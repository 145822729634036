import { LitElement, html, css } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import XLSX from 'xlsx';
// SELECT pid, pg_terminate_backend(pid) FROM pg_stat_activity WHERE state = 'active' and query not ilike '%MAGIC%';

import { reports } from '../mindues-app/report-defs.js';
import './report.js';

const reports_page_styles = css`
      :host { 
        --top-bar-color: var(--reporting-color);
      }
      .column {
        box-sizing: border-box;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: center;
        align-items: stretch;
        flex-direction: column; 
        width: 100%;
        padding: 0;
        height: fit-content;
        padding-bottom: 24px;
      }

      .column > * {
        margin: 0;
        width: 100%;
        flex: 1 1;
      }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;

          height: calc(100vh - 64px);
        }

        mwc-fab {
          position: fixed;
          right: 50px;
          bottom: 50px;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        .table-scroller[height_computed] {
          height: fit-content;
        }
              
        .top-app-bar-adjust {
          margin-top: 64px;
          }

        kale-card {
          width: 100%;
          margin-top: 24px;
          --card-border: var(--paper-grey-400);
          --card-bg: white;/*var(--paper-grey-700);*/
          width: fit-content;

        }


        div.card-actions {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-direction: row; 
          margin-bottom: 0;
          padding: 8px;
          width: 100%;
          /*()
          padding-right: 18px;
          padding-bottom: 16px;
          */
        }

        div.card-header {
          padding: 18px;
          padding-top: 8px;
          padding-right: 32px;
          font-size: 1.3em;
          font-weight: 500;
          background-color: var(--paper-kale-800);
          color: white;
          width: 100%;
        }
        .card-header > mwc-icon {
          margin-right: 12px;
          position: relative;
          font-size: 150%;
          top: 8px;
          margin-top: -8px;
          width: 100%;
        }

        div.card-content {
          padding: 18px;
          flex: 1 1;
        }

        .card-content > h2 {
          margin-top: 0px;
        }


        report-table {
          display: none;
        }
        report-table[show] {
          display: block;
          position: fixed;
          left: 0;
          top: 64px;
          width: 100%;
          height: 100%;
        }


        mwc-textfield {
          margin-top: -24px;
        }

    .report-choice {
      margin-top: 18px;
      border: 1px solid var(--paper-grey-400);
      background-color: white;
      border-radius: 8px;
      padding: 24px;
      cursor: pointer;
          transition: var(--shadow-transition);
          box-shadow: none; /* var(--shadow-level-one);*/
        }

        .report-choice:hover {
          /*box-shadow: var(--shadow-elevation-8dp_-_box-shadow); */
          box-shadow: var(--shadow-elevated);

        }
               .report-choice-header {
                 display: flex;
                 flex-direction: row;
                 width: 100%;
                 padding-bottom: 16px;
               }
               .report-choice-header > * {
                 padding: 4px;
               }
               .report-choice-icon{
                 margin-top: -12px;

               }
               .report-choice-name{
                 flex: 1;
                 font-size: 120%;
                 font-weight: bold;

               }
               .report-choice-arguments{

               }
               .report-choice-buttons{

               }
                .report-choice-desc{
                  font-weight: 200;
                }

      #content {
        overflow: hidden;
      }
`;

const defs = {
  'affiliate': {name: 'Affiliate Progress'},
  'masters': {name: 'Master Agreements'},
  'detail': {name: 'Detail'},
}

class ReportsPage extends MDuesPage {
  static styles = [ super.styles, reports_page_styles]
  static icon = "timeline"
  static default_title = "Reports"
  static properties = {
      ...super.properties,
      reportkey: {type: String }
    };

  constructor() {
    super();
    this.args = new Map();
  }

  period_changed(p) {
    //console.warn("P chnaged", p?.name);
    this.requestUpdate();
  }

  async activateRoute(cached, {key, name}) {
    console.log("routed", key, name);
    super.activateRoute();
    this.activateReport(key);
    this.reportkey = key;
    this.requestUpdate("mdp");
    this.requestUpdate("reportkey");
  }

  activateReport(k) {
    this.title = `Report: ${defs[k].name}`;
  }

  getReport() {
    switch (this.reportkey) {
      case 'affiliate':
        return html`<affiliate-report id="report" .period_id=${this.mdp?.id}></affiliate-report>`;
        break;
      case 'masters':
        return html`<masters-report id="report" .period_id=${this.mdp?.id}></masters-report>`;
        break;
      case 'detail':
        return html`<detail-report id="report" .period_id=${this.mdp?.id}></detail-report>`;
        break;
    }
  }

  renderPage() {
    return html`
            <div class="column">
              ${this.getReport()}
            </div>
  `;
  }
  renderExtraItems() {
    return html`
      <mwc-button slot="actionItems" icon="file_download"  style="--mdc-theme-primary: white;" @click=${e => this.downloadTable()}>download</mwc-button>
      ${super.renderExtraItems()}
    `
  }
  downloadTable() {
    this.renderRoot.getElementById('report')?.downloadTable();
  }



}

window.customElements.define('reports-page', ReportsPage);
export { ReportsPage }
