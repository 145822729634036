import { LitElement, html, css, nothing } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import { KaleForm } from '../shared-components/form.js';
import '../shared-components/card.js';
import { client, formatQueryError } from '../queries/client.js';
import gql from 'graphql-tag';
import { refetch_searches, SearchAgreements, SearchAgreementInfo,  EditIncrease, EditAgreement, upsert_agreement_mutation, delete_agreement_increases_mutation } from '../queries/queries.js';
import { MDuesResultList, formatAffiliate, formatAffiliateWithState,  MDuesListItem, MduesSearchBox, NewItemDialog, shortdatefmt } from '../components/result_list.js';

export class IncreaseSearch extends MduesSearchBox {
  renderRowOptions() {
    return html`
      <mwc-formfield label="Masters" class="option">
        <mwc-switch @input=${e => this.search = { ...this.search, master_only: e.path[0].checked || undefined }}></mwc-switch>
      </mwc-formfield>
    `
  }
}

//<mwc-textfield @input=${e => this.search = {...this.search, filter: e.target.value}} style="flex: 1 1" outlined label="filter" icon="search"></mwc-textfield>
window.customElements.define('increase-search', IncreaseSearch);


const increase_row_styles = css`
  :host { 
    display: table-row;
  }

  div.cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    padding: 12px;
    --mdc-text-field-fill-color: none;
    --mdc-select-fill-color: none;
    --mdc-text-field-idle-line-color: none;
    whitespace: nowrap;
  }
  div.cell.toggle-cell {
    vertical-align: middle;
  }

  div.cell[newrow] {
  }
  div.group {
    display: flex;
    flex: 1;
  }
  div.group > * {
    display: inline-block;
  }

  div.fixer {
    position: relative;
    top: 0;
    left: 0;
  }

  /*
  .cell kale-listbox {
    position: relative;
    top: -24px;
  }*/

`

export class IncreaseRow extends KaleForm {
  static styles = [increase_row_styles];
  static properties = {
    ...super.properties,
    increase: { type: Object },
    target: { type: Object },
    in_negotiation: { type: Boolean },
    increase_in_negotiation: { type: Boolean },
    is_pct: { type: Boolean },
    time_unit: { type: String },
    is_new: { type: Boolean },
    suppressed: { type: Boolean }
  }
  static form_name = 'IncreaseFormRow'
  static mutation_class = EditIncrease

  dataUpdate(new_data) {
    this.increase = new_data;
  }
  finalUpdate(new_data, method) {
    console.log("finally", method);
    if (method === 'mutate') {
      this.increase = new_data;
    } else if (method === 'delete') {
      this.dispatchEvent(new CustomEvent('deleted', { bubbles: true, composed: true, detail: this.increase }))
    }
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.increase }))
    this.nextMulti();
  }

  get mutation_instance() {
    console.log(`build mutation for ${this.constructor.form_name}: ${this.constructor.mutation_class.name}.`)
    if (!this.constructor.mutation_class) return null;
    if (!this._mutation) this._mutation = new (this.constructor.mutation_class)(
      (increase) => this.dataUpdate(increase),
      { changeMap: null },
      (increase, method) => this.finalUpdate(increase, method),
      (err) => console.error(err) //FIXME: more error checking/alerts
    );
    return this._mutation;
  }

  async saveMulti(parent, rest) {
    console.warn("SAVING MULTI", parent, rest);
    this.multiqueue = [...(this.multiqueue ? this.multiqueue : []), ...rest];
    this.save(parent);
  }

  nextMulti() {
    if (this.multiqueue && this.multiqueue.length > 0) {
      console.warn(this.form_name, "clearing multiqueue", this.multiqueue.length);
      let next = this.multiqueue.pop();
      console.log("NEXT:", next);
      next.form.saveMulti(next.parent, this.multiqueue);
      this.multiqueue = [];
    }
  }
  async save_impl(data) {
    const mut = this.mutation_instance;
    console.log("INCREASE SAVE_IMPL", mut, data);
    console.log("TARGET", this.target);
    console.log("INCREASE", this.increase);
    if (!mut || !this.increase) return;
    mut.save(data, { ...this.increase, agreement_id: this.target.id});
    this.saveComplete(Object.keys(data));
  }
  delete() {
    const mut = this.mutation_instance;
    if (!mut || !this.increase) return;
    mut.delete(this.increase);
  }

  setUnit(increase_type) {
    if (!increase_type) return;
    console.warn("SET UNIT", increase_type);

    this.is_pct = increase_type === 'pct';
    switch (increase_type) {
      case 'hourly':
        this.time_unit = 'hour';
        break;
      case 'yearly':
        this.time_unit = 'year';
        break;
      default:
        this.time_unit = undefined;
        break;
    }
  }

  set increase(i) {
    this._inc = i;
    if (this.is_pct === undefined) this.setUnit(i.increase_type);
    if (this.increase_in_negotiation === undefined) this.increase_in_negotiation = i.in_negotiation;
    this.requestUpdate('increase');
  }

  get increase() {
    return this._inc;
  }

  set is_new(v) {
    this._is_new = v;
    if(v) this.setUnit('pct');
    this.requestUpdate('is_new');
  }
  get is_new() {
    return this._is_new;
  }

  static mutation_class = EditIncrease
  static form_name = "IncreaseRow"
  static type_items = [
    { name: 'Percent', code: 'pct' },
    { name: 'Hourly', code: 'hourly' },
    { name: 'Yearly', code: 'yearly' },
  ]

  getCurVal(field) {
    if (this.dirty_map.has(field)) {
      return this.dirty_map.get(field).value
    }
    return this.increase[field];
  }

  /*
  firstUpdated() {
    super.firstUpdated();
    if (!this.increase) this.setUnit('pct');
  }*/

  render() {
  
    const {is_new} =  this;
    const { id, members, in_negotiation, increase_type, increase_value, increase_base_value, effective_date, comment} = this.increase;

    return html`
          <div class="cell" ?newrow=${is_new}>
            <kale-textfield ?outlined=${is_new} inputtype="number" label="Members" placeholder="Members" style="max-width: 50px;" .value=${members} field="members"></kale-textfield>
          </div>
        ${ this.is_master ? html`
          <div class="cell toggle-cell" ?newrow=${is_new}>
            <kale-toggle id="in_negotiation" .value=${in_negotiation} field="in_negotiation" @component-dirty=${({ detail: { value } }) => { console.log("inc in neg => ", value); this.increase_in_negotiation = value }}></kale-toggle>
          </div>
        ` : ''}
      ${this.in_negotiation ? '' : this.increase_in_negotiation ? html`<div class="cell"></div><div class="cell"></div><div class="cell"></div><div class="cell"></div>` : html`
          <div class="cell" ?newrow=${is_new}>
          <kale-listbox ?outlined=${is_new} id="type" label="Type" placeholder="Type" .value=${increase_type ? increase_type : 'pct'} field="increase_type" .items=${this.constructor.type_items} @component-dirty=${({ detail: { value } }) => this.setUnit(this?.renderRoot?.getElementById('type')?.value)}></kale-listbox>
          </div>
          <div class="cell" ?newrow=${is_new}>
              <kale-textfield                                  ?outlined=${is_new} inputtype="number" placeholder="Increase" suffix=${this.is_pct || this.time_unit === undefined ? '%' : `$/${this.time_unit}`}  style="max-width: 50px;" .value=${increase_value} field="increase_value"></kale-textfield>
          </div>
        ${this.is_pct ? html` <div class="cell"></div>` : html`
          <div class="cell" ?newrow=${is_new}>
            <kale-textfield ?disabled=${this.is_pct === 'pct'} ?outlined=${is_new} inputtype="number" placeholder="Base"   suffix=${this.is_pct || this.time_unit === undefined ? '' : `$/${this.time_unit}`} style="max-width: 50px;" .value=${increase_base_value ? increase_base_value : null} field="increase_base_value"></kale-textfield>
          </div>
        `}

          <div class="cell" ?newrow=${is_new}>
            <kale-date ?outlined=${is_new} label="Effective" placeholder="Base" field="effective_date" .value=${effective_date}></kale-date>
          </div>
      `}
          <div class="cell" ?newrow=${is_new}>
            <kale-textarea ?outlined=${is_new} placeholder="Comment" style="max-width: 50px;" .value=${comment ? comment : null} field="comment"></kale-textarea>
          </div>
          <div class="cell">

            ${id ? html`<mwc-icon-button @click=${e => { this.delete(); }} icon="delete"></mwc-icon-button>` : '' }
          </div>
    `
  }
}
window.customElements.define('increase-row', IncreaseRow);


const increase_item_styles = css`
  :host {
    --item-primary-color: var(--virtual-color);
  }

  :host([increase_type=unit]) {
    --item-primary-color: var(--unit-color) !important;
  }
  :host([increase_type=master]) {
    --item-primary-color: var(--master-color) !important;
  }



  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }
  .item-chip.unit-target {
    --chip-background: var(--unit-color);
    --chip-color: white;
  }
  .item-chip.master-target {
    --chip-background: var(--master-color);
    --chip-color: white;
  }


  .increase_table {
    box-sizing: border-box;
    width: 100%;
    box-shadow: var(--shadow-elevation-3dp_-_box-shadow);
    margin-top: 18px;
    padding: 8px;

  }

  table {
    width: 100%;
  }
  
  td, th {
    text-align: center;
    padding: 8px;
  }

  th {
    background: var(--paper-grey-700);
    color: white;
    text-transform: uppercase;
    font-size: 90%;
    font-weight: 100;
  }
  tr:nth-child(even) {background: #CCC}
tr:nth-child(odd) {background: #FFF}

  td.button {
    background: white;
  }

  .header-actions {
    float: right;
  }
  .footer-info {
    margin-top: 18px;
  }

  .footnote { 
    font-size: 90%;
    font-weight: 100;
    opacity: 0.9;
    margin: 1em;
  }

  div.control_row {
    display: flex;
    flex-direction: row;
    align-contents: center;
  }
  div.control_row > * {
    margin-right: 12px;
  }
  div.control_row > mwc-formfield {
    position: relative;
    top: 12px;
  }
  div.control_row  kale-toggle {
    position: relative;
    bottom: 12px;
  }

  div.agreementid {
    padding-left: 30px;
    font-size: 60%;
    font-weight: 100;
    display: inline-block;
  }

  table.fixed { 
    table-layout:fixed; 
  }
  
  td {
    padding: 1px;
    background-color: var(--item-primary-color);
    //background-color: blue ;
    color: white;
    font-size: 15px;
    text-align: center;
  }
  
  td.master {
    padding: 2px;
    background-color: var(--master-color) ;
    color: white;
    font-size: 15px;
    text-align: center;
  }  

  .negotiations_indicator {
    border-radius: 10px;
    background-color: var(--paper-orange-900);
    color: white;
    padding: 4px 8px;
    position: relative;
    top: -6px;
  }

  .info_info.header_date {
    font-size: 12px;
    display: block;
    max-width: 55px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export class IncreaseItem extends MDuesListItem {
  static styles = [...super.styles, increase_item_styles];
  static properties = { item: { type: Object }, increase_type: { type: String }, in_negotiation: {type: Boolean}, from_dialog: {type: Boolean}}

  static mutation_class = EditAgreement

  setAgreementInfo(i) {
    const { agreement: {master}, in_negotiation } = i
    this.increase_type = master ? 'master' : 'unit';
    if (this.increase_type) {
      this.setAttribute('increase_type', this.increase_type);
    }
    this.in_negotiation = i.in_negotiation;
    this.some_negotiation = i?.increases?.some(inc => inc.in_negotiation);
  }
  setAgreement(i) {
    const { master } = i
    this.increase_type = master ? 'master' : 'unit';
    if (this.increase_type) {
      this.setAttribute('increase_type', this.increase_type);
    }
  }

  set item(i) {
    this._item = i;
    switch (i.__typename) {
      case "cached_agreement": 
      this.setAgreement(i);
      break;
      case "core_agreement_info":
      this.setAgreementInfo(i);
      break;
    }
    this.requestUpdate('item');
    

  }
  get item() { return this._item }

  async save_impl(data) {
    const mut = this.mutation_instance;
    console.log("!!!!!!AGREEMENT SAVE_IMPL", mut, data, this.item);
    if (!mut || !this.item) return;
    mut.save(data, { ...this.item});
    this.saveComplete(Object.keys(data));
  }

  chipClass(item) {
    return `item-chip ${item.master ? 'master-target' : 'unit-target'}`
  }

  dataUpdate(new_data) {
    this.item = new_data;
  }
  finalUpdate(new_data) {
    this.item = new_data;
    this.requestUpdate("item");
    //this.editing = false;
  }

  handleDelete(row) {
    this.finalUpdate({ ...this.item, increases: this.item.increases.filter(i => i.id !== row.id) });
  }


  renderEditableIncreases(increases) {
    return html`
    <table>
      <thead>
        <tr>
          <th>members</th>
          ${this.increase_type === 'master' ? html`<th>in negotiation</th>` : ''}
          ${this.in_negotiation ? '' : html`
          <th>type</th>
          <th>increase</th>
          <th>base amt</th>
          <th>effective</th>
          `}
          <th>comment</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        ${[...increases, { increase_target_id: this.item.agreement_id, in_negotiation: false, type: 'pct' }].map(i => html`<increase-row .is_new=${!i.id} @deleted=${({ detail }) => this.handleDelete(detail)} .target=${this.item} .is_master=${this.increase_type === 'master'} .increase=${i} .in_negotiation=${this.in_negotiation}></increase-row>`)}
      </tbody>
    </table>
    `;
  }

  formDirty(form, name) {
    console.log("FORM DIRTY", form, name, this.dirty_map);

  }
  
  renderEditForm(item) {
    const { in_negotiation, effective_date, expires_date, stat_cap, stat_override, members, total_members, adjusted_members, total_adjusted_members, adjusted_members_in_negotiation, total_adjusted_members_in_negotiation, weighted_increase, total_weighted_increase, increases } = item;
    return html`
      <div class="control_row">
        <mwc-formfield label="In Negotiations">
          <kale-toggle id="in_negotiation" .value=${item.in_negotiation} field="in_negotiation" @component-dirty=${({ detail: { value } }) => { console.log("in neg => ", value); this.in_negotiation = value }}></kale-toggle>
        </mwc-formfield>
        <kale-date label="Effective" field="effective_date" .value=${item.effective_date}></kale-date>
        <kale-date label="Expires" field="expires_date" .value=${item.expires_date}></kale-date>
        <kale-textfield type="number" label="Manual Stat Override" field="stat_override" .value_transform=${v => {console.log("transforming", v, " => ", v?.trim() === '' ? null : v); return v?.trim() === '' ? null : v;}} .value=${item.stat_override}></kale-textfield>
      </div>
          <div class="target-info">
              <div class="info_item"><span class="info_label">stat</span><span class="info_info">${stat_cap !== null ? stat_cap.toLocaleString() : html`&mdash;`}</span></div>
              <div class="info_item"><span class="info_label">members${total_members !== members ? ` (group)` : ''}</span><span class="info_info">${members !== null && members !== undefined ? members.toLocaleString() : html`&mdash;`}${total_members !== members ? html` (${total_members !== null ? total_members.toLocaleString() : html`&mdash;`})` : ''}</span></div>
              <div class="info_item"><span class="info_label">adjusted${total_adjusted_members !== adjusted_members ? ` (group)` : ''}</span><span class="info_info">${adjusted_members !== null && adjusted_members !== undefined ? adjusted_members.toLocaleString() : html`&mdash;`}${stat_override ? html`&ast;` : ''}${total_adjusted_members !== adjusted_members ? html` (${total_adjusted_members !== null && total_adjusted_members !== undefined ? total_adjusted_members.toLocaleString() : html`&mdash;`})` : ''}</span></div>
              <div class="info_item"><span class="info_label">in negotiation${total_adjusted_members_in_negotiation !== adjusted_members_in_negotiation ? ` (group)` : ''}</span><span class="info_info">${adjusted_members_in_negotiation !== null && adjusted_members_in_negotiation !== undefined ? adjusted_members_in_negotiation.toLocaleString() : html`&mdash;`}${total_adjusted_members_in_negotiation !== adjusted_members_in_negotiation ? html` (${total_adjusted_members_in_negotiation !== null && total_adjusted_members_in_negotiation !== undefined ? total_adjusted_members_in_negotiation.toLocaleString() : html`&mdash;`})` : ''}</span></div>
          </div>
      <div class="divider"></div>

      <div class="increase_table">
        ${this.renderEditableIncreases(item.increases)}
      </div>
      ${stat_override ? html`<div>&ast; STAT set manually on this unit/agreement</div>` : ''}

    `;
    /*

      <h4>Units:</h4>
      ${this.renderItemBody(item)}
      <h5>Add unit:</h5>
      <unit-search id="unit-filter-box" compact @search=${({detail}) => {console.log("UNIT FILTER", detail); this.unit_filter = detail}}></unit-search>
      <unit-list id="unit-search-list" .filter=${result => result.real_targets.filter(({target: {period_id}}) => period_id === this.item.period_id).length === 0} .mdues_period=${this.item.period_id} .presentation=${'selection'} .search=${this.unit_filter} @item-selection=${e => this.addUnit(e.detail)}></unit-list>

    */
  }
  //<mwc-textfield id="alias_entry" label="Enter New Alias" outlined @keyup=${({target, key})=> { if (key === 'Enter') this.enterNewAlias(target)}}></mwc-textfield>
  get item_date() {
    return this?.item?.updated;
  }

  renderHeaderName({ agreement: {name = ''} } = {}) { return name }
  
  renderHeaderDetail(agreement) {
    const { increases } = agreement;
    const {in_negotiation, effective_date, expires_date, stat_cap, stat_override, members, total_members, adjusted_members, total_adjusted_members, adjusted_members_in_negotiation, total_adjusted_members_in_negotiation, weighted_increase, total_weighted_increase} = agreement;  
    let actual_stat_cap = stat_override ? stat_override : stat_cap;
    let units = agreement?.units;
    if (!units) return html`<div class="unit">no unit info</div>`;
    
    let unique = Array.from((new Set(units.map(u => `${formatAffiliateWithState(u.unit)}${u?.unit?.subunit && String(u?.unit?.subunit).trim() !== '' ? ` / ${u?.unit?.subunit}` : ''}`)).values()));
 
    let rendered = unique.slice(0, 5);
    let excess = unique.slice(5);

    // This table layout is crunchy. We'll need to refactor at some point
    
    return html` 

        <table class="fixed">
            <col width="20px" />
            <col width="20px" />
            <col width="20px" />
            <col width="20px" />
            <col width="20px" />
            <col width="20px" />
            <col width="25px" />
            <col width="15px" />
            <col width="25px" />
            <col width="25px" />
            <col width="25px" />

            <tr class="info_labels">
            <td></td>
            <td>Cn</td>
             ${!this.item.agreement.master ? html`
                <td>Local</td><td>Subunit</td>
             ` : html`
                    <td colspan="2">Units</td>
                `}
            <td>Eff</td>
            <td>Exp</td>
            <td>Mbrs${total_members !== members ? ` (group)` : ''}
            </td>
            <td>STAT</td>
            <td>Adj${total_adjusted_members !== adjusted_members ? ` (group)` : ''}
            </td>
            <td>In Neg${total_adjusted_members_in_negotiation !== adjusted_members_in_negotiation ? ` (group)` : ''}
            </td>
            <td>Mbrs x Inc${total_weighted_increase !== weighted_increase ? ` (group)` : ''}
            </td>
            </tr>
            
            <tr>
            <td>${this.item.in_negotiation ? html`<div class="negotiations_indicator">IN NEG.</div>` : ''}</td>
            <td>${units[0].unit.state}&nbsp;${units[0].unit.council}</td>
            
                ${!this.item.agreement.master ? html`
                    <td>${units[0].unit.local}</td>
                    <td>${units[0].unit.subunit}</td>
                ` : html`
                    <td colspan="2">${units.length} units</td>
                `}
                
                
                <td>
                <div>
                <span class="info_info header_date" title=${effective_date ? (shortdatefmt(new Date(effective_date))) : (in_negotiation ? html`&empty;`: html`&mdash;`)}>${effective_date ? (shortdatefmt(new Date(effective_date))) : (in_negotiation ? html`&empty;`: html`&mdash;`)}</span>
                </div>
                </td>
                <td>
                <div>
                <span class="info_info header_date" title=${expires_date ?  shortdatefmt(new Date(expires_date)) : (in_negotiation ? html`&empty;` : html`&mdash;`)}>${expires_date ?  shortdatefmt(new Date(expires_date)) : (in_negotiation ? html`&empty;` : html`&mdash;`)}</span>
                </div>
                </td>
                <td>
                <div>
                <span class="info_info">${members !== null && members !== undefined ? members.toLocaleString() : html`&mdash;`}${total_members !== members ? html` (${total_members !== null ? total_members.toLocaleString() : html`&mdash;`})` : ''}</span>
                </div>
                </td>
                <td>
                <div>
                <span class="info_info">${actual_stat_cap !== null && actual_stat_cap !== undefined ? actual_stat_cap.toLocaleString() : html`&mdash;`}${stat_override ? html`&ast;` : ''}</span>
                </div>
                </td>
                <td>
                <div>
                <span class="info_info">${adjusted_members !== null && adjusted_members !== undefined ? adjusted_members.toLocaleString() : html`&mdash;`}${stat_override ? html`&ast;` : ''}${total_adjusted_members !== adjusted_members ? html` (${total_adjusted_members !== null && total_adjusted_members !== undefined ? total_adjusted_members.toLocaleString() : html`&mdash;`})` : ''}</span>
                </div>
                </td>
                <td>
                <div>
                <span class="info_info">${adjusted_members_in_negotiation !== null && adjusted_members_in_negotiation !== undefined ? adjusted_members_in_negotiation.toLocaleString() : html`&mdash;`}${total_adjusted_members_in_negotiation !== adjusted_members_in_negotiation ? html` (${total_adjusted_members_in_negotiation !== null && total_adjusted_members_in_negotiation !== undefined ? total_adjusted_members_in_negotiation.toLocaleString() : html`&mdash;`})` : ''}</span>
                </div>
                </td>
                <td>
                <div>
                <span class="info_info">
                ${weighted_increase !== null && weighted_increase !== undefined ?
                   weighted_increase.toLocaleString()
                   : (in_negotiation ? html`&empty;` : html`&mdash;`)}
                 ${total_weighted_increase !== weighted_increase ?
                   html` (${total_weighted_increase !== null && total_weighted_increase !== undefined ? total_weighted_increase.toLocaleString() : html`&mdash;`})` 
                   : ''}</span></div></td>
            </tr>
        </table>
    `;
  }

  itemChipIcon(agreement) {
    return this.from_dialog ? '' : super.itemChipIcon(agreement);
  }
    
  renderItemChipTip(agreement) {
    let units = agreement?.units;
    let states = Array.from(new Set(units.map(u => u.unit.state)).keys());
    if (units) {
      if (units.length === 1) {
        return `${agreement.name} - ${formatAffiliateWithState(units[0].unit)}${units[0]?.unit?.subunit && String(units[0]?.unit?.subunit).trim() !== '' ? ` / subunit ${units[0]?.unit?.subunit}` : ''}`
      } else {
        return states.map(s => `${s}: ${units.filter(u => u.unit.state === s).length} units`).join(' / ');
      }
      
    } else {
      return agreement.name;
    }
  }

  renderItemChipDesc(agreement) {
    //console.log("CHIP", agreement);
    let units = agreement?.units;
    let states = Array.from(new Set(units.map(u => u.unit.state)).keys());
    if (units) {
      if (units.length === 1) {
        return `${agreement.name} - ${formatAffiliateWithState(units[0].unit)}${units[0]?.unit?.subunit && String(units[0]?.unit?.subunit).trim() !== '' ? ` / subunit ${units[0]?.unit?.subunit}` : ''}`
      } else {
        return `${agreement?.name}`;
        //return `${agreement?.name} (${states.join('/')})`;
      }
      
    } else {
      return agreement.name;
    }
  }

  renderHeaderId(agreement) {
    return html`<div class="agreementid">${agreement.id}</div>`;
  }
  //renderItemBody({units=[]}={}) { return html`<div class="aliases">${this.renderUnits(units.map(u => u.unit))}</div>` }
  renderItemBody(item) {
    const { increases } = item;
    const {in_negotiation, effective_date, expires_date, stat_cap, stat_override, members, total_members, adjusted_members, total_adjusted_members, adjusted_members_in_negotiation, total_adjusted_members_in_negotiation, weighted_increase, total_weighted_increase} = item;
    let actual_stat_cap = stat_override ? stat_override : stat_cap;
    //console.log("RENDER ITEM", item, increases );
    //if (!status_code) return '';
    return html`
          <div class="increase_table">
            ${this.renderIncreases(item, increases)}
          </div>
          ${stat_override ? html`<div>&ast; STAT set manually on this unit/agreement</div>` : ''}
     `
  }

  /*
  stat_cap
  members
  total_members
  adjusted_members
  total_adjusted_members
  weighted_increase
  total_weighted_increase
  */

  renderUnitList(target) {
    if (!target) return '';
    const { units } = target;
    return html`${units.map(({ unit: u }) => html`<div class="unit">${u.name}</div>`)}`;
  }
  renderUnitAff(target) {
    if (!target) return '';
    const { units: [{ unit }] } = target;
    console.log("render unit", unit);
    const aff = [
      ['C', 'council'],
      ['L', 'local'],
      ['U', 'sublocal']
    ].filter(([_, p]) => unit[p]).map(([abb, field]) => `${abb}${unit[field]}`).join(' / ');
    return html`<div class="unit">${aff}</div>`;
  }

  
  renderIncreases(item, increases, editable) {
    console.log("RENDER INC", item, increases)
    const warn_pseudo = false //increases.some(({ adjusted: { is_pseudo } }) => is_pseudo);
    return html`
    <table>
      <thead>
        <tr>
          <th>members</th>
          <th>adjusted</th>
          ${this.increase_type === 'master'&& this.some_negotiation ? html`<th>in negotiation</th>` : ''}
          ${! this?.item?.in_negotiation ? html`
          <th>effective</th>
          <th>increase</th>
          <th>members &times; increase</th>
          ` : '' }
          <th>comment</th>
        </tr>
      </thead>
      <tbody>
      ${increases.map(({ members, in_negotiation, type, value, base_value, source, 
      increase_pct = 0,
      is_pseudo,
      adjusted_members,
      weighted_increase,
      effective_date,
      comment
      }) => html`
        <tr>
          <td>${members?.toLocaleString()}</td>
          <td>${adjusted_members?.toLocaleString()}${is_pseudo ? '*' : ''}</td>
          ${this.increase_type === 'master' && this.some_negotiation ? html`<td>${in_negotiation ? adjusted_members?.toLocaleString() : '0'}</td>` : ''}
          ${!this.item.in_negotiation && !in_negotiation ? html`
          <td>${effective_date !== null ? (new Date(effective_date)).toLocaleDateString() : ''}</td>
          <td>${increase_pct !== null ? increase_pct.toLocaleString() : 0}%</td>
          <td>${weighted_increase !== null ? weighted_increase.toLocaleString() : 0}</td>
          ` : in_negotiation ? html`<td colspan="3"></td>` : '' }
          <td>${comment}</td>
        </tr>
      `)}
      </tbody>
    </table>
    ${warn_pseudo ? html`
      <div class="footnote">* Based on aggregrated STAT units</div>
    ` : ''}
    `;
    // <td class="button"><mwc-icon-button icon="delete"></mwc-icon-button><mwc-icon-button icon="edit"></mwc-icon-button></td>
  }

  extra_edit_actions = [
    {
      name: 'delete',
      icon: 'delete_sweep',
      action: (e, item) => this.dispatchEvent(new CustomEvent('demote', { bubbles: true, composed: true, detail: item }))
    }
  ]
}

window.customElements.define('increase-item', IncreaseItem);



const increase_list_styles = css`
  .pending_targets { 
    box-sizing: border-box;
    padding: 12px;
  }

  .card-header-table {
        display: table;
        width: 86%;
        
        border:0px;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
  }
  .chip-list {
    flex: none;
  }

`;

export class IncreaseList extends MDuesResultList {
  static styles = [...super.styles, increase_list_styles];
  static properties = { ...super.properties, promoted: { type: Object } };
  static search_class = null;

  constructor() {
    super();
    this.active_sorts = [{ name: 'updated', direction: 'desc' }, { name: { agreement: { name: "asc" } } }];
  }
  
  static valid_sorts = [
        {field: 'name', display: 'Name', width: 88},
        {field: 'updated', display: 'Date', width: 12}
  ]
      
  get search_class() {
    return this.q ? this.q : this.constructor.search_class;
  }
  
  render() {
    if (this.presentation === 'selection') {
      return html`
      <div class="chip-list">${this.results.map(result => this.renderSelectionItem(result))}</div>
      `
    }

    let cached = this.results.filter(r => r.__typename==='cached_agreement');
    let core = this.results.filter(r => r.__typename ==='core_agreement_info');

    
    return html`
      ${this.only_chips ? nothing : this.renderSortHeaders()} 
      <div class="column centered" ?in-progress=${this.in_progress}>
        <div class="chip-list" ?in-progress=${this.in_progress}>
          ${cached && cached.length > 0 ? cached.map(result => this.renderSelectionItem(result, true)) : nothing}
          ${this.new_item ? this.renderItem(this.new_item, true) : nothing}
          ${core && core.length > 0 ? core.map(result => this.renderItem(result)) : nothing}
        </div>      
      </div> 
      ${this.in_progress ? html`
      <mwc-circular-progress class=${ this.only_chips ? 'chips-progress' : 'page-progress' } indeterminate></mwc-circular-progress>
    ` : ''}
      `;
  }
  
        //${repeat(this.results.filter(r => r.__typename ==='core_agreement_info'), r => r.id, result => this.renderItem(result))}
  formatSort() {
        let x = Object.fromEntries(this.active_sorts.map(({name, direction}) => {
            switch(name) {
                case 'name':
                    return ["agreement", {name: direction}];
                default:
                    return [name, direction]
            }
            }));
        
        console.log("new sort order:", JSON.stringify(x));
        return x;
        
  }
  
  updateTargetData(target) {
    console.log("UPDATING", target);
    this.results = [target, ...this.results.filter(r => r.id !== target.id)]//.sort((a,b) => new Date(a) - new Date(b)) ;
    console.log("new results", this.results);
    this.requestUpdate('results');
  }

 
  demoteItem(target) {
    console.log("demoting", target);

    const delete_inc = gql`
        mutation clear_increases($agr: uuid!) {
          increases:delete_core_increase (
            where: {agreement: {uid: {_eq: $agr}}}
          ) {
            returning {
              id
              agreement_id
            }
          }
          
        }
    `;
    const delete_agr = gql`
        mutation demote_agreement($agr: uuid!) {
          agreement:delete_core_agreement_info (
            where: {uid: {_eq: $agr}}
          ) {
            returning {
              agreement_id
              uid
            }
          }
          
        }
    `;

    client.mutate({
      mutation: delete_inc,
      variables: {agr: target.uid},
     }).then(data => {
       console.log("deleted increases:", data);
       client.mutate({
         mutation: delete_agr,
         variables: { agr: target.uid },
         refetchQueries: refetch_searches
       }).then(data => {
         console.log("deleted agreement info:", data);
         this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'success', text: `${target.agreement.name} data cleared` } })); // TODO: undo
       }).catch(error => {
         this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `delete failed` } })); // TODO: undo
         formatQueryError(error);
       });
     }).catch(error => {
       this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `delete failed` } })); // TODO: undo
       formatQueryError(error);
     });
    
  }

  promoteMaster(target) {
    const edit_target = new EditAgreement(
      a => { console.log("MASTER PROMOTE", a) }, // update
      { changeMap: null }, // initial
      a => {
        console.log("FINAL MASTER PROMOTE", a);
        this.updateTargetData({ ...target, promoted: true });
        this.refresh();
      },
      (e, msgs) => { console.error(e) } //errors
    );

    edit_target.save({ agreement_id: target.id}, target);
  }

  promoteUnit(item) {
    const vars = {
      agreement_id: item.agreement_id,
      period_id: item.period_id,
    };
    console.log("PROMOTE UNIT", vars)
    console.log(JSON.stringify(vars));

    client.mutate({
      mutation: upsert_agreement_mutation,
      variables: vars
    }).then(data => {
      console.log("insert succeeded?", data.data.agreement.returning);
      data.data.agreement.returning.forEach(target => {
        this.updateTargetData(target);
        this.refresh();
      })

    })
      .catch(error => { console.log(error); formatQueryError(error) });
  }

  
  promoteTarget(target) {
    //this.promoted = target.id;
    //this.promoteMaster(target);

    this.results = this.results.filter(item => item !== target);
    
    //console.log("this.results ================ ", this.results);
    //this.selectionresults = [...this.selectionresults, target];
    
   this.requestUpdate();
  }

  promoteTargets(selection) {     
      selection.forEach(target => {this.promoted = target.id;  this.promoteMaster(target) } );
  }

  
  renderSelectionItem(item, from_dialog=false) {
    return html`<increase-item @update-list=${() => this.refresh()} ?from_dialog=${from_dialog} selectable .item=${item} @item-selection=${e => this.promoteTarget(e.detail)}></increase-item>`;
  }

  renderItem(item) {
    return html`<increase-item @update-list=${() => this.refresh()} @demote=${e => this.demoteItem(e.detail)} @close=${() => this.promoted = null} .editing=${this.promoted === item.__type === 'core_agreement_info'} .item=${item}></increase-item>`;
  }

  handleSearchResults(seq, res) {
    if (super.handleSearchResults(seq, res)) {
      console.log(`${this.only_chips? 'promotable' : 'full'} results`, res);
      const t = this.results.find(t => t.period && t.period.stats);
      if (t) {
        this.dispatchEvent(new CustomEvent('status', { bubbles: true, composed: true, detail: t.period.stats}));
      } else {
        this.dispatchEvent(new CustomEvent('status', { bubbles: true, composed: true, detail: { total_stat_members: null, period_total_adjusted_members: 0, period_total_adjusted_members_less_negotiation: 0, average_increase: 0 } }));
      }      
    }
  }
}

window.customElements.define('increase-list', IncreaseList);

const increases_page_styles = css`
      :host { 
        background-color: white;
        --top-bar-color: var(--increase-color);
      }
  
      .column {
        background-color: var(--page-bg, var(--paper-grey-200));
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        min-height: 100%;
        box-sizing: border-box;
        padding: 0px;
      }
      .column > * {
      }

      .column.centered {
        align-items: center;
      }
      
      .row {
        flex: 1 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .centered_row {
        justify-content: center;
      }


      .card {
        box-sizing: border-box;
        background-color: white;
        border: 1px solid var(--paper-grey-400);
        padding: 12px;
        padding-bottom: 16px;
        border-radius: 8px;
        transition: var(--shadow-transition);
        cursor: text;
      }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }
        #search_box {
         font-size: 22px;
        }

        #search_box > #search_text_editable {
          display: inline-block;
          margin-left: 8px;
          min-width: 10px;
        }

        #search_text_editable,#search_text_editable:active,#search_text_editable:focus   {
            border:none;
            outline:none;
         }
         
         #search_text_editable[empty]::before {
          content: "Search";
          color: var(--paper-grey-500);
         }

        #search_text_editable:active::before,#search_text_editable:focus::before {
          content: "";
        }

        #results {
          padding: 0;
        }
        
        .result {
          padding: 4px;
          color: var(--paper-grey-800);
               display: flex;
                 flex-direction: row;
          justify-content: space-between;
          align-content: center;
          align-items: center;

        }
        .result > div {
          width: fit-content;
          margin: 8px;
        }

        .graphic {
          width: 64px;
        }
        .info {
          flex: 3;
          
        }
        .name {
          
        }
        .subinfo {
          font-size: 80%;
          color: var(--paper-grey-600);
          
        }
        .actions {
          text-align: right;
          
        }
      
        .top-app-bar-adjust {
          margin-top: 64px;
        }
        mwc-fab {
          position: fixed;
          right: 48px;
          bottom: 48px;
        }

        .drawer {

        }

        #units {
          height: 100%;
          max-width: max( 50vw, 800px);
          box-shadow: var(--shadow-elevation-3dp_-_box-shadow);
        }
        #detail {
          height: 100%;
          flex: 1 1;
          width: 100%;
        }
        .header_info {
          display: inline-block;
          border-radius: 12px;
          background-color: var(--increase-secondary);
          color: var(--increase-color);
          padding: 8px 12px;
          font-size: 20px;
          margin-right: 24px;

          position: relative; 
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center; 
          overflow: hidden;
          height: 50%;
        }
        .header_info > * {
          margin-right: 2em;
        }

        .header_info > .progress_bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          background-color: var(--increase-color);
          opacity: 0.2;

        }

        .info_detail {
          margin-left: 1em;
          margin-right: 1em;
          font-size: 12px;
          opacity: 0.8;
        }
`;

class IncreasesPage extends MDuesPage {
  static styles = [super.styles, increases_page_styles]
  static icon = "attach_money"
  static default_title = "Increases"
  static properties = { ...super.properties, detail: {type: Object }, new_dialog_open: { type: Boolean }, new_dialog_error: { type: Boolean }}

  renderPage() {
    return html`
      <div class="column" >
        <increase-search .mdues_period=${this.mdp ? this.mdp.id : null} @search=${({ detail }) => this.search = detail} }></increase-search>
  
        <!-- <increase-list .only_chips=${true} .q=${SearchAgreements} .mdues_period=${this.mdp ? this.mdp.id : null} .search=${{...this.search, promoted: false}} -->
        <!-- @detail=${({ detail }) => this.detail = detail}  @status=${({ detail }) => this.status = detail}></increase-list> -->
             
        <increase-list class="refresh-list" .mdues_period=${this.mdp ? this.mdp.id : null} .q=${SearchAgreementInfo} .search=${ { ...this.search }} @detail=${({ detail }) => this.detail = detail}  @status=${({ detail }) => this.status = detail}
        >
        </increase-list>
      </div>
      
      ${this.new_dialog_open? html`
      <newincrease-dialog @saveall=${e=>this.handle_item_saved(e.detail)}
      @newincrease-error=${e=>{this.new_dialog_error=true}}
      @newincreaseclosed=${e=>{{this.new_dialog_open=false}}}
      .period_id=${this?.mdp?.id}
      >
      </newincrease-dialog>` : ''}
    `;
  }
  
  handle_item_saved(selection) {
     let list = this.renderRoot.querySelector('increase-list');
     
     list?.promoteTargets(selection);
  }  
  /*
     <increase-list .only_chips=${true} .q=${SearchAgreements} .mdues_period=${this.mdp ? this.mdp.id : null} .search=${{...this.search, promoted: false}} @detail=${({ detail }) => this.detail = detail}  @status=${({ detail }) => this.status = detail}></increase-list>
        <div class="row">
          <div id="units"><increase-list .search=${this.search} @detail=${({detail}) => this.detail = detail}></increase-list></div>
          <div id="detail"><entity-detail .detail=${this.detail}></entity-detail></div>
        </div>
  */
  renderExtraItems() {
    if (!this.status) return super.renderExtraItems();
    const stat = this?.mdp?.stats?.total_stat_members ?? 0;
    const done = this?.status?.period_total_adjusted_members ?? 0;
    const have_inc = this?.status?.period_total_adjusted_members_less_negotiation ?? 0;
    const average_increase = this?.status?.average_increase ?? 0;
    return html`
      <mwc-button slot="actionItems" icon="add"  @click=${e => this.addNewIncrease()}>New Increase&nbsp&nbsp</mwc-button>
      <div class="header_info progress" slot="actionItems"><div class="progress_bar" style="width: ${100.0 * done / stat}%"></div>${Math.round(100.0 * done / stat)}% <span class="info_detail">(${done}/${stat})</span></div>
      <div class="header_info avg" slot="actionItems"><span class="info_detail">increase</span>${average_increase !== null ? average_increase.toLocaleString() : 0}%</div>
      ${super.renderExtraItems()}
    `
  }
  
  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      detail: { type: Object },
      status: { type: Object }
    };
  }
  
  addNewIncrease() {
      if (!this.new_dialog_open)
        this.new_dialog_open = true;
  }  

}

window.customElements.define('increases-page', IncreasesPage);
export { IncreasesPage }

const new_increase_style = css`
  mwc-dialog {
    --mdc-dialog-scrim-color: rgba(0, 0, 0, 0.6);
    --mdc-dialog-min-width: 1300px;
    --mdc-dialog-max-width: 1300px;
    --mdc-dialog-min-height: 700px;
    --mdc-dialog-max-height: 700px;
  }      
 
  kale-textfield {
     --kale-textfield-width: 500px;
  }

.divider{
    position:absolute;
    left:50%;
    top:30%;
    bottom:5%;
    border-left:1px solid gray;
}  

.flex-row{
  display: flex;
  flex-flow: row;
}

* {
  box-sizing: border-box;
}

.column {
  width: 50%;
  padding: 10px;
  height: 300px;
  overflow-y: overlay;
}

.item-chip.unit-target {
--chip-background: var(--unit-color);
--chip-color: white;
}

.empty_message {
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  font-weight: 100;
  opacity: 0.75;
}
kale-chip[ismaster] {
  background-color: var(--master-color);
}

`;

class NewIncreaseDialog extends NewItemDialog {
  static styles = new_increase_style;
  
  static properties = { ...super.properties, table_view: {type: Boolean}, item: { type: Object }, 
        editing: { type: Boolean }, selectable: { type: Boolean }, expanded: { type: Boolean }, search: { type: Object },
        periods: { type: Array }, period_id: { type: Number },  selection: { type: Array }}
        
  static mutation_class = EditAgreement;
  selection = [];
      
  
  static get properties() {
    return {
      ...(super.properties),
      opened: {type: Boolean }
    };
  }
  
  dataUpdate(new_data) {
    this.item = new_data;
  }
  
  finalUpdate(new_data) {
    this.dispatchEvent(new CustomEvent('item-saved', { bubbles: true, composed: true, detail: new_data }));
  }
  
  updateList() {
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.item }))
  }
  
  get mutation_instance() {
    if (!this.constructor.mutation_class) 
        return null;
    
    if (!this._mutation) 
        this._mutation = new (this.constructor.mutation_class)(
            (item) => this.dataUpdate(item),
            { changeMap: null },
            (item) => this.finalUpdate(item),
            (err) => console.error(err) //FIXME: more error checking/alerts
       );
    
    return this._mutation;
  }
  
  save_impl(data) {   
      this.dispatchEvent(new CustomEvent('saveall', { bubbles: true, composed: true, detail: this.selection })); 
  }  
  
  addSelection(target) {
      this.selection = [...this.selection, target];
      this.requestUpdate();
  }
  
  removeSelection(target) {
      this.selection =  this.selection?.filter(s=>s.id!=target.id);
      this.renderRoot.querySelector('increase-list')?.push?.(target);
      this.requestUpdate();
  }

  chipDesc(agreement) {
    let units = agreement?.units;
    if (units) {
      if (units.length === 1) {
        return `${agreement.name} - ${formatAffiliateWithState(units[0].unit)}${units[0]?.unit?.subunit && String(units[0]?.unit?.subunit).trim() !== '' ? ` / subunit ${units[0]?.unit?.subunit}` : ''}`
      } else {
        return `${agreement?.name}`;
        //return `${agreement?.name} (${states.join('/')})`;
      }
      
    } else {
      return agreement.name;
    }
  }
  
  render()  {
    return  html`     
      <mwc-dialog id="newIncreaseDialog" heading="Find Increase Agreements/Units" open .title="New Increase"  
      @closed=${e=>{console.log("NewIncreaseDialog === ", e, this); this.dispatchEvent(new CustomEvent('newincreaseclosed', { bubbles: true, composed: true, detail: null })) }}>
        
        <div>
        <increase-search .mdues_period=${this?.period_id} @search=${({ detail }) => this.search = detail}></increase-search>
        </div>
        
        <div class="flex-row">
            <div class="column">
                <increase-list .ignore=${this.selection || []} .only_chips=${true} .q=${SearchAgreements} .mdues_period=${this?.period_id} .search=${{...this.search, promoted: false}} 
                @detail=${({detail}) => this.detail = detail}  
                @status=${({detail}) => this.status = detail}  
                @item-selection=${({detail}) => this.addSelection(detail)}>
                </increase-list>
            </div>
            <div class="column">
                ${!this.selection || this.selection.length === 0 ? html`<div class="empty_message">click results at left to add units/masters</div>` : nothing }
                ${this.selection?.map(agreement => {
                  return html`
                  <kale-chip
                    style="margin:4px;"
                    class="item-chip unit-target"
                    @click=${e => this.removeSelection(agreement)} 
                    label=${this.chipDesc(agreement)}
                    ?ismaster=${agreement?.units?.length > 1}
                ></kale-chip>`
              })} 
            </div>      

        </div>
        <mwc-button
            id="primary-action-button"
            slot="primaryAction"
            @click=${e => this.save()}
            dialogAction="increasesaved"
            ?disabled=${!(this.selection && this.selection.length > 0)}
            >
            ${this.selection && this.selection.length > 0  ? `add ${this.selection.length} item${this.selection.length > 1 ? 's' : ''}` : 'add'}
        </mwc-button>
      
        <mwc-button
            slot="secondaryAction"
            dialogAction="close">
            Cancel
        </mwc-button>

      </mwc-dialog>`
      ;
  }

}
                              
window.customElements.define('newincrease-dialog', NewIncreaseDialog);
export { NewIncreaseDialog }
