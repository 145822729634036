import { LitElement, html, css, nothing } from 'lit';
import { repeat } from 'lit/directives/repeat.js';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import gql from 'graphql-tag';
import { client, formatQueryError } from '../queries/client.js';
import { wait } from '../shared-components/utilities/anim.js';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { SearchAffiliates, SearchUnits, EditUnit, EditUnitAlias } from '../queries/queries.js';
import { MDuesResultList, formatAffiliate, MDuesListItem, NewItemDialog, MduesSearchBox } from '../components/result_list.js';
import XLSX from 'xlsx';

export class AffiliateSearchBox extends MduesSearchBox {
  renderOptions() {
    return html`
    `
  }
}

const affiliate_item_styles = css`
  :host {
    --item-primary-color: var(--unit-color);
  }

  :host([deprecated]) {
    --item-primary-color: var(--unit-deprecated-color);
  }
  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }
  .item-chip {
    --chip-background: var(--unit-secondary-color);
    --kale-chip-border: 1px dotted var(--unit-color);
    --chip-color: var(--unit-color);
  }
  .container {
        display: table;
  }
  .row  {
        display: table-row;
  }
  .left, .right, .middle {
        display: table-cell;
        padding-left: 150px;
        padding-right: 100px;
        // border:1px solid #FF0000;
        width: 150px;
  }
  .left p, .right p, .middle p {
        margin: 10px 10px;
  }

  .aff-item {
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    align-items: center;
    border-radius: 8px;
    background-color: var(--affiliate-color, --paper-teal-600);
    color: white;
    width: max(30vw, 300px);
    margin-left: auto;
    margin-right: auto;
    padding: 8px 16px;
    cursor: pointer;
    box-sizing: border-box;
  }

  div.aff-item-chip {
    display: flex;
    flex-flow: row nowrap;
    white-space: nowrap;
    align-items: center;
    border-radius: 18px;
    background-color: var(--affiliate-color, var(--paper-teal-600));
    border: 1px solid rgba(0,0,0,0);
    color: white;
    margin-left: auto;
    margin-right: auto;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 70%;
    padding-top: 8px;
    flex: 1;
    user-select: none;
  }

  div.aff-item-chip[is_selected] {
    border: 1px solid var(--paper-grey-600);
    background-color: white;
    color: var(--paper-grey-600);
    opacity: 0.8;
  }

  div.aff-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
  div.aff-info > * {
    font-weight: 100;
    font-size: 75%;
    align-self: flex-end;
  }
  div.aff-info > *[is_selected] {
    font-style: oblique;
  }

  div.aff-info > *:first-child {
    font-weight: bold;
    font-size: 100%;
    align-self: center;
    flex: 1;
  }
`;

const getjsdate = (d, xl, offset) => {
  let ret = null;
  if (d) {
    let jd;
    if (typeof (d) === 'object' && d.constructor.name === 'Date') {
      jd = d
      //ret = d.toLocaleDateString();
    } else {
      jd = d.match && d.match(/\+/) ? (new Date(d)) : (new Date(d + 'EST'));
    }
    if (offset !== undefined) {
      jd.setHours(jd.getHours() + offset);
    }
    ret = jd//.toLocaleDateString();
    let x = new Date(d);
  }
  return ret;
}
const XLSX_NULL = { v: null, t: 'z' };
export const xlsx_formatters = {
  date: celldata => {
    let d = getjsdate(celldata, true);
    let str = d ? d.toLocaleDateString() : null;
    return celldata ? { v: str, t: 'd', z: 'mm/dd/yyyy' } : XLSX_NULL;
  },
  number: celldata => {
    return celldata ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  bool: celldata => {
    return celldata ? { v: celldata ? 'Y' : 'N', t: 's' } : XLSX_NULL;
  },
  int: celldata => {
    return celldata ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  money: celldata => {
    return celldata ? { v: celldata, t: 'n', z: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00' } : XLSX_NULL;
  },
  cents: celldata => {
    return celldata ? { v: celldata, t: 'n', z: '[$$-409]#,##0.00;[RED]-[$$-409]#,##0.00' } : XLSX_NULL;
  },
  decimal: (celldata, fieldinfo) => {
    return celldata ? { v: celldata, t: 'n' } : XLSX_NULL;
  },
  percent: (celldata, fieldinfo) => {
    return (celldata ?? false) ? { v: celldata/100, t: 'n', z: "0.00%" } : XLSX_NULL;
  },
  text: celldata => {
    return celldata ? { v: celldata, t: 's' } : XLSX_NULL;
  },
  ltext: celldata => {
    return celldata ? { v: celldata, t: 's' } : XLSX_NULL;
  },
  ctext: celldata => {
    return celldata ? { v: celldata, t: 's' } : XLSX_NULL;
  }
}

window.customElements.define('affiliate-search', AffiliateSearchBox);

export class AffiliateItem extends MDuesListItem {
    
  static styles = [...super.styles, affiliate_item_styles];
  static properties = { ...super.properties, period_id: {type: Number}, year: {type: Number}, is_selected: {type: Boolean}}
  
  set item(i) {
    super.item = i;
    if (i.deprecated) {
      this.setAttribute("deprecated", "");
    } else {
      this.removeAttribute("deprecated")
    }
  }
  get item() { return super.item }
  
  renderu() {
    const {state=null, type=null, chapter=null, rows=0} = this.item ?? {};
    return html`<div class="aff-item" @click=${() => this.downloadItem(this.item)}>
    <mwc-icon>table_chart</mwc-icon>
    <div class="aff-info">
    <span>${state} ${type === "C" ? "Council" : "Local"} ${chapter}</span>
    <span>${rows} row${rows == 1 ? '' : 's'}</span>
    </div>`
  }

  render() {
    const {state=null, type=null, chapter=null, rows=0} = this.item ?? {};
    return html`<div class="aff-item-chip" ?is_selected=${this.is_selected} @click=${() => this.selectItem(this.item)}>
    <div class="aff-info">
    <span>${state} ${type === "C" ? "Council" : "Local"} ${chapter}</span>
    ${this.is_selected ? html`<span is_selected>selected</span>` : html`<span>${rows} row${rows == 1 ? '' : 's'}</span>`}
    </div>`
  }

  selectItem(item) {
    this.dispatchEvent(new CustomEvent('affiliate-select', { bubbles: true, composed: true, detail: item }));
  }
  
}

window.customElements.define('affiliate-item', AffiliateItem);


const aff_list_styles = css`
  div.row {
    margin-top: 1em;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
  }

  div.chip-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    height: 100%;

    margin-left: 20px;
    margin-right: 36px;
    /*width: 50vw;
    max-width: max(400px, 50vw);
    min-width: min(400px, 100vw);
    */
  }
  div.selection-list {
    width: 100%;
    /*
    max-width: 100vw;
    min-width: none;
    margin-right: 32px;
    */

  }

  div.chip-list > * {
  }

  .no-results {
    font-weight: 100;
    font-style: oblique;
    opacity: 0.7;
    
  }

`;
        //<div class="chip-list selection-list">
         //   ${this.results.slice(0,10).map(result => this.renderItem(result))}
       // </div>

export class AffiliateList extends MDuesResultList {
  static styles = [...super.styles, aff_list_styles];
  static search_class = SearchAffiliates;
  static properties = { ...super.properties, year: {type: Number}, selected: {type: Array} }
  
  searchArgs() {
        let args = {...this.search, period: this.mdues_period, order: [{"state": "asc"}, {"chapter": "asc"}]};
        if (args.council) { args.type = "C"; }
        if (args.local) { args.type = "L"; }
        //console.warn(this.constructor.name, "SEARCHING WITH ***************************", JSON.stringify(args));
        return args;
  }

  render() {
    const selected = new Set(this.selected?.map?.(s => s.id) ?? []);
   // const current = (this.results ?? []).filter(s => !selected.has(s.id));
    return html`      
        ${this.in_progress ? html`
          <mwc-circular-progress class="page-progress" indeterminate></mwc-circular-progress>
        
        ` : ''}
        <div class="row">
        <div class="chip-list" ?in-progress=${this.in_progress} @cancel-new=${() => this.removeNew()} @saved-new=${() => this.removeNew()}>
            ${ repeat(this.results ?? [], result => result.id, result => this.renderItem(result, selected.has(result.id))) }
        </div>


      `;
  }

  renderItem(item, is_selected) {
    return html`<affiliate-item @update-list=${() => this.refresh()} .is_selected=${is_selected} .period_id=${this.mdues_period} .year=${this.year} .editing=${item.tempname !== undefined} .item=${item}></affiliate-item>`;
  }
  
}

window.customElements.define('affiliate-list', AffiliateList);

const affiliates_page_styles = css`
  :host { 
    background-color: white;
    --top-bar-color: var(--paper-pink-700);
  }

  #affiliates {
    height: 100%;
    max-width: max( 50vw, 800px);
    box-shadow: var(--shadow-elevation-3dp_-_box-shadow);
  }
  .subtitle {
    font-weight: 100;
    font-size: 60%;
    font-style: oblique;
  }
  div.chip-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    height: 100%;
    border: 1px solid var(--paper-pink-500);
    border-radius: 8px;
    padding: 8px;
    min-height: 64px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 8px;
  }

  div.chip-list > * {
    margin: 4px;
  }

  .selected {
    --affiliate-color: var(--paper-pink-600);
  }

  .select-message, .selection-count {
    opacity: 0.6;
    font-size: 80%;
    font-weight: 100;
    font-style: oblique;
    min-height: 1em;
  }

  .selection-area {
    width: calc(100% - 64px);
    margin: 20px 20px 0px;
    flex: 0 1 0%;
    flex-direction: column;
    align-items: flex-end;
    box-sizing: border-box;
  }

  mwc-button {
    --mdc-theme-primary: var(--paper-pink-600)
  }

  div.button-area {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  div.button-area > * {
    margin-bottom: 4px;
  }
  .selection-count {
    font-size: 60%;
  }

  .right {
    justify-content: flex-end;
    min-height: 56px;
  }
  .clear-button {
    margin-right: 12px;
  }

`;

class FormExportPage extends MDuesPage {
  static styles = [super.styles, affiliates_page_styles]
  static icon = "table_chart"
  static default_title = "Forms"
  static properties = {
    ...super.properties,
    detail: {type: Object },
    new_dialog_open: { type: Boolean },
    new_dialog_error: { type: Boolean },
    selection: {type: Array}
  }

  //get period_dependent() { return false; }
  
  renderTitle() {
      return html`<span id="main_title">${this.title}</span><span class="subtitle">${this.mdp?.year+1} forms pre-populated with ${this.mdp?.year} data</span>`
  }

  selectItem(item) {
    this.selection = this.selection ?? [];
    if (this.selection.some(s => s.id === item.id)) return this.deselectItem(item);
    this.selection = [...this.selection.filter(s => s.id !== item.id), item];
  }
  deselectItem(item) {
    this.selection = this.selection?.filter?.(s => s.id !== item.id) ?? [];
  }
  renderPage() {
    const rows = this.selection?.reduce?.((acc, cur) => acc + cur.rows, 0) ?? 0;
    const affs = this.selection?.length ?? 0;
    return html`
        <div class="column">
          <div class="row selection-area">
            <div class="chip-list">
            ${ this.selection && this.selection?.length > 0 ? 
              repeat(this.selection ?? [], item => item.id, item => html`
              <affiliate-item
                class="selected"
                @affiliate-select=${e => this.deselectItem(e.detail)} 
                .period_id=${this.mdues_period}
                .year=${this.year}
                .item=${item}
              ></affiliate-item>
            `) : html`<span class="select-message">pick affiliates below&hellip;</span>`}
          </div>
          <div class="row right">
            <mwc-button class="clear-button" @click=${e => this.selection = []}>clear</mwc-button>
            <div class="button-area">
              <mwc-button raised ?disabled=${affs == 0} @click=${e => this.downloadSelected()}>download</mwc-button>
              <div class="selection-count">
                ${affs > 0 ? html`
                ${rows} row${rows === 1 ? '' : 's'} from ${affs} affiliate${affs === 1 ? '' : 's'}` : nothing}
              </div>
            </div>
          </div>
        </div>

        <affiliate-search compact @search=${({ detail }) => this.search = detail}></affiliate-search>    
        <affiliate-list class="refresh-list" @affiliate-select=${e => this.selectItem(e.detail)} .selected=${this.selection} .mdues_period=${this.mdp ? this.mdp.id : null} .year=${this?.mdp?.year} .search=${this.search} @detail=${({ detail }) => this.detail = detail} .table_view=${this.table_view}  ></affiliate-list>
        </div>
        `;     
            
  }
  async downloadSelected() {

    console.log("DOWNLOADING AFFS", this.selection);

    const export_query = gql`
        query export_query ($aff_ids: [String]!, $period_id: Int!) {
            virtual_export_fixed (
                where: {
                  _and: [
                    {affiliate_id: {_in: $aff_ids}},
                    {period_id: {_eq: $period_id}},
                  ]}
                
            ) 
            {
                affiliate_id
                period_id
                year

                state
                council
                local
                subunit

                master
                unit_name
                master_name
                
                number_of_members
                agreement_eff_date
                agreement_exp_date

                contact
            }
        }
    `; 
 
     
    console.log("running query with", {aff_ids: this.selection.map(s => s.id), period_id: this.mdp?.id});
     let export_result = await client.query({
      fetchPolicy: 'network-only',
      query: export_query,
      variables: {aff_ids: this.selection.map(s => s.id), period_id: this.mdp?.id} 
    });
     
     // .then(response => { console.log(response); export_data = response })
     // .catch(error => console.error(error));   
    
    console.log("export_result ======== ", export_result.data.virtual_export_fixed);
    
    let export_data = export_result.data.virtual_export_fixed.map(obj => {
      //console.log("object =====**************** ", obj); 
      return [
        { type: "ltext", data: obj.state },
        { type: "ltext", data: obj.council },
        { type: "ltext", data: obj.local },
        { type: "ltext", data: obj.subunit },
        { type: "ctext", data: obj.master_name },
        { type: "ltext", data: obj.unit_name },
        { type: "date", data: obj.agreement_eff_date },
        { type: "date", data: obj.agreement_exp_date },
        { type: "number", data: obj.number_of_members },
        { type: "percent", data: null },
        { type: "number", data: null },
        { type: "number", data: null },
        { type: "decimal", data: null },
        { type: "decimal", data: null },
        { type: "date", data: null },
        { type: "ctext", data: null },
        { type: "ltext", data: obj?.contact?.name },
        { type: "ltext", data: obj?.contact?.email },
        { type: "ltext", data: obj?.contact?.phone }
      ].map(({type, data}) => xlsx_formatters[type](data))
    });

    var wb = XLSX.utils.book_new(); // create workbook
    var ws = XLSX.utils.aoa_to_sheet(
      [
        [
          "State",
          "Council",
          "Local",
          "Chapter/Subunit", "Master Agreement?",
          "Employer/Master",
          "Agreement Effective Date",
          "Agreement Expiration Date",
          "Number of Members",
          "Percent Wage Increase",
          "Cents Per Hour Increase",
          "If cents per hour, average hourly wage before the increase",
          "Dollar Lump Sum Increase",
          "If dollar lump sum, average annual wage before the increase",
          "Effective Date of Increase",
          `Is this unit still in negotiations for the 8/1/${this.year-1} to 7/31/${this.year} period? (Yes or No)`,
          "Your Name",
          "Your Email",
          "Your Phone",
          "Any special wage adjustments, furloughs or other comments?"
        ].map(v => ({v, t: "s", s: {alignment: {wrapText: '1'}} })),
       ...export_data]);// create worksheet
    var ws_name = 'Report';


    const fitToColumn = (arrayOfArray) => arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] !== null && a2[i] !== undefined ? (typeof (a2[i]) === 'object' && a2[i].constructor.name === 'Date' ? a2[i].toLocaleDateString() : a2[i].toString()).length : 0)) }));
    let d2 = XLSX.utils.sheet_to_json(ws, { header: 1 });
    ws['!cols'] = fitToColumn(d2);

    XLSX.utils.book_append_sheet(wb, ws, ws_name); // add worksheet to workbook
    const affs = this.selection.map(a => `${a.state}-${a.type}${a.chapter}`).join("+")
    XLSX.writeFile(wb, `mindues-${this.mdp?.year+1}_${affs}.xlsx`); // write xls to file
  }

}

window.customElements.define('form-export-page', FormExportPage);
export { FormExportPage }


