
import { LitElement, html, css, nothing } from 'lit';
import { KalePage } from '../shared-components/page.js';
import gql from 'graphql-tag';
import { client, SearchPeriods } from '../queries/queries.js';
import '@material/mwc-icon-button';

const mdues_page_styles = css`
  :host {
    --alias-color: var(--paper-pink-700);
    --unit-color: var(--paper-teal-700);
    --unit-deprecated-color: var(--paper-teal-400);
    --unit-secondary-color: var(--paper-teal-100);
    --master-color: var(--paper-purple-700);
    --virtual-color: var(--paper-grey-700);
    --increase-color: var(--paper-cyan-900);
    --increase-secondary: var(--paper-cyan-100);
    --period-color: var(--paper-green-700);
    --reporting-color: var(--paper-blue-800);
    --reporting-secondary: var(--paper-blue-400);
  }

  div.title {
    position: relative;
    width: 200px;
  }

  mwc-select.title {
    position: fixed;
    left: 100px;
    top: 5px;
  }
  mwc-select[slot='actionItems'], mwc-select.title {
    --mdc-theme-primary: var(--top-bar-secondary, white);
    --mdc-theme-text-primary-on-background: var(--top-bar-secondary, white);
    --mdc-theme-surface: var(--top-bar-color, purple);
    --mdc-select-fill-color: var(--top-bar-color, white);
    --mdc-select-ink-color: var(--top-bar-secondary, white);
    --mdc-select-label-ink-color: var(--top-bar-secondary, white);
    --mdc-select-dropdown-icon-color: var(--top-bar-secondary, white);

    --mdc-select-idle-line-color: var(--top-var-color);
    --mdc-select-hover-line-color: var(--top-bar-secondary);
    --mdc-select-outlined-idle-border-color: var(--top-bar-color);
    --mdc-select-outlined-hover-border-color: white;

    /* inherits the styles of mwc-menu internally */
    --mdc-menu-item-height: 30px;

    /* inherits the styles of mwc-list internally */
    --mdc-list-vertical-padding: 0px;
    --mdc-list-side-padding: 30px;
    background-color: red;
  }

  .flex-column {
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0px;
  }

  .flex-row {
    flex: 1 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
    
  mwc-fab {
    position: fixed;
    right: 48px;
    bottom: 48px;
  }

  .column {
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column; 
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 0px;
  }
  .column > * {
  }
  
  .row {
    flex: 1 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }


  mwc-select {
    --mdc-notched-outline-trailing-border-radius: 0 28px 28px 0;
  }

  mwc-icon-button[toggled] {
    background-color: var(--paper-grey-100);
    border-radius: 100%;
    color: var(--top-bar-color);
  }

  mwc-icon-button {
    margin-right: 20px;
    transform: scale(1) rotate(0);
  }

  div.pending_control {
    position: relative;
    overflow: visible;
  }
  div.pending_control > mwc-icon-button {
    --mdc-theme-text-disabled-on-light: var(--paper-grey-400);

  }
  span.pending_badge {
    font-size: 60%;
    color: white;
    position: absolute;
    left: 28px;
    top: 6px;
    background-color: var(--paper-green-800);
    border-radius: 3px;
    padding: 2px 4px;
    color: white;
    font-weight: 700;
    visibility: hidden;
  }
  span.pending_badge[pending] {
    background-color: var(--paper-red-800);
    visibility: visible;
  }

  @keyframes pulse {
    from { transform: scale(1.2) }
    80% { transform: scale(0.95)}
    to { transform: scale(1.2)  }
  }

  @keyframes spin {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
  }

  mwc-icon-button[working] {
    animation: pulse 1s infinite;
  }
  mwc-icon-button.pending_icon {
    transform: scale(1) rotate(0deg);
  }
`

function abbreviateNumber(value) {
  var newValue = value;
  if (value >= 1000) {
      var suffixes = ["", "k", "m", "b","t"];
      var suffixNum = Math.floor( (""+value).length/3 );
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum != 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 != 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

let current_period = null;
export const get_current_period = () => current_period;
export const set_current_period = (p, elem) => {
  if (elem && p !== current_period) {
    console.warn("sending p change", p)
    elem.dispatchEvent(new CustomEvent('period-changed', {detail: p, bubbles: true, composed: true }));
  } 
  current_period = p;
}


export class MDuesPage extends KalePage {
  static styles = [super.styles, mdues_page_styles]
  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      periods: { type: Array },
      table_view: { type: Boolean},
      update_in_progress: { type: Boolean},
    };
  }
  get pending_updates() { return this._pending_updates }
  set pending_updates(p) { 
    if (this._pending_updates != p) {
      this._pending_updates = p;
      this.refreshResults?.();
      this.requestUpdate('pending_updates');
    }

  }

  refreshResults() {
    this.renderRoot?.querySelectorAll?.('.refresh-list')?.forEach(r => {
      r?.refresh?.()
    });
  }
  period_changed(p) {

  }

  async do_set_mdp(p) {
    this.ignore_mdp_change = true;
    set_current_period(p, this);
    this.period_changed(p);
    this.requestUpdate('mdp');
    await this.updateComplete;
    this.ignore_mdp_change = false;
  }

  set mdp(p) {
    if (this.ignore_mdp_change || get_current_period() === p) return;
    this.do_set_mdp(p);
  }

  get mdp() { 
    return get_current_period();
  }

  set mdues_period(p) {
    set_current_period(p, this);
    this.requestUpdate('mdues_period');
  }
  get mdues_period() { return get_current_period() }

  getPeriods() {
    const periods = new SearchPeriods(res => { 
      this.periods = res; 
      if (!this.mdp || !res.find(p => p.id === this.mdp?.id)) {
        set_current_period(res[0]);
        this.period_changed(res[0]);
        this.requestUpdate('mdp');
      }
    }, {});
  }
  constructor() {
    super();
    this.getPeriods();
  }

  async activateRoute() {
    //console.warn("PAGE ACTIVATED", this.title, this.mdp?.id, '=>', get_current_period()?.id);
    this.mdp = get_current_period();
    this.requestUpdate("mdp");
    this.getPeriods();
  }

  get period_dependent() { return true; }
  renderPreTitle() {
    const { periods, mdp, period_dependent } = this;
    return period_dependent ? html`
    <div class="title">
      <mwc-select class="title">
        ${periods ? periods.map(p => html`
          <mwc-list-item value=${p.id} ?selected=${mdp.id === p.id} @request-selected=${e => { if (e?.detail?.source === 'interaction') { console.log("selected", p, e); this.mdp = p; } }}>${p.locked ? '🔒' : ''} ${p.year}${p.name && String(p.year)!==p.name ? `: ${p.name}` : ''}</mwc-list-item>
        `) : ''}
      </mwc-select>
    </div>
    ` : nothing;
  }

  manualDBUpdate() {
    this.update_in_progress = true;
    console.log("Sending update request for", this.pending_updates);
    this.dispatchEvent(new CustomEvent('snackbar', {
      bubbles: true,
      composed: true,
      detail: {
      text: `queuing ${this.pending_updates} DB updates`
    }
    }));

    client.mutate({
      mutation: gql`
      mutation single_request {
        insert_update_requests_one(
          object: {
            requested: ${this.pending_updates}
          }
        ) {
          id
          ts
          end_ts
          requested
          completed
        }
      }
      `
    }).then(d => {
      console.warn(d);
      this.update_in_progress = false;

      this.dispatchEvent(new CustomEvent('snackbar', {
        bubbles: true,
        composed: true,
        detail: {
          text: `completed ${this.pending_updates} DB updates`
        }
      })); // TODO: undo
    })
  }


  renderExtraItems() {
    const { pending_updates, update_in_progress } = this;
    //return pending_updates ?  html`<mwc-button outlined slot="actionItems">${this.pending_updates} updates</mwc-button>` : '';
    return html`
    <div class="pending_control" slot="actionItems" title=${pending_updates ? `${pending_updates?.toLocaleString()} updates
      pending` : "✓ up to date" }>
      <mwc-icon-button class="pending_icon" ?working=${update_in_progress} icon=${pending_updates ? "pending" : "check_circle"} @click=${()=> this.manualDBUpdate()}></mwc-icon-button>
      <span class="pending_badge" ?pending=${pending_updates}>${pending_updates ? abbreviateNumber(pending_updates) :
        '✓'}</span>
    </div>`;
  }
}
