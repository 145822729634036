import gql from 'graphql-tag';
import { StaticQuery, SaveableQuery } from './client.js';


export const refetch_searches = ["target_search", "unit_search", "agreement_search", "unit_increase_search"];

/*
export const status_fields = gql`
  fragment StatusFields on status{
    code
    desc
    next_code
    next {
      code
      desc
    }
    possible_previous {
      code 
      desc
    }
  }
`;*/
export const period_stats_fields = gql`
  fragment PeriodStats on cached_period_stats {
       total_adjusted_members
       total_stat_members
       period_total_adjusted_members
       period_total_adjusted_members_less_negotiation
       average_increase
  } 
  `;

export const file_upload_fields = gql`
 fragment FileUploadFields on file_upload {
   id
   timestamp
   filename
   hash
   format
   data
   status
 }
`;

export const period_fields = gql`
  fragment PeriodFields on core_period {
    id
    name
    year
    locked
    stats { ...PeriodStats }
  } 
  ${period_stats_fields}
  `;

export const periods_query = gql`
  query period_info($order: [core_period_order_by!] = [{year: desc}, {locked: asc}]) {
    period:core_period(order_by: $order) {
         ...PeriodFields
    }
  }
  ${period_fields}
`

export class SearchPeriods extends StaticQuery {
  get gql_impl() {
    return periods_query;
  }
  data_impl({ data: { period } }) {
    return period;
  }
  query(vars) {
    vars.period = undefined;
    super.query(vars);
  }
}

export const file_uploads_query = gql`
  query uploads($period: Int!, $order: [file_upload_order_by!]) {
    file_upload(order_by: $order, where: {period_id: {_eq: $period}}) {
         ...FileUploadFields
    }
  }
  ${file_upload_fields}
`

export class SearchFileUploads extends StaticQuery {
  get gql_impl() {
    return file_uploads_query;
  }
  data_impl({ data: { file_upload } }) {
    return file_upload;
  }
}



export const increase_fields = gql`
  fragment IncreaseFields on core_increase {
    id
    agreement_id
    members
    increase_type
    increase_value
    increase_base_value
    updated
    adjusted_members
    weighted_increase
    increase_pct
    effective_date
    comment
    in_negotiation
  }
  `;

export const unit_fields = gql`
  fragment UnitFields on core_unit {
    id
    name
    state
    council
    local  
    subunit
    localid 
    aff_pk
    updated

    deprecated
  

    stat_local {
      localnum
      localname
      affpk
    }

    agreements {
      agreement_info {
        period_id
      }
    }

    aliases { 
      id
      name
    }

  }
  `;


 export const simple_agreement_fields = gql`
  fragment SimpleAgreementFields on cached_agreement {
    id
    unit_id
    master
    promoted
    name
    period_id
    period { ...PeriodFields }
   }
  ${period_fields}
   `;


export const agreement_info_fields = gql`
  fragment AgreementInfoFields on core_agreement_info {
    agreement_id
    id:agreement_id
    uid
    in_negotiation
    effective_date
    expires_date
    stat_cap
    members
    total_members
    adjusted_members
    adjusted_members_in_negotiation
    total_adjusted_members
    total_adjusted_members_in_negotiation
    weighted_increase
    total_weighted_increase
    updated
    stat_override
    units {
      unit {
        state
        council
        local
        subunit
      }
    }
    increases { ...IncreaseFields }
    period { ...PeriodFields }
    agreement { ...SimpleAgreementFields }
  }
  ${increase_fields}
  ${unit_fields}
  ${period_fields}
  ${simple_agreement_fields}
  `;

export const agreement_fields = gql`
  fragment AgreementFields on cached_agreement {
    id
    unit_id
    master
    promoted
    name
    period_id
    increases { ...IncreaseFields }
    period { ...PeriodFields }
    info { 
      ...AgreementInfoFields
      units {
        unit {
          state
          council
          local
          subunit
        }
      }
     }
   }
  ${increase_fields}
  ${agreement_info_fields}
  ${unit_fields}
  ${period_fields}
   `;

    /*units { 
      unit {
        ...UnitFields 
      }
    }*/
export const master_agreement_fields = gql`
  fragment MasterAgreementFields on core_master {
    id
    name
    period_id
    updated
    increases { ...IncreaseFields }
    report_individually
    units(order_by: [{unit: {local: asc}}, {unit: {subunit: asc}}]) { 

      unit {
        ...UnitFields 
      }
    }
  }
  ${increase_fields}
  ${unit_fields}
`;

export const unit_agreement_fields = gql`
 fragment UnitAgreementFields on core_unit {
         agreements {
           agreement {
             ...AgreementFields
           }
         }
  }
  ${agreement_fields}
`;


export const unit_info_query = gql`
  query unit_info($UnitId: Int!){
    unit:core_unit( where: {id: {_eq: $UnitId}} ){
         ...UnitFields
         ...UnitAgreementFields
    }
  }
  ${unit_fields}
  ${unit_agreement_fields}
`

export const units_query = gql`
  query unit_info{
    unit:core_unit {
         ...UnitFields
    }
  }
  ${unit_fields}
`;
      /*stat(where: {_or: [{period_id: {_eq: $period}}, {_not: {period_id: {}}}]}) {
        period_id
        stat
      }*/

export const units_search = gql`
  query unit_search($include_deprecated: Boolean=false, $limit: Int=20, $tscursor: String, $period: Int, $filter: String, $state: String, $council: Int, $local: Int, $subunit: String, $hasmaster: Boolean, $order: [core_unit_order_by!]){
    unit:core_unit(
      order_by: $order,
      limit: $limit,
      where: {
        _and: 
         [
           {_or: [
             {name: {_ilike: $filter}}, 
             {aliases: {name: {_ilike: $filter}}}
           ]},
           {state: {_ilike: $state}},
           {council: {_eq: $council}},
           {local: {_eq: $local}},
           {subunit: {_eq: $subunit}}
           {_or: [
            {deprecated: {_eq: $include_deprecated}},
           ]}
         ]
      }
      ){
         ...UnitFields
         stat(where: {_or: [{period_id: {_eq: $period}}, {_not: {period_id: {}}}]}) {
           period_id
           stat
         }
    }
  }
  ${unit_fields}
`;

export const affiliates_search = gql`
  query affiliates_search($filter: String, $state: String, $council: Int, $local: Int, $type: String, $unit: Int, $limit: Int = 10000, $tscursor: String, $period: Int, $order: [virtual_affiliates_order_by!]) {
  affiliate: virtual_affiliates(
      order_by: $order,
      limit: $limit,
      where: {
          _and: 
          [
            {period: {_eq: $period}}
            {_or: [ 
              {_and:
                  [
                    {chapter: {_eq: $unit}},
                    {id: {_ilike: $filter}},
                    {state: {_ilike: $state}},
                    {chapter: {_eq: $local}},
                    {chapter: {_eq: $unit}}
                  ]
                },
              ]},
            {_and: [{period: {_eq: $period}}] },
            {_and: [
              {type: {_eq: $type}},
              {chapter: {_eq: $local}},
              {chapter: {_eq: $council}}
            ]},
          ]
        }
    )
    {
        id
        type
        chapter
        state
        period
        rows
    }
  }
`;


export const master_search = gql`
  query masters_search($limit: Int=20, $tscursor: String, $period: Int!, $filter: String, $state: String, $council: Int, $local: Int, $subunit: String,  $order: [core_master_order_by!]){
    master:core_master(
      order_by: $order,
      limit: $limit,
      where: { 
        _and: [ 
          {period_id: {_eq: $period}}, 
          {_or: [  
            {name: {_ilike: $filter}}, 
            {units: {unit: {name: {_ilike: $filter}}}}
         	]},
          {_or: [
            {_not: {units:{}}},
            {_and: [
              {units: {unit: {state: {_ilike: $state}}}}, 
              {units: {unit: {council: {_eq: $council}}}}, 
              {units: {unit: {local: {_eq: $local}}}}, 
              {units: {unit: {subunit: {_eq: $subunit}}}}
            ]}
          ]}
        ]}
      ){
      ...MasterAgreementFields
    }
  }
  ${master_agreement_fields}
`;

export const unpromoted_agreement_search = gql`
  query unpromoted_agreement_search($limit: Int=20, $tscursor: String, $period: Int!, $filter: String, $state: String, $council: Int, $local: Int, $subunit: String, $master_only: Boolean, $promoted: Boolean, $order: [cached_agreement_order_by!]){
    agreement:cached_agreement(
      limit: $limit,
      order_by: $order,
      where: {
        _and: 
        [ 
          {units: {unit: {deprecated: {_eq: false}}}},
          {period_id: {_eq: $period}}, 
          {master: {_eq: $master_only}}, 
          {promoted: {_eq: $promoted}}, 
          {_or: [  
            {units: {unit: {name: {_ilike: $filter}}}}
          ]}, 
          {_or: [
            {_not: {units:{}}},
            {_and: [
              {units: {unit: {state: {_ilike: $state}}}}, 
              {units: {unit: {council: {_eq: $council}}}}, 
              {units: {unit: {local: {_eq: $local}}}}, 
              {units: {unit: {subunit: {_eq: $subunit}}}}
            ]}
          ]}
        ]}
      ){
      ...AgreementFields
      
      units {
        unit {
          state
          council
          local
          subunit
        }
      }
    }
  }
  ${agreement_fields}
`;
export const agreement_info_search = gql`
  query agreement_search($limit: Int=50, $tscursor: String, $period: Int!, $filter: String, $state: String, $council: Int, $local: Int, $subunit: String, $master_only: Boolean, $promoted: Boolean, $order: [core_agreement_info_order_by!]){
    agreement:core_agreement_info(
      limit: $limit,
      order_by: $order,
      where: {
        _and: 
        [ 
          {agreement: {period_id: {_eq: $period}}}, 
          {agreement: {master: {_eq: $master_only}}}, 
          {_or: [  
            {agreement: {name: {_ilike: $filter}}}, 
            {units: {unit: {name: {_ilike: $filter}}}}
          ]}, 
          {_or: [
            {_not: {units:{}}},
            {_and: [
              {units: {unit: {state: {_ilike: $state}}}}, 
              {units: {unit: {council: {_eq: $council}}}}, 
              {units: {unit: {local: {_eq: $local}}}}, 
              {units: {unit: {subunit: {_eq: $subunit}}}}
            ]}
          ]}
        ]}
      ){
      ...AgreementInfoFields
    }
  }
  ${agreement_info_fields}
`;

export class QueryUnitsList extends StaticQuery {
  get gql_impl() {
    return units_query;
  }
  data_impl({ data: { unit } }) {
    return unit;
  }
}

export class UnitInfo extends StaticQuery {
  get gql_impl() {
    return unit_info_query;
  }
  data_impl({ data: { unit: [unit] } }) {
    return unit;
  }
}

export class SearchUnits extends StaticQuery {
  get gql_impl() {
    return units_search;
  }
  data_impl({ data: { unit } }) {
    return unit;
  }
}

export class SearchAffiliates extends StaticQuery {
  get gql_impl() {
    return affiliates_search;
  }
  data_impl({ data: { affiliate } }) {
    return affiliate;
  }
}

export class SearchAgreements extends StaticQuery {
  network_only = true;
  get gql_impl() {
    return unpromoted_agreement_search;
  }
  data_impl({ data: { agreement } }) {
    return agreement;
  }
}

export class SearchAgreementInfo extends StaticQuery {
  network_only = true;
  get gql_impl() {
    return agreement_info_search;
  }
  data_impl({ data: { agreement } }) {
    return agreement;
  }
}


export class SearchMasters extends StaticQuery {
  get gql_impl() {
    return master_search;
  }
  data_impl({ data: { master } }) {
    return master;
  }
}



export const upsert_unit_mutation = gql`
  mutation upsert_unit($changeMap: core_unit_insert_input!, $changeCols: [core_unit_update_column!]!) {
    unit:insert_core_unit(
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_unit_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...UnitFields
        all_agreements {
              agreement {
                id
                name
                promoted
                period_id
                increases {
                  ...IncreaseFields
                }
              }
            }
      }
    }
  }
  ${unit_fields}
  ${increase_fields}
`;

export const delete_unit_mutation = gql`
  mutation delete_unit($deleteId: Int!) {
    delete_core_unit(
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;


export class EditUnit extends SaveableQuery {
  friendly_name = "Unit"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return ['id', 'name'];
  }
  data_impl({ data: { unit: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_unit_mutation;
  }
  get delete_gql_impl() {
    return delete_unit_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}


export const upsert_unit_alias_mutation = gql`
  mutation upsert_unit_alias($changeMap: core_unit_alias_insert_input!, $changeCols: [core_unit_alias_update_column!]!) {
    unit_alias:insert_core_unit_alias (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_unit_alias_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        id
        unit_id
        name
      }
    }
  }
`;

export const delete_unit_alias_mutation = gql`
  mutation delete_unit_alias($deleteId: Int!) {
    delete_core_unit_alias (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;



/*
export class PersonNotes extends StaticQuery {
  get required_impl() {
    return ['PersonId'];
  }
  get gql_impl() {
    return person_note_for_person_query;
  }
  data_impl({ data: { person_note: person_note } }) {
    return person_note;
  }
  vars_from_impl(data) {
    return { 'PersonId': data.person_id };
  }
}*/


export class EditUnitAlias extends SaveableQuery {
  friendly_name = "Alias"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return [
      "unit_id"
    ];
  }
  data_impl({ data: { unit_alias: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_unit_alias_mutation;
  }
  get delete_gql_impl() {
    return delete_unit_alias_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}




export const upsert_period_mutation = gql`
  mutation upsert_period($changeMap: core_period_insert_input!, $changeCols: [core_period_update_column!]!) {
    period:insert_core_period(
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_period_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...PeriodFields
      }
    }
  }
  ${period_fields}
`;

export const delete_period_mutation = gql`
  mutation delete_core_period($deleteId: Int!) {
    delete_core_period(
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;


export class EditPeriod extends SaveableQuery {
  friendly_name = "Period"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return ['id', 'name'];
  }
  data_impl({ data: { period: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_period_mutation;
  }
  get delete_gql_impl() {
    return delete_period_mutation;
  }
}




export const upsert_master_member_mutation = gql`
  mutation upsert_master_member($changeMap: core_master_member_insert_input!, $changeCols: [core_master_member_update_column!]!) {
    master_member:insert_core_master_member (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_master_member_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        master_id
        unit_id
        unit {
          ...UnitFields
        }
      }
    }
  }
  ${unit_fields}
`;

export const delete_master_member_mutation = gql`
  mutation delete_master_member($unitId: Int!, $targetId: Int!) {
    delete_core_master_member (
      where: { _and: [{unit_id: {_eq: $unitId}}, {master_id: {_eq: $targetId}}] }
    ) {
        affected_rows
      }
  }
`;




export class EditMasterMember extends SaveableQuery {
  friendly_name = "Unit"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return [
      "unit_id", "master_id"
    ];
  }
  data_impl({ data: { master_member: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_master_member_mutation;
  }
  get delete_gql_impl() {
    return delete_master_member_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}



export const upsert_increase_mutation = gql`
  mutation upsert_increase($changeMap: core_increase_insert_input!, $changeCols: [core_increase_update_column!]!) {
    increase:insert_core_increase (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_increase_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        agreement_id
        id
        ...IncreaseFields
      }
    }
  }
  ${increase_fields}
`;

export const delete_increase_mutation = gql`
  mutation delete_increase($deleteId: Int!) {
    delete_core_increase (
      where: { id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;

export class EditIncrease extends SaveableQuery {
  friendly_name = "Increase Row"
  refetch_queries = refetch_searches

  get required_keys_impl() {
    return [
      "id", "agreement_id", "increase_type", "members"
    ];
  }

  data_impl({ data: { increase: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_increase_mutation;
  }
  get delete_gql_impl() {
    return delete_increase_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}

export const insert_master_mutation = gql`
mutation insert_target(
  $id: Int!,
  $period_id: Int!,
  $name: String!,
  $members: core_master_member_arr_rel_insert_input!
  ) {
  insert_core_master ( objects: [
    {
      id: $id,
      period_id: $period_id,
      name: $name,
      members: $members
    }
   ]) {
    affected_rows
      returning {
      id
      ...MasterAgreementFields
    }
  }
}
${master_agreement_fields}
`;


export const upsert_master_mutation = gql`
  mutation upsert_master($changeMap: core_master_insert_input!, $changeCols: [core_master_update_column!]!) {
    master:insert_core_master (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_master_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        id
        ...MasterAgreementFields
      }
    }
  }
  ${master_agreement_fields}
`;

export const delete_master_mutation = gql`
  mutation delete_master($deleteId: Int!) {
    delete_core_master (
      where: {id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;

export const delete_master_increases_mutation = gql`
  mutation delete_master_increases($targetId: Int!) {
    delete_core_increase (
      where: {agreement_id: {_eq: $targetId} }
    ) {
        affected_rows
      }
  }
`;


export class EditMaster extends SaveableQuery {
  friendly_name = "Data"
  refetch_queries = ["master_search"]

  get required_keys_impl() {
    return ["id", "name"];
  }
  data_impl({ data: { master: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_master_mutation;
  }
  get delete_gql_impl() {
    return delete_master_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}



export const upsert_agreement_mutation = gql`
  mutation upsert_agreement_info($changeMap: core_agreement_info_insert_input!, $changeCols: [core_agreement_info_update_column!]!) {
    agreement:insert_core_agreement_info (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: core_agreement_info_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...AgreementInfoFields
      }
    }
  }
  ${agreement_info_fields}
`;

export const delete_agreement_mutation = gql`
  mutation delete_agreement($deleteId: Int!) {
    delete_core_agreement_info (
      where: {agreement_id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;

export const delete_agreement_increases_mutation = gql`
  mutation delete_agreement_increases($targetId: Int!) {
    delete_core_increase (
      where: {agreement_id: {_eq: $targetId} }
    ) {
        affected_rows
      }
  }
`;


export class EditAgreement extends SaveableQuery {
  friendly_name = "Agreement"
  refetch_queries = ["agreement_search"]

  get required_keys_impl() {
    return ["agreement_id"];
  }
  data_impl({ data: { agreement: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_agreement_mutation;
  }
  get delete_gql_impl() {
    return delete_agreement_mutation;
  }
  /*
  get save_queries_impl() {
    return [(new PersonNotes(null, null))];
  }*/
}



export const upsert_file_upload_mutation = gql`
  mutation upsert_file_upload($changeMap: file_upload_insert_input!, $changeCols: [file_upload_update_column!]!) {
    file_upload:insert_file_upload (
      objects: [ $changeMap ],
      on_conflict: {
        constraint: file_upload_pkey,
        update_columns: $changeCols
      }
    ) {
      returning {
        ...FileUploadFields
      }
    }
  }
  ${file_upload_fields}
`;

export const delete_file_upload_mutation = gql`
  mutation delete_file_upload($deleteId: Int!) {
    delete_file_upload (
      where: {file_upload_id: {_eq: $deleteId} }
    ) {
        affected_rows
      }
  }
`;


export class EditFileUpload extends SaveableQuery {
  friendly_name = "FileUpload"
  refetch_queries = ["upload_search"]

  get required_keys_impl() {
    return [];
  }
  data_impl({ data: { file_upload: { returning: [d] } } }) {
    return d;
  }

  get mutate_gql_impl() {
    return upsert_file_upload_mutation;
  }
  get delete_gql_impl() {
    return delete_file_upload_mutation;
  }
}

