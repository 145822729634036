import { LitElement, html, css } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { EditPeriod, EditFileUpload, SearchFileUploads } from '../queries/queries.js';
import { MDuesResultList, MDuesListItem, NewItemDialog } from '../components/result_list.js';
import {dayjs} from '../shared-components/utilities/dates.js';


const period_item_styles = css`
  :host {
    --item-primary-color: var(--period-color);
    width: fit-content;
  }
  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }
  div.file {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    background-color: var(--paper-blue-700);
    color: white;
    width: max(40vw, 400px);
    padding: 8px 16px;
    cursor: pointer;
  }
  div.file[locked] {
    cursor: not-allowed;
  }

  div.fileinfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
  }
  div.fileinfo > * {
    font-weight: 100;
    font-size: 80%%;
  }

  div.fileinfo > *:first-child {
    font-weight: bold;
    font-size: 100%;
  }


`;
export class FileUploadItem extends LitElement {
  static styles = [ period_item_styles];
  static properties = { period: { type: Object }, item: { type: Object }, locked: {type: Boolean}}

  render() {
    const {filename, timestamp, hash, status } = this.item;
    let d = dayjs(timestamp);
    //console.log("DATE:", timestamp, d);
    return html`
      <div class="file" ?locked=${this.locked} @click=${e => this.openItem()}>
        <mwc-icon>${this.locked ? 'lock' : 'summarize'}</mwc-icon>
        <div class="fileinfo">
          <span class="dataitem filename">${filename}</span>
          <span class="dataitem rows">${status?.rows} rows</span>
          <span class="dataitem timestamp">uploaded ${dayjs(timestamp).calendar().toLowerCase()}</span>
        </div>
      </div>
    `
  }
  openItem() {
    if (this.locked) return;
    this.dispatchEvent(new CustomEvent('open-upload', { detail: this.item, bubbles: true, composed: true}));
  }

}

window.customElements.define('upload-item', FileUploadItem);




const period_list_styles = css`
  .column > * {
    width: fit-content;
  }

`;

export class FileUploadList extends MDuesResultList {
  static styles = [...super.styles, period_list_styles];
  static search_class = SearchFileUploads;
  static properties = { ...super.properties, locked: {type: Boolean}}

  fab_title = "New Upload"
  new_item_title = "New Upload"

  searchArgs() {
    //console.warn("SEARCH ARGS FOR FULES", this.mdues_period);
        return { ...this.search, period: this.mdues_period, order: this.formatSort() };
  }

  renderItem(upload) {
    //console.log("rendering", upload, this.mdues_period);
    return html`<upload-item .locked=${this.locked} @update-list=${() => this.refresh()} .editing=${upload.tempname !== undefined} .item=${upload}></upload-item>`;
  }
  
  add_item_saved(item) {
      this.results = [item, ...this.results];
      this.requestUpdate("results");
  }  
}

window.customElements.define('upload-list', FileUploadList);

const periods_page_styles = css`
      :host { 
        background-color: white;
        --top-bar-color: var(--period-color);
      }
      .column {
        padding-top: 20px;
      }
        
`;

class UploadsPage extends MDuesPage {
  static styles = [super.styles, periods_page_styles]
  static icon = "folder"
  static default_title = "Uploads"

  renderPage() {
    return html`
      <div class="column">
        <upload-list class="refresh-list" ?locked=${this.mdues_period?.locked} .search=${this.search} @detail=${({ detail }) => this.detail = detail} .mdues_period=${this.mdp ? this.mdp.id : null}></upload-list>
      </div>
        
    `;   
  }
 
  static get properties() {
    return {
      ...(super.properties),
      search: { type: Object },
      detail: { type: Object },
      new_dialog_open: { type: Boolean }
    };
  }
  
  renderExtraItems() {
    const { periods, mdp } = this;
    
    return html`
      <mwc-button ?disabled=${this.mdues_period?.locked} slot="actionItems" icon="add"  @click=${e => this.newUpload()}>New Upload&nbsp&nbsp</mwc-button>
      ${super.renderExtraItems()}
      `
  }  

  newUpload() {
    this.dispatchEvent(new CustomEvent('new-upload', { detail: null, bubbles: true, composed: true}));
  }
}

window.customElements.define('upload-page', UploadsPage);
export { UploadsPage }

const new_period_style = css`
  mwc-dialog {
    --mdc-dialog-scrim-color: rgba(0, 0, 0, 0.6);
    --mdc-dialog-min-width: 500px;
  }      
 
  kale-textfield {
     --kale-textfield-width: 500px;
  }
`;
