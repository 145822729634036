import { html, css, LitElement } from 'lit';
import '../shared-components/login.js';
import '../shared-components/router.js';
import '../shared-components/snackbar.js';
const build = 0;
import { colors, shadows } from '../shared-components/styles.js';
import { CognitoAuthMgr } from '../shared-components/utilities/authmgr.js';
import { timeline_colors, timeline_classes } from "../components/colors.js";

import { client } from '../queries/client.js';
// Pages:
import { PeriodsPage } from '../pages/periods.js';
import { UnitsPage } from '../pages/units.js';
import { MasterAgreementsPage } from '../pages/master_agreements.js';
import { IncreasesPage } from '../pages/increases.js';
import { ImportPage } from '../pages/import.js';

import { FormExportPage } from '../pages/form-export.js';

import { ReportsPage } from '../pages/reports.js';
import { UploadsPage } from '../pages/uploads.js';

console.log("MINDUES BUILD", build);
window.localStorage.setItem('mindues_build_number', build);
window.localStorage.setItem('mindues_version', build);

const routes = [
  { component: UnitsPage, path: "^/units$", nav: "/units", name: "Units" },
  { component: IncreasesPage, path: "^/$", name: "Units" },
  { component: PeriodsPage, path: "^/periods$", nav: "/periods", name: "Periods" },
  { component: MasterAgreementsPage, path: "^/agreements$", nav: "/agreements", name: "Agreements" },
  { component: IncreasesPage, path: "^/increases$", nav: "/increases", name: "Increases" },
  { component: UploadsPage, path: "^/uploads[/]?$", nav: "/uploads", name: "Previous Uploads" },
  { component: ImportPage, path: "^/files[/]?$", nav: "/files", name: "Import", events: ['open-upload', 'new-upload'] },

  { section: "Reports", path: "^/report/.*", icon: "area_chart", subroutes: [
      { component: ReportsPage, path: "^/reports?/affiliates?[/]?$", icon: 'done_outline', nav: "/report/affiliates", parent: "/reports", key: 'affiliate', name: "Affiliate Progress" },
      { component: ReportsPage, path: "^/reports?/masters[/]?$", icon: 'group_work', nav: "/report/masters", parent: "/reports", key: 'masters', name: "Master Agreements" },
      { component: ReportsPage, path: "^/reports?/detail[/]?$", icon: 'zoom_in', nav: "/report/detail", parent: "/reports", key: 'detail', name: "Full Detail" },
    ]
  },
  { component: FormExportPage, path: "^/exports$", icon: "table_chart", nav: "/exports", name: "exports" },
  /*
  { component: ApprovalsPage, path: "^/approvals$", nav: "/approvals", name: "Approvals" },
  { component: AppSettingsPage, path: "^/settings[/]?$", nav: "/settings", name: "Settings" },
  */

  /*
  // second level 
  { component: PersonPage, path: "^/people/[view|edit].*", parent: '/'},
  { component: ViewReport, path: "^/reports/view.*", parent: '/reports', name: "Reports" },
  { component: ViewReport, path: "^/reports/(.+)[/]?", parent: '/reports', name: "Reports" },

  // not in nav menu
  { component: PeoplePage, path: "^/people[/]?$", name: "Search", icon: 'search' },
  { component: UserPrefsPage, path: "^/prefs[/]?$" },
  { component: FastProcessPage, path: "^/process(fast)*[/]?$", name: "Process (Fast)" },
  */
];

const authmgr = new CognitoAuthMgr({
  login: () => client.clearStore(),
  logout: () => client.clearStore(),
  //pool: { UserPoolId: 'us-east-1_8eABgmznc', ClientId: '70jm43tqfje6ii2ifulhh3ulb7' }
  pool: { UserPoolId: 'us-east-1_3asOdJiHU', ClientId: '301u002a6b0uk2cpe5ebvijrnk' }

});
window.authmgr = authmgr;

const mindues_app_style = css`
        :host {
          overflow: visible;
          font-family: 'Roboto', 'Noto', sans-serif;
          display: block;
          position: relative;
          --top-bar-color: var(--paper-green-700);
          ${colors}
          ${shadows}
          ${timeline_colors}
        }
        ${timeline_classes}

        kale-router {
          z-index: -1;
        }
        kale-router[authorized] {
          z-index: 10;
        }
        kale-router[suppress] {
          display: none;
        }
        login-box {
          z-index: 100;
        }
        login-box[authorized]{
          display: none;
        }
        #buildnumber {
          position: fixed;
          bottom: 0px;
          left: 0px;
          font-size: 10px;
          color: black;
          z-index: 1000;
          opacity: 0.5;
          font-weight: 100;
        }

        .test-overlay {
            z-index: 1000;
            position: fixed;
            top: -50vh;
            left: -50vw;
            width: 200vw;
            height: 200vh;
            opacity: 0.10;
            pointer-events: none;
        }
`;

class MinimumDuesApp extends LitElement {
  static styles = mindues_app_style
  constructor() {
    super();
    this.login = null;
    const host = window.location.host.split(':')[0].split('.')[0].toLowerCase();
    const dev_strings = {
      'mindues-test': `test build ${Number(build)}`,
      'localhost': `local dev build`
    }
    this.dev_string = dev_strings[host];
    console.log(host, "", this.dev_string);
    this.app_name = this.dev_string ? `Minimum Dues [${this.dev_string.toUpperCase()}]` : 'MinimumDues';
  }

  async firstUpdated() {
  }
  render() {
    return html`
      ${this.login_state && this.login_state === 'LOGGED IN' ? html`
      <kale-router
        .routes=${routes}
        .app=${{ name: "Minimum Dues System", version: build }}
        @pagetitle=${e => { document.title = e.detail.title ? `${this.app_name} | ${e.detail.title}` : this.app_name }}
        @signout=${e => this.handleSignout()}
        @snackbar=${e => this.showSnack(e.detail)}
        .login=${this.login}
      ></kale-router> ` : html``}
      <login-box id="login" header="Minimum Dues System" @snackbar=${e => this.showSnack(e.detail)} ?authorized=${this.login_state === 'LOGGED IN'} @login=${e => { this.login_state = e.detail.state; this.login = e.detail.login }}></login-box>
      <kale-snackbar id="snacks"></kale-snackbar>

      <div id="buildnumber">#${build}</div>
      ${
      // translucent warning overlay for test builds
      this.dev_string ? html`
          <div class="test-overlay" style=${`background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='300'> <g transform='translate(150, 150) rotate(-35)'><text text-anchor='middle' x='0' y='0' style='font-size: 25px; font-family: sans-serif; fill: red; text-transform: uppercase; font-weight: bold; font-stretch: condensed;'>${this.dev_string}</text></g> </svg>"); `}></div>
          <div class="test-overlay" style=${`background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='300'> <g transform='translate(150, 150) rotate(-35)'><text text-anchor='middle' x='0' y='0' style='font-size: 20px; font-family: sans-serif; fill: red; text-transform: uppercase; font-weight: bold; font-stretch: condensed;'>${this.dev_string}</text></g> </svg>"); transform: translate(100px, 100px);`}></div>
          <div class="test-overlay" style=${`background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='300' height='300'> <g transform='translate(150, 150) rotate(-35)'><text text-anchor='middle' x='0' y='0' style='font-size: 15px; font-family: sans-serif; fill: red; text-transform: uppercase; font-weight: bold;font-stretch: condensed;'>${this.dev_string}</text></g> </svg>"); transform: translate(-100px, -100px); `}></div>
        `: ''
      }

    `;
    /*
    
    <svg 
    width='100' height='100' style='background-color: lightblue'> <g transform='translate(25, 75) rotate(-45)'><text x='0' y='0' style=' fill: black; text-transform: uppercase; font-weight: 100; '>test</text></g>
</svg>


    <svg width="100" height="100" style="background-color: lightblue">
                <g transform="translate(25, 75) rotate(-45)"><text x="0" y="0" style="
        fill: black;
        text-transform: uppercase;
        font-weight: 100;
    ">test</text></g>
              </svg>
    
    
    */

  }
  handleSignout() {
    window.authmgr.logout();
  }

  showSnack(s) {
    if (!this.snacks) {
      this.snacks = this.renderRoot.getElementById('snacks')
    }
    if (this.snacks) {
      this.snacks.queueSnack(s);
    }
  }
  static get properties() {
    return {
      login_state: { type: String },
      login: { type: Object }
    };
  }
}

window.customElements.define('mindues-app', MinimumDuesApp);
