import { LitElement, html, css } from 'lit';
import { MDuesPage } from '../components/mdues_page.js';
import '@material/mwc-icon';
import '@material/mwc-list';
import '@material/mwc-fab';
import '@material/mwc-button';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import gql from 'graphql-tag';
import { client, formatQueryError } from '../queries/client.js';
import { wait } from '../shared-components/utilities/anim.js';
import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { SearchUnits, EditUnit, EditUnitAlias } from '../queries/queries.js';
import { MDuesResultList, formatAffiliate, MDuesListItem, NewItemDialog, MduesSearchBox } from '../components/result_list.js';



const unit_item_styles = css`
  :host {
    --item-primary-color: var(--unit-color);
  }

  :host([deprecated]) {
    --item-primary-color: var(--unit-deprecated-color);
  }
  .alias-chip {
    --chip-background: var(--alias-color);
    --chip-color: white;
  }
  .item-chip {
    --chip-background: var(--unit-secondary-color);
    --kale-chip-border: 1px dotted var(--unit-color);
    --chip-color: var(--unit-color);
  }

  .deprecated_unit {
    background-color: var(--paper-red-700);
    color: white;
  }

`;

export class UnitItem extends MDuesListItem {
  static styles = [...super.styles, unit_item_styles];
  static mutation_class = EditUnit
  
  set item(i) {
    super.item = i;
    if (i.deprecated) {
      this.setAttribute("deprecated", "");
    } else {
      this.removeAttribute("deprecated")
    }
  }
  get item() { return super.item }


  renderAliases(aliases) {
    if (!aliases || aliases.length === 0) {
      return html`<div class="no-items">no aliases</div>`
    }
    return html`
    <div class="chip-list">
      ${aliases.map(({ id, name }) => html`
      <mdues-chip class="alias-chip" @delete-item=${() => this.deleteAlias(id)} label=${name} ?deletable=${this.editing}></mdues-chip>
      `)}
    </div>
    `;
  }
  renderItemChipDesc(item) { return `${item.name} (${formatAffiliate(item)})` }
  renderHeaderName({ name = '' } = {}) { return name }
  renderHeaderDetail(unit) {
    //console.log("UNIT = ", unit);
    if (!unit) return '';
    return html`${unit.deprecated ? html`<div class="unit deprecated_unit">deleted</div>`: ''}<div class="unit">${unit.state ? `${unit.state} ` : ''}${formatAffiliate(unit)}</div> <div class="unit">stat = ${unit?.stat?.find(s => s.period_id === window?.mdues_period?.id)?.stat?.toLocaleString()}</div>`;
  }
  renderItemBody({ aliases = [] } = {}) { return html`<div class="aliases">${this.renderAliases(aliases)}</div>` }

  deleteAlias(id) {
    console.log("deleting", id);

    const edit_alias = new EditUnitAlias(
      a => { console.log("DELETE UPDATE", a) }, // update
      { changeMap: null }, // initial
      a => { console.log("FINAL DELETE", a); }, // final
      (e, msgs) => { console.error(e) } //errors
    );

    edit_alias.delete({ id: id });
    this.item = { ...this.item, aliases: this.item.aliases.filter(a => a.id !== id) }
  }
  enterNewAlias(field) {
    //const field = this.renderRoot.getElementById('alias_entry');
    //console.log("ENTER ALIAS",  field.value, v);

    const edit_alias = new EditUnitAlias(
      a => { console.log("UPDATE WITH ALIAS", a) }, // update
      { changeMap: null }, // initial
      a => { console.log("FINAL ALIAS", a); this.item = { ...this.item, aliases: [...this.item.aliases, a] }; field.value = ''; field.blur() }, // final
      (e, msgs) => { console.error(e) } //errors
    );
    edit_alias.save({ unit_id: this.item.id, name: field.value }, {});
  }

  renderEditForm(item) {
    return html`
      <div>
        <kale-textfield id="name" label="Unit Name" field="name" .value=${item.name}></kale-textfield>
      </div>
      <div>
        <kale-textfield id="state" label="State" field="state" .value=${item.state}></kale-textfield>
        <kale-textfield id="council" label="Council" field="council" .value=${item.council}></kale-textfield>
      </div>
      <div>
        <kale-textfield id="local" label="Local" field="local" .value=${item.local}></kale-textfield>
        <kale-textfield id="subunit" label="Subunit" field="subunit" .value=${item.subunit}></kale-textfield>
      </div>
      <kale-toggle id="deprecated" label="Deleted" field="deprecated" .value=${item.deprecated ?? false}></kale-toggle>
      <div class="divider"></div>
      <h4>Aliases:</h4>
      ${this.renderItemBody(item)}
      <mwc-textfield id="alias_entry" label="Enter New Alias" outlined @keyup=${({ target, key }) => { if (key === 'Enter') this.enterNewAlias(target) }}></mwc-textfield>
    `;
  }

}/*
      <div class="bottom-buttons">
        <mwc-button @click=${e => this.editing = true}>add</mwc-button>
      </div>
      */

window.customElements.define('unit-item', UnitItem);



const unit_list_styles = css`

`;

export class UnitList extends MDuesResultList {
  static styles = [...super.styles, unit_list_styles];
  static search_class = SearchUnits;
  static properties = { 
    ...super.properties,
    fab_title: { type: String },
    new_item_title: {type: String},
    mdues_period_required: {type: Boolean},
    active_sorts: {type: Array}
  }
  constructor() {
    super();
    this.fab_title = "New Unit"
    this.new_item_title = "New Unit"
    this.mdues_period_required = false
    this.active_sorts = [ {name: 'name', direction: 'asc'}, {name: 'council', direction: 'asc' }, {name: 'local', direction: 'asc' }, {name: 'updated', direction: 'desc' } ];
  }
  
  searchArgs() {
        //return { ...this.search, period: this.mdues_period, order: this.formatSort() };

        let args = { ...this.search, period: this.mdues_period, order: this.formatSort() };
        //console.warn(this.constructor.name, "SEARCHING WITH", JSON.stringify(args));
        return args;
  }




  static valid_sorts = [
        {field: 'name', display: 'Name', width: 25},
        {field: 'council', display: 'Council', width: 7},
        {field: 'local', display: 'Local', width: 56},
        {field: 'updated', display: 'Date', width: 12}
  ]
  renderItem(item, is_new) {
    return html`<unit-item @update-list=${() => this.refresh()} .editing=${item.tempname !== undefined} .item=${item}></unit-item>`;
  }
  
  renderSelectionItem(item) {
    return html`<unit-item @update-list=${() => this.refresh()} selectable .item=${item}></unit-item>`;
  }
  
  add_item_saved(item) {
      this.results = [item, ...this.results];
      this.requestUpdate("results");
  }  
}



window.customElements.define('unit-list', UnitList);

const units_page_styles = css`
  :host { 
    background-color: white;
    --top-bar-color: var(--unit-color);
  }

  #units {
    height: 100%;
    max-width: max( 50vw, 800px);
    box-shadow: var(--shadow-elevation-3dp_-_box-shadow);
  }

`;


export class UnitSearchBox extends MduesSearchBox {
  renderRowOptions() {
    return html`
      <mwc-formfield label="show deleted" class="option">
        <mwc-switch @input=${e => this.search = { ...this.search, include_deprecated: e.path[0].checked || undefined }}></mwc-switch>
      </mwc-formfield>
    `
  }
}

//<mwc-textfield @input=${e => this.search = {...this.search, filter: e.target.value}} style="flex: 1 1" outlined label="filter" icon="search"></mwc-textfield>
window.customElements.define('unit-search', UnitSearchBox);


class UnitsPage extends MDuesPage {
  static styles = [super.styles, units_page_styles]
  static icon = "people_outline"
  static default_title = "Units"
  static properties = { ...super.properties, detail: {type: Object }, new_dialog_open: { type: Boolean }, new_dialog_error: { type: Boolean }}

  get period_dependent() { return false; }
  renderPage() {
    return html`
      <div class="column">
        <unit-search @search=${({ detail }) => this.search = detail}></unit-search>
        <unit-list class="refresh-list" .mdues_period=${this.mdp ? this.mdp.id : null} .search=${this.search} @detail=${({ detail }) => this.detail = detail} .table_view=${this.table_view}  ></unit-list>
      </div>
        
      ${this.new_dialog_open? html`
          <newunit-dialog @item-saved=${e=>this.handle_item_saved(e.detail)}
          @newunit-error=${e=>{this.new_dialog_error=true}}
          @newunitclosed=${e=>{{this.new_dialog_open=false; 
              console.log("UnitPage ---", e, this)}}}>
              </newunit-dialog>` : ''}
        `;        
  }

  addNewUnit() {
      if (!this.new_dialog_open)
        this.new_dialog_open = true;
  }
  
  handle_item_saved(item) {
     let list = this.renderRoot.querySelector('unit-list');
     list?.add_item_saved(item);
  }  
  
  renderExtraItems() {
    const { periods, mdp } = this;
    
    return html`
      <mwc-button slot="actionItems" icon="add"  @click=${e => this.addNewUnit()}>New Unit&nbsp&nbsp</mwc-button>
      ${super.renderExtraItems()}
      `
  }
}

window.customElements.define('units-page', UnitsPage);
export { UnitsPage }

const new_unit_style = css`
  mwc-dialog {
    --mdc-dialog-scrim-color: rgba(0, 0, 0, 0.6);
    --mdc-dialog-min-width: 500px;
  }      
 
  kale-textfield {
     --kale-textfield-width: 500px;
  }
  
  .error-msg {
        color: red;
        margin: auto;
        width: 50%;
  }
`;

class NewUnitDialog extends NewItemDialog {
  static styles = [new_unit_style]
  static properties = { ...super.properties, table_view: {type: Boolean}, item: { type: Object }, editing: { type: Boolean }, selectable: { type: Boolean }, expanded: { type: Boolean }, canSaveUnit: { type: Boolean }}
  static mutation_class = EditUnit;
  
  new_state = ""; new_council = ""; new_local = "";
  
  static get properties() {
    return {
      ...(super.properties),
      opened: {type: Boolean }
    };
  }
  
  dataUpdate(new_data) {
    this.item = new_data;
  }
  
  finalUpdate(new_data) {
    this.dispatchEvent(new CustomEvent('item-saved', { bubbles: true, composed: true, detail: new_data }));
  }
  
  updateList() {
    this.dispatchEvent(new CustomEvent('update-list', { bubbles: true, composed: true, detail: this.item }))
  }
  
  get mutation_instance() {
    if (!this.constructor.mutation_class) 
        return null;
    
    if (!this._mutation) 
        this._mutation = new (this.constructor.mutation_class)(
            (item) => this.dataUpdate(item),
            { changeMap: null },
            (item) => this.finalUpdate(item),
            (err) => console.error(err) //FIXME: more error checking/alerts
       );
    
    return this._mutation;
  }
  
  save_impl(data) {
    const mut = this.mutation_instance;
    mut.save(data);  
    this.saveComplete(Object.keys(data));
  }  
  
  async validate() {
      let q = "";
      
      if (this.new_local.length == 0) {
            q = gql`
                query q($state: String, $council: String, $local: String){
                    unit_ret_list:temp_import_simple_stat(
                    where: { 
                        _and: [ 
                        {state: {_eq: $state}},  {council: {_eq: $council}}
                        ]}
                    ) {
                    council
                    local
                    membership
                    name
                    stat
                    state
                    }
                }
            `;                
      }
      else if (this.new_council.length == 0) {
            q = gql`
                query q($state: String, $council: String, $local: String){
                    unit_ret_list:temp_import_simple_stat(
                    where: { 
                        _and: [ 
                        {state: {_eq: $state}}, {local: {_eq: $local}}
                        ]}
                    ) {
                    council
                    local
                    membership
                    name
                    stat
                    state
                    }
                }
            `;
    }
    else {
             q = gql`
                query q($state: String, $council: String, $local: String){
                    unit_ret_list:temp_import_simple_stat(
                    where: { 
                        _and: [ 
                        {state: {_eq: $state}}, {council: {_eq: $council}}, {local: {_eq: $local}}
                        ]}
                    ) {
                    council
                    local
                    membership
                    name
                    stat
                    state
                    }
                }
            `;       
    }
    
    client.query({
      fetchPolicy: 'network-only',
      query: q,
      variables: {state: this.new_state.toUpperCase(), council: this.new_council, local: this.new_local}
    }
    ).then(qdata => {
        // console.log("-------- qdata length ********", qdata.data.unit_ret_list.length);
        if (qdata.data.unit_ret_list.length == 0) {
            this.canSaveUnit = false;
        } else {
            this.canSaveUnit = true;
        }
            
    } 
    ).catch(error => console.error(error));   

    await wait(500);
    //console.log("-------- this.canSaveUnit ********", this.canSaveUnit);
  }  
  
  /*
  validate(data) {
   const q = gql`
        query q($state: String, $council: String, $local: String){
            unit_ret_list:temp_import_simple_stat(
            where: { 
                _and: [ 
                {state: {_eq: $state}}, 
                {council: {_eq: $council}}, 
                {local: {_eq: $local}}
                ]}
            ) {
            council
            local
            membership
            name
            stat
            state
            }
        }
    `;
    
    client.query({
      fetchPolicy: 'network-only',
      query: q,
      variables: {state: data.state.toUpperCase(), council: data.council, local: data.local}
    }
    ).then(qdata => {
        console.log("-------- qdata length ********", qdata.data.unit_ret_list.length);
        if (qdata.data.unit_ret_list.length == 0) {
            // this.dispatchEvent(new CustomEvent('newunit-error', { bubbles: true, composed: true, detail: this.item }))
            this.canSaveUnit = false;
        }
        else 
            this.canSaveUnit = true;
            
    } 
    ).catch(error => console.error(error));   
    
    //save();  
  }  */
  
  render()  {
    return  html` 
      <mwc-dialog id="newUnitDialog" heading="New Unit" open .title="New Unit"  
      @closed=${e=>{console.log("NewUnitDialog closed === ", e, this); this.dispatchEvent(new CustomEvent('newunitclosed', { bubbles: true, composed: true, detail: null })) }} 
      >
        <div>
        <kale-textfield id="unitName" label="Unit Name" field="name" .value="New Unit Name" required></kale-textfield>
        <kale-textfield id="unitState" label="State" field="state" .value="State" 
            @input="${e1 => {
            const path = e1.composedPath();
            const input = path[0];
            this.new_state = input.value;
            
            this.validate();
            }}"
        ></kale-textfield>
        
        <kale-textfield id="unitCouncil" label="Council" field="council" .value="Council" 
            @input="${e => {
            const path = e.composedPath();
            const input = path[0];
            this.new_council = input.value;
            
            this.validate();
            }}"
        ></kale-textfield>
        
        <kale-textfield id="unitLocal" label="Local" field="local" .value="Local" 
            @input="${e => {
            const path = e.composedPath();
            const input = path[0];
            this.new_local = input.value;

            this.validate();
            }}"   
        ></kale-textfield>
        
        <kale-textfield id="unitSubunit" label="Subunit" field="subunit" .value="Subunit"></kale-textfield>
        </div>
        
        <!-- <div class="error-msg">${this.new_dialog_error ? html`State / Council / Local not exisit` : ''}</div> -->
      
        <mwc-button
            id="primary-action-button"
            slot="primaryAction"
            @click=${e => this.save()}
            ?disabled=${!this.canSaveUnit}
            dialogAction="unitsaved">
            Save
        </mwc-button>
      
        <mwc-button
            slot="secondaryAction"
            dialogAction="close">
            Cancel
        </mwc-button>
      </mwc-dialog>`
      ;
  }

}

window.customElements.define('newunit-dialog', NewUnitDialog);
export { NewUnitDialog }


