import { LitElement, html } from 'lit';
import gql from 'graphql-tag';
export const getSort = (col, rev) => {
  let path = col.split('.');
  return path.length === 1 ? `{${col}: ${rev ? 'desc_nulls_last' : 'asc_nulls_first'}}` : `{${path[0]}: ${getSort(path.slice(1).join('.'), rev)}}`
};
//{report:{group_code: asc}}, {last_name: asc}
export const makeOrderBy = (lookup, sort) => {
  if (!sort || sort.length === 0) return '';
  return `order_by: [${sort.filter(s => lookup[s.col] !== null).map(s => getSort(lookup[s.col] ? lookup[s.col] : s.col, s.reverse)).join(', ')}]`
};
;
export const filterValue = (val) => {
  if (typeof (val) === 'text' || typeof (val) === 'string') {
    return `"${val}"`;
  }
  if (val.getYear) {
    return `"${val.toLocaleDateString()}"`
  }
  return val

}
export const makeFilter = (filter, lookup) => {
  filter = filter.map(f => ({ ...f, col: lookup && lookup[f.col] ? lookup[f.col] : f.col }))
  //{ benefit_start_match: { _eq: false } }
  //filter: [{ col: 'benefit_start_match', op: '_eq', val: false }],
  let ret = `{_and: [${filter.map(f => `{${f.col}: {${f.op}: ${filterValue(f.val)}}}`).join(', ')}]}`;
  return ret;
}
export const makeCond = (sort, limit, offset, lookup) => {
  let ret = `
      ${makeOrderBy(lookup, sort)}
      ${limit > 0 ? `limit: ${limit}` : ''}
      ${offset > 0 ? `offset: ${offset}` : ''}
  `;
  return ret;
}
export const declared_reports = [];
export const declared_reports_ = [
  {
    name: 'Begin Deduction Report',
    desc: "Currently employed and reaching required contribution date.",
    subname: args => `(${args.year})`,
    icon: 'alarm',
    args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() + 1 : d.getFullYear() })(new Date()) },
    limit: 2000,
    sort: [{ col: 'group', reverse: false }, { col: 'name', reverse: false }, { col: 'person.id', reverse: false }],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
        'name': 'person.last_name',
        'group': 'group',
        'employer code': 'employer',
        'employer name': 'employer',
        'hired': 'basis',
        'contributor': 'contributing',
      };
      return gql`
      query begin_deduction {
        report: report_contribution_beginning (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {contributing: {_gte: "${new Date(args.year, 0, 1).toLocaleDateString()}"}},
            {contributing: {_lte: "${new Date(args.year + 1, 0, 1).toLocaleDateString()}"}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          person {
            id
            first_name
            last_name
            middle_name
            last_4 { ssn }
            person_gender_code
            birth_date
          }
          employer_detail {
            name
            code
          }
          group
          basis
          contributing
        }
      }
      `;
    },
    columns: [
      { c: 'group', t: 'ltext' },
      { c: 'employer code', t: 'ltext' },
      { c: 'employer name', t: 'ltext' },
      { c: 'name', t: 'person_link' },
      { c: 'hired', t: 'date' },
      { c: 'contributor', t: 'date' }
    ],
    download_columns: [
      { c: 'group', t: 'ltext' },
      { c: 'employer code', t: 'ltext' },
      { c: 'employer name', t: 'ltext' },
      { c: 'person_id', t: 'person_link' },
      { c: 'last_name', t: 'ltext' },
      { c: 'first_name', t: 'ltext' },
      { c: 'middle_name', t: 'ltext' },
      { c: 'hired', t: 'date' },
      { c: 'contributor', t: 'date' }
    ],
    row_func: d => ({
      id: d.person.id,
      person_id: d.person.id,
      name: ['first_name', 'middle_name', 'last_name'].map(n => d.person[n]).filter(n => n).join(' '),
      //name: d.person,
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      'group': d.group,
      'employer code': d.employer_detail ? d.employer_detail.code : null,
      'employer name': d.employer_detail ? d.employer_detail.name : null,
      'hired': (d.basis),
      'contributor': (d.contributing),
    })
  },

  {
    name: 'Age 65 Report',
    desc: "Reaching age 65 this year (and has employments, and not deceased).",
    subname: args => `(${args.year})`,
    icon: 'alarm',
    args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() + 1 : d.getFullYear() })(new Date()) },
    limit: 2000,
    sort: [{ col: 'dob', reverse: false }, { col: 'name', reverse: false }, { col: 'id', reverse: false }],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
        'name': 'last_name',
        '65': 'birth_date',
        'dob': 'birth_date',
        'ssn': 'last_4.ssn',
      };
      //{_not: {deceased_date: {}}},
      return gql`
      query age65 {
        report: person (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {birth_date: {_lte: "${new Date(args.year - 64, 0, 1).toLocaleDateString()}"}},
            {birth_date: {_gte: "${new Date(args.year - 65, 0, 0).toLocaleDateString()}"}},
            {employments: {}},
            {deceased_date: {_is_null: true}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          id
          first_name
          last_name
          middle_name
          last_4 { ssn }
          person_gender_code
          birth_date
          deceased_date
          emails {
            email
          }
          phones {
            number
            phone_type_code
          }
          preferred_address {
            address {
              line1
              line2
              line3
              city
              address_state_code
              zip
            }
          }
          current_state {
              group
              employer
              status
          }
          employments {
              start_date
              end_date
              employer_code
          }
        }
      }
      `;
    },
    columns: [
      { c: 'name', t: 'person_link' },
      { c: 'computed group', t: 'ltext' },
      { c: 'last employer', t: 'ltext' },
      { c: 'ssn', t: 'ssn' },
      { c: 'dob', t: 'date' },
      { c: '65', t: 'date' },
      { c: 'emails', t: 'ltext' },
      { c: 'phones', t: 'ltext' },
      { c: 'line1', t: 'ltext' },
      { c: 'line2', t: 'ltext' },
      { c: 'line3', t: 'ltext' },
      { c: 'city', t: 'ltext' },
      { c: 'state', t: 'ltext' },
      { c: 'zip', t: 'ltext' },
    ],
    download_columns: [
      //{ c: 'id', t: 'ctext' },
      { c: 'person_id', t: 'person_link' },
      { c: 'last_name', t: 'ltext' },
      { c: 'first_name', t: 'ltext' },
      { c: 'middle_name', t: 'ltext' },
      { c: 'computed group', t: 'ltext' },
      { c: 'last employer', t: 'ltext' },
      { c: 'ssn', t: 'ssn' },
      { c: 'dob', t: 'date' },
      { c: '65', t: 'date' },
      { c: 'emails', t: 'ltext' },
      { c: 'phones', t: 'ltext' },
      { c: 'line1', t: 'ltext' },
      { c: 'line2', t: 'ltext' },
      { c: 'line3', t: 'ltext' },
      { c: 'city', t: 'ltext' },
      { c: 'state', t: 'ltext' },
      { c: 'zip', t: 'ltext' },
    ],
    row_func: (d, args) => ({
      id: d.id,
      person_id: d.id,
      name: ['first_name', 'middle_name', 'last_name'].map(n => d[n]).filter(n => n).join(' '),
      //name: d.person,
      last_name: d.last_name,
      first_name: d.first_name,
      middle_name: d.middle_name,
      sex: d.person_gender_code,
      'dob': new Date(d.birth_date),
      'deceased': (d.deceased_date),
      '65': (d => { d.setFullYear(d.getFullYear() + 65); return d; })(new Date(d.birth_date)),
      ssn: d.last_4.ssn,
      emails: d.emails.map(e => e.email).join(', '),
      phones: d.phones.map(p => `${p.number}${p.phone_type_code ? ` (${p.phone_type_code})` : ''}`).join(', '),
      line1: d.preferred_address && d.preferred_address.address ? d.preferred_address.address.line1 : null,
      line2: d.preferred_address && d.preferred_address.address ? d.preferred_address.address.line2 : null,
      line3: d.preferred_address && d.preferred_address.address ? d.preferred_address.address.line3 : null,
      city: d.preferred_address && d.preferred_address.address ? d.preferred_address.address.city : null,
      state: d.preferred_address && d.preferred_address.address ? d.preferred_address.address.address_state_code : null,
      zip: d.preferred_address && d.preferred_address.address ? d.preferred_address.address.zip : null,
      status: d.current_state && d.current_state.status ? d.current_state.status : null,
      "computed group": d.current_state && d.current_state.group ? d.current_state.group : null,
      "current employer": d.current_state && d.current_state.employer ? d.current_state.employer : null,
      "last employer": d.employments && d.employments.length > 0 ? d.employments.map(e => ({ ...e, start_date: e.start_date ? new Date(e.start_date) : null })).filter(e => e.start_date && e.start_date < new Date()).sort((e1, e2) => e2.start_date - e1.start_date).map(e => e.employer_code)[0] : null,
    })
  },
  {
    name: 'Contributions Report',
    desc: 'Total employee contributions by employer.',
    subname: args => `(${args.year})`,
    icon: 'attach_money',
    args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() : d.getFullYear() - 1 })(new Date()) },
    limit: 2000,
    sort: [{ col: 'employer', reverse: false }],
    filter: [],
    query: (filter, sort, limit, offset, args) => {
      console.log("OFFSET", offset);
      const lookup = {
        'year': 'year',
        'employer': 'employer',
        'Total EE Contributions': 'total_ee_contribution',
      };

      let ret = gql`
      query begin_deduction {
        report: report_contributions_by_employer (
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {year: {_eq: ${args.year}}},
            ${makeFilter(filter, lookup)}
          ]}
        )
        {
          year
          employer
          total_ee_contribution
        }
      }
      `;
      return ret;
    },
    columns: [
      { c: 'employer', t: 'ltext' },
      { c: 'Total EE Contributions', t: 'money' },
    ],
    download_columns: [
      { c: 'year', t: 'number' },
      { c: 'employer', t: 'ltext' },
      { c: 'Total EE Contributions', t: 'money' },
    ],
    row_func: d => ({
      year: d.year,
      employer: d.employer,
      'Total EE Contributions': d.total_ee_contribution
    })
  },
  {
    name: 'Summary Statements',
    icon: 'people',
    //wip: true,
    args: {},
    limit: 2000,
    desc: "Pension summary statement printing data.",
    // order_by: [{report:{group_code: asc}}, {last_name: asc}]
    // ( where: {report: {basis: {_is_null:false}}})
    sort: [],
    filter: [],
    //subname: args => `(${args.year})`,
    query: (filter, sort, limit, offset, args) => {
      console.log("args=", args, "sort=", sort);
      const lookup = {

      };

      //order_by: [{report:{group_code: asc}}, {last_name: asc}]
      //where: {basis:{}}
      return gql`
      query pension_statement {
        report:report_pension_statement_view(
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            ${makeFilter(filter)}
          ]}
        )
        {
          activegroupcode
          person_id
          last_name
          first_name
          participantfullname
          birthdate
          hireddate
          benefitstartdate
          contributionstartdate
          pensionparticipantno
          activegroupname
          address
          address2
          city
          state
          zipcode
          contribs_type
          contribs_date
          contributiondate
          interest
          contributionamount
          contribs_bal
          contribs_total_interest
          contribs_total_contribs
          accountbalance
          totalinterest
          totalcontribution
        }
      }
      `;
    },

    columns: [
      { c: 'ActiveGroupCode', t: 'ltext' },
      { c: 'ParticipantFullName', t: 'person_link' },
      { c: 'BirthDate', t: 'date' },
      { c: 'HiredDate', t: 'date' },
      { c: 'BenefitStartDate', t: 'date' },
      { c: 'ContributionStartDate', t: 'date' },
      { c: 'PensionParticipantNo', t: 'number' },
      { c: 'ActiveGroupName', t: 'ltext' },
      { c: 'Address', t: 'ltext' },
      { c: 'Address2', t: 'ltext' },
      { c: 'City', t: 'ltext' },
      { c: 'State', t: 'ltext' },
      { c: 'ZipCode', t: 'ltext' },
      { c: 'ContributionDate', t: 'date' },
      { c: 'Interest', t: 'money' },
      { c: 'ContributionAmount', t: 'money' },
      { c: 'AccountBalance', t: 'money' },
      { c: 'TotalInterest', t: 'money' },
      { c: 'TotalContribution', t: 'money' },
    ],
    download_columns: [
      { c: 'ActiveGroupCode', t: 'ltext' },
      { c: 'LastName', t: 'ltext' },
      { c: 'FirstName', t: 'ltext' },
      { c: 'ParticipantFullName', t: 'ltext' },
      { c: 'BirthDate', t: 'date' },
      { c: 'HiredDate', t: 'date' },
      { c: 'BenefitStartDate', t: 'date' },
      { c: 'ContributionStartDate', t: 'date' },
      { c: 'PensionParticipantNo', t: 'number' },
      { c: 'ActiveGroupName', t: 'ltext' },
      { c: 'Address', t: 'ltext' },
      { c: 'Address2', t: 'ltext' },
      { c: 'City', t: 'ltext' },
      { c: 'State', t: 'ltext' },
      { c: 'ZipCode', t: 'ltext' },
      { c: 'ContributionDate', t: 'date' },
      { c: 'Interest', t: 'money' },
      { c: 'ContributionAmount', t: 'money' },
      { c: 'AccountBalance', t: 'money' },
      { c: 'TotalInterest', t: 'money' },
      { c: 'TotalContribution', t: 'money' },
    ],
    row_func: d => ({
      id: d.person_id,
      person_id: d.person_id,
      "ActiveGroupCode": d.activegroupcode,
      "LastName": d.lastname,
      "FirstName": d.firstname,
      "ParticipantFullName": d.participantfullname,
      "BirthDate": d.birthdate,
      "HiredDate": d.hireddate,
      "BenefitStartDate": d.benefitstartdate,
      "ContributionStartDate": d.contributionstartdate,
      "PensionParticipantNo": d.pensionparticipantno,
      "ActiveGroupName": d.activegroupname,
      "Address": d.address,
      "Address2": d.address2,
      "City": d.city,
      "State": d.state,
      "ZipCode": d.zipcode,
      "ContributionDate": d.contributiondate,
      "Interest": d.interest,
      "ContributionAmount": d.contributionamount,
      "AccountBalance": d.accountbalance,
      "TotalInterest": d.totalinterest,
      "TotalContribution": d.totalcontribution,
    })
  },

  {
    name: 'Actuarial Report',
    icon: 'people',
    args: { year: (d => { return (d.getMonth() === 11 && d.getDate() > 29) ? d.getFullYear() : d.getFullYear() - 1 })(new Date()) },
    limit: 2000,
    desc: "Status of current participants.",
    // order_by: [{report:{group_code: asc}}, {last_name: asc}]
    // ( where: {report: {basis: {_is_null:false}}})
    sort: [{ col: 'group', reverse: false }, { col: 'name', reverse: false }, { col: 'person.id', reverse: false }],
    filter: [],
    subname: args => `(${args.year})`,
    query: (filter, sort, limit, offset, args) => {
      console.log("args=", args, "sort=", sort);
      const lookup = {
        'name': 'person.last_name',
        'group': 'group_code',
        'sex': 'person.person_gender_code',
        'dob': 'person.birth_date',
        'ssn': 'person.last_4.ssn',
        'hired': 'hired',
        'benefit start': 'basis',
        'member': 'participating',
        'contributor': 'contributing',
        'total contrib': 'total_contributions',
        'total interest': 'contribution_interest',
        'balance': 'contribution_balance',
        'sal0': 'sal0',
        'sal1': 'sal1',
        'sal2': 'sal2',
        'service': 'credited_years',
        'vesting': 'vesting_years',
      };

      //order_by: [{report:{group_code: asc}}, {last_name: asc}]
      //where: {basis:{}}
      return gql`
      query actuarial_report {
        report:report_yearly_data(
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            {year: {_eq: ${args.year}}},
            {participating: {}},
            ${makeFilter(filter)}
          ]}
        )
        {
          person {
            id
            first_name
            last_name
            middle_name
            last_4 { ssn }
            person_gender_code
            birth_date
          }
          year
          group_code
          basis
          age
          total_contributions
          contribution_balance
          contribution_interest
          sal0
          sal1
          sal2
          credited_years
          vesting_years
          contributing
          participating
          hired
          ytd_contribution_interest
          ytd_credited_years
          ytd_service_hours
          ytd_vesting_years
        }
      }
      `;
    },
    columns: [
      { c: 'name', t: 'person_link' },
      { c: 'group', t: 'ctext' },
      { c: 'ssn', t: 'ssn' },
      { c: 'sex', t: 'ctext' },
      { c: 'dob', t: 'date' },
      { c: 'age', t: 'decimal', decimals: 2 },
      { c: 'hired', t: 'date' },
      { c: 'benefit start', t: 'date' },
      { c: 'member', t: 'date' },
      { c: 'service', t: 'decimal', decimals: 3 },
      { c: 'vesting', t: 'number', decimals: 0 },
      { c: 'contributor', t: 'date' },
      { c: 'total contrib', t: 'money' },
      { c: 'total interest', t: 'money' },
      { c: 'balance', t: 'money' },
      { c: 'sal0', t: 'money' },
      { c: 'sal1', t: 'money' },
      { c: 'sal2', t: 'money' },
    ],
    download_columns: [
      { c: 'person_id', t: 'person_link' },
      { c: 'last_name', t: 'ltext' },
      { c: 'first_name', t: 'ltext' },
      { c: 'middle_name', t: 'ltext' },
      { c: 'group', t: 'ctext' },
      { c: 'ssn', t: 'ssn' },
      { c: 'sex', t: 'ctext' },
      { c: 'dob', t: 'date' },
      { c: 'age', t: 'number' },
      { c: 'hired', t: 'date' },
      { c: 'benefit start', t: 'date' },
      { c: 'member', t: 'date' },
      { c: 'service', t: 'decimal', decimals: 3 },
      { c: 'vesting', t: 'number', decimals: 0 },
      { c: 'contributor', t: 'date' },
      { c: 'total contrib', t: 'money' },
      { c: 'total interest', t: 'money' },
      { c: 'balance', t: 'money' },
      { c: 'sal0', t: 'money' },
      { c: 'sal1', t: 'money' },
      { c: 'sal2', t: 'money' },
    ],
    row_func: d => ({
      id: d.person.id,
      person_id: d.person.id,
      name: ['first_name', 'middle_name', 'last_name'].map(n => d.person[n]).filter(n => n).join(' '),
      //name: d.person,
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      group: d.group_code,
      ssn: d.person.last_4.ssn,//d.person.ssn.slice(d.person.ssn.length - 4),
      sex: d.person.person_gender_code,
      dob: (d.person.birth_date),
      age: (d.age),
      'benefit start': (d.basis),
      'total contrib': (d.total_contributions),//tomoney(d.contribution_balance - d.contribution_interest),
      'total interest': (d.contribution_interest),
      'balance': (d.contribution_balance),
      'sal0': (d.sal0),
      'sal1': (d.sal1),
      'sal2': (d.sal2),
      'service': d.credited_years,
      'vesting': d.vesting_years,
      'contributor': (d.contributing),
      'member': (d.participating),
      'hired': (d.hired)
    })
  },

  {
    name: '2018 Actuarial Validation Report',
    icon: 'done',
    args: {},
    limit: 2000,
    desc: "Compare new actuarial report results with reference 2018 results from old system.",
    // order_by: [{report:{group_code: asc}}, {last_name: asc}]
    // ( where: {report: {basis: {_is_null:false}}})
    sort: [{ col: 'group_sys', reverse: false }, { col: 'name', reverse: false }, { col: 'id', reverse: false }],
    //filter: [{ col: 'benefit_start_match', op: '_eq', val: false }],
    filter: [],
    highlight: (row, field) => {
      const ssns = new Set(["612545847", "555737264", "625726938", "618389766", "024621752", "299645355", "285845320", "196589556", "219331010", "046620743", "190461576", "128721250", "549471247", "167624178", "063681616", "191608597", "653926345", "320645485", "525871679", "290706093", "169660237", "038403662", "302825289", "281740612", "342784163", "267732719", "375131411", "163526148", "592805842", "506196317", "125748846", "561871824", "595809488", "279581815", "114986174", "286927899", "348544757", "254738644", "196483867", "049687958", "411651568", "138883046", "085661937", "229551689", "216257751", "143907503", "286861010", "405375131", "132683077", "394948158", "014543065", "275682984", "268681841", "041504873", "099609575", "623463401", "461694091", "341803330", "118665872", "345881105", "441808319", "595508277", "130468204", "287702419", "553319324", "384118112", "059388957", "468985943", "589463458", "584823352", "334580081", "318620271", "202680345", "128508698", "478064924", "090724943", "069702793", "546671428", "278760569", "603328394", "575535556", "021744287", "197705065", "290821630", "319807697", "468949143", "611182784", "257418355", "077846535", "085728267", "285862284", "542139113", "606744909"]);
      return ssns.has(row.ssn)
    },
    query: (filter, sort, limit, offset, args) => {
      const lookup = {
      };

      //order_by: [{report:{group_code: asc}}, {last_name: asc}]
      //where: {basis:{}}
      //{ssn: {_in: ["612545847", "555737264", "625726938", "618389766", "024621752", "299645355", "285845320", "196589556", "219331010", "046620743", "190461576", "128721250", "549471247", "167624178", "063681616", "191608597", "653926345", "320645485", "525871679", "290706093", "169660237", "038403662", "302825289", "281740612", "342784163", "267732719", "375131411", "163526148", "592805842", "506196317", "125748846", "561871824", "595809488", "279581815", "114986174", "286927899", "348544757", "254738644", "196483867", "049687958", "411651568", "138883046", "085661937", "229551689", "216257751", "143907503", "286861010", "405375131", "132683077", "394948158", "014543065", "275682984", "268681841", "041504873", "099609575", "623463401", "461694091", "341803330", "118665872", "345881105", "441808319", "595508277", "130468204", "287702419", "553319324", "384118112", "059388957", "468985943", "589463458", "584823352", "334580081", "318620271", "202680345", "128508698", "478064924", "090724943", "069702793", "546671428", "278760569", "603328394", "575535556", "021744287", "197705065", "290821630", "319807697", "468949143", "611182784", "257418355", "077846535", "085728267", "285862284", "542139113", "606744909"]}},
      return gql`
        query validation_report {
          report: report_actuarial_validation(
            ${makeCond(sort, limit, offset, lookup)}
            where: 
            {
              _and: [
                {year: {_eq: 2018}},
                ${makeFilter(filter)}
              ]
            }
          ) {
            id
            error_count
            alert_count
            migration_count
            year
            name
            ssn
            age
            group_ref
            group_sys
            group_match
            sex_ref
            sex_sys
            sex_match
            dob_ref
            dob_sys
            dob_match
            hired_ref
            hired_sys
            hired_match
            benefit_start_ref
            benefit_start_sys
            benefit_start_match
            member_ref
            member_sys
            member_match
            service_ref
            service_sys
            service_match
            vesting_ref
            vesting_sys
            vesting_match
            contributor_ref
            contributor_sys
            contributor_match
            total_contributions_ref
            total_contributions_sys
            total_contributions_match
            total_interest_ref
            total_interest_sys
            total_interest_match
            account_balance_ref
            account_balance_sys
            account_balance_match
            sal0_ref
            sal0_sys
            sal0_match
            sal1_ref
            sal1_sys
            sal1_match
            sal2_ref
            sal2_sys
            sal2_match
          }
        }
      `;
    },
    columns: [
      { c: 'name', t: 'person_link' },
      { c: 'error_count', a: 'err', t: 'int' },
      { c: 'alert_count', a: 'alrt', t: 'int' },
      { c: 'migration_count', a: 'mgrt', t: 'int' },
      { c: 'group', t: 'ctext', compare: true },
      { c: 'ssn', t: 'ssn' },
      { c: 'sex', t: 'ctext', compare: true },
      { c: 'dob', t: 'date', compare: true },
      { c: 'age', t: 'decimal', decimals: 2 },
      { c: 'hired', t: 'date', compare: true },
      { c: 'benefit_start', t: 'date', compare: true },
      { c: 'member', t: 'date', compare: true },
      { c: 'service', t: 'decimal', decimals: 3, compare: true },
      { c: 'vesting', t: 'number', decimals: 0, compare: true },
      { c: 'contributor', t: 'date', compare: true },
      { c: 'total_contributions', a: 'ctrbs', t: 'money', compare: true },
      { c: 'total_interest', a: 'intr', t: 'money', compare: true },
      { c: 'account_balance', a: 'acct bal', t: 'money', compare: true },
      { c: 'sal0', t: 'money', compare: true },
      { c: 'sal1', t: 'money', compare: true },
      { c: 'sal2', t: 'money', compare: true },
    ],
    download_columns: [
      { c: 'id', t: 'person_link' },
      { c: 'name', t: 'ltext' },
      { c: 'error_count', t: 'int' },
      { c: 'alert_count', t: 'int' },
      { c: 'migration_count', t: 'int' },
      { c: 'group', t: 'ctext', compare: true },
      { c: 'ssn', t: 'ssn' },
      { c: 'sex', t: 'ctext', compare: true },
      { c: 'dob', t: 'date', compare: true },
      { c: 'age', t: 'decimal', decimals: 2 },
      { c: 'hired', t: 'date', compare: true },
      { c: 'benefit_start', t: 'date', compare: true },
      { c: 'member', t: 'date', compare: true },
      { c: 'service', t: 'decimal', decimals: 3, compare: true },
      { c: 'vesting', t: 'number', decimals: 0, compare: true },
      { c: 'contributor', t: 'date', compare: true },
      { c: 'total_contributions', t: 'money', compare: true },
      { c: 'total_interest', t: 'money', compare: true },
      { c: 'account_balance', t: 'money', compare: true },
      { c: 'sal0', t: 'money', compare: true },
      { c: 'sal1', t: 'money', compare: true },
      { c: 'sal2', t: 'money', compare: true },
    ]
  },
  {
    name: 'Error Summary Report',
    desc: "Counts grouped by message type.",
    icon: 'error',
    args: {},
    limit: 500,
    sort: [{ col: 'affected', reverse: true }],
    filter: [],
    query: (filter, sort, limit, offset) => {
      return gql`
      query error_summary {
        report:view_cached_errors_summary(
          ${makeCond(sort, limit, offset, {})}
          where: ${makeFilter(filter)
        }
        ) {
          level
          code
          instances
          affected
        }
      }`
    },
    columns: [
      { c: 'level', t: 'ltext' },
      { c: 'code', t: 'ltext' },
      { c: 'instances', t: 'number' },
      { c: 'affected', t: 'number' },
    ],
    row_func: d => ({
      level: html`<span class=${d.level}>${d.level}</span>`,
      code: d.code,
      instances: d.instances.toLocaleString(),
      affected: d.affected.toLocaleString()
    })
  },
  {
    name: 'Error Detail Report',
    desc: "Errors, alerts and migration issues by person.",
    icon: 'error',
    args: {},
    limit: 500,
    sort: [{ col: 'total', reverse: true }, { col: 'name', reverse: false }, { col: 'level' }],
    filter: [],
    query: (filter, sort, limit, offset) => {
      const lookup = {
        'name': 'person.last_name',
      };

      return gql`
      query error_detail{
        report:cached_errors(
          ${makeCond(sort, limit, offset, lookup)}
          where: {_and: [
            ${makeFilter(filter, lookup)}
          ]}
        ) {
          person {
                id
                first_name
                last_name
                middle_name
              }
            date
            level
            code
            message
            message_count
            level_count
            total
        }
      }`
    },
    columns: [
      { c: 'name', t: 'person_link' },
      { c: 'level', t: 'ltext' },
      { c: '@level', t: 'number' },
      // { c: 'code', t: 'ltext' },
      { c: 'message', t: 'ltext' },
      { c: 'repeats', t: 'number' },
      { c: 'total', t: 'number' },
    ],
    download_columns: [
      { c: 'person_id', t: 'person_link' },
      { c: 'last_name', t: 'ltext' },
      { c: 'first_name', t: 'ltext' },
      { c: 'middle_name', t: 'ltext' },
      { c: 'level', t: 'ltext' },
      { c: '@level', t: 'number' },
      { c: 'code', t: 'ltext' },
      { c: 'message', t: 'ltext' },
      { c: 'repeats', t: 'number' },
      { c: 'total', t: 'number' },
    ],
    row_func: d => ({
      id: d.person.id,
      person_id: d.person.id,
      //id: `<a href="https://benefits-test.afscme.org/people/view?person=${d.person.id}">${d.person.id}</a>`,
      //name: html`<a href="/people/view?person=${d.person.id}">${['first_name', 'middle_name', 'last_name'].map(n => d.person[n]).filter(n => n).join(' ')}</a>`,
      name: ['first_name', 'middle_name', 'last_name'].map(n => d.person[n]).filter(n => n).join(' '),
      //name: d.person,
      last_name: d.person.last_name,
      first_name: d.person.first_name,
      middle_name: d.person.middle_name,
      level: html`<span class=${d.level}>${d.level}</span>`,
      code: d.code,
      message: d.message,
      '@level': d.level_count,
      'repeats': d.message_count,
      total: d.total,
    })
  },
]
export const reports = declared_reports.filter(r => !r.wip);
