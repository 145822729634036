import { LitElement, html, css, nothing } from 'lit';
import { MDuesPage, set_current_period, get_current_period } from '../components/mdues_page.js';
import { repeat } from 'lit/directives/repeat.js';
import { guard } from 'lit/directives/guard.js';
import '@material/mwc-icon';
import '@material/mwc-icon';
import '@material/mwc-button';
import '@material/mwc-menu';
import '@material/mwc-dialog';
import '@material/mwc-textfield';
import '@material/mwc-checkbox';
import '@material/mwc-linear-progress';
import {dayjs} from '../shared-components/utilities/dates.js';

import { ProgressCircle } from '../shared-components/progress-circle.js';

import { client, formatQueryError, refetch_searches, unit_fields, increase_fields, EditIncrease, EditUnit, agreement_info_fields, EditAgreement, EditUnitAlias, EditFileUpload } from '../queries/queries.js';
import gql from 'graphql-tag';


import { KaleForm, KaleTextField, KaleDate, KaleToggle, KaleEnum } from '../shared-components/form.js';
import { ContributionDateCell, ContributionAmtCell, ContributionTextCell, ContributionSSNCell, ContributionBoolCell } from '../components/editors.js';
import { debounce } from '../shared-components/utilities/debounce.js';
import * as Comlink from 'comlink';
import { wait } from '../shared-components/utilities/anim.js';
import { validate } from 'graphql';



const jsdate = (d) => {
  return d ? new EventDate(new Date(d.y, d.m - 1, d.d)) : null;
}

const xlsxdate = (data) => {
  return jsdate(XLSX.SSF.parse_date_code(data));
}

const isnum = n => n !== null && n !== undefined && !isNaN(Number(String(n).replace(/[^\d.]/g, '')));

let _stringer_memoize = new Map();
const stringerize = s => {
  if (_stringer_memoize.has(s)) return _stringer_memoize.get(s);
  const res = s.toLowerCase().replace(/[^a-z0-9 ]/, '').split(' ').map(x => x.trim()).join(' ');
  _stringer_memoize.set(s, res);
  return res;
}

// TODO attempt to align words in s2 with most similar words in s1 and return this alignment

let sort_memo = new Map();
const alphasort = (s) => {
  if (sort_memo.has(s)) return sort_memo.get(s);
  const ret = s.split(' ').map(a => a.trim()).filter(a => a && a!=='').sort((a,b) => {
    if(a < b) { return -1; }
    if(a > b) { return 1; }
    return 0;
  }).join(' ');
  sort_memo.set(s, ret);
  return ret;
}

let sim_memo = new Map();
const similarity = (s1, s2, debug) => {
  if (debug) console.warn(`RAW sim strings: '${s1}' vs '${s2}, stringerized: '${stringerize(s1)}' : '${stringerize(s2)}'`);
  s1 = alphasort(stringerize(s1 || ''));
  s2 = alphasort(stringerize(s2 || ''));
  const key = `${s1}+${s2}`;
  if (sim_memo.has(key)){
    const result = sim_memo.get(key);
    if (debug) console.log(`CACHED similarity("${s1}", "${s2}") = ${result} [key="${key}"]`)
    return result;
  }
  const result = similarity_actual(s1, s2);
  sim_memo.set(key, result);
  if (debug) console.log(`similarity("${s1}", "${s2}") = ${result} [key="${key}"]`)
  return result;
}
const similarity_actual = (s1, s2) => {
  if (s1 === s2) return 1.0;
  var longer = s1;
  var shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  var longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }

 // return (longerLength - editDist(longer, shorter)) / parseFloat(longerLength);
  return (longerLength - distance_damerau(longer, shorter)) / parseFloat(longerLength);
}
// Levenshtein edit distance

let editdist_memoize = new Map();
const editDist = (a, b) => {
  //a = stringerize(a);
  //b = stringerize(b);
  let alen = a.length;
  let blen = b.length;
  if (alen === 0) return blen;
  if (blen=== 0) return alen;

  const key = a+b;
  if (editdist_memoize.has(key)) return editdist_memoize.get(key);

  let tmp, i, j, prev, val, row, ma, mb, mc, md, bprev;

  if (alen> blen) {
    tmp = a;
    a = b;
    b = tmp;
  }

  row = new Int8Array(alen+1);
  // init the row
  for (i = 0; i <= alen; i++) {
    row[i] = i;
  }

  // fill in the rest
  for (i = 1; i <= blen; i++) {
    prev = i;
    bprev = b[i - 1]
    for (j = 1; j <= alen; j++) {
      if (bprev === a[j - 1]) {
        val = row[j-1];
      } else {
          ma = prev+1;
          mb = row[j]+1;
          mc = ma - ((ma - mb) & ((mb - ma) >> 7));
          md = row[j-1]+1;
          val = mc - ((mc - md) & ((md - mc) >> 7));
      }
      row[j - 1] = prev;
      prev = val;
    }
    row[alen] = prev;
  }
  editdist_memoize.set(key, row[alen]);
  return row[alen];
};

// damerau lev:

const initMatrix = (s1, s2) => {
  /* istanbul ignore next */
  if (undefined == s1 || undefined == s2) {
    return null;
  }

  let d = [];
  for (let i = 0; i <= s1.length; i++) {
    d[i] = [];
    d[i][0] = i;
  }
  for (let j = 0; j <= s2.length; j++) {
    d[0][j] = j;
  }

  return d;
};

const damerau = (i, j, s1, s2, d, cost) => {
  if (i > 1 && j > 1 && s1[i - 1] === s2[j - 2] && s1[i - 2] === s2[j - 1]) {
    d[i][j] = Math.min.apply(null, [d[i][j], d[i - 2][j - 2] + cost]);
  }
};

const distance_damerau = (s1, s2) => {
  if (
    undefined == s1 ||
    undefined == s2 ||
    "string" !== typeof s1 ||
    "string" !== typeof s2
  ) {
    return -1;
  }

  let d = initMatrix(s1, s2);
  /* istanbul ignore next */
  if (null === d) {
    return -1;
  }
  for (var i = 1; i <= s1.length; i++) {
    let cost;
    for (let j = 1; j <= s2.length; j++) {
      if (s1.charAt(i - 1) === s2.charAt(j - 1)) {
        cost = 0;
      } else {
        cost = 1;
      }

      d[i][j] = Math.min.apply(null, [
        d[i - 1][j] + 1,
        d[i][j - 1] + 1,
        d[i - 1][j - 1] + cost,
      ]);

      damerau(i, j, s1, s2, d, cost);
    }
  }

  return d[s1.length][s2.length];
};


/*

function editDistance(s1, s2) {
  //s1 = s1.toLowerCase();
  //s2 = s2.toLowerCase();
  s1 = stringerize(s1);
  s2 = stringerize(s2);

  var costs = new Array();
  for (var i = 0; i <= s1.length; i++) {
    var lastValue = i;
    for (var j = 0; j <= s2.length; j++) {
      if (i == 0)
        costs[j] = j;
      else {
        if (j > 0) {
          var newValue = costs[j - 1];
          if (s1.charAt(i - 1) != s2.charAt(j - 1))
            newValue = Math.min(Math.min(newValue, lastValue),
              costs[j]) + 1;
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0)
      costs[s2.length] = lastValue;
  }
  return costs[s2.length];
}

window.editd1 = editDist;
window.editd2 = editDistance;
*/


const arcDraw = (radius, cx, cy, pct) => {
  pct = pct === undefined ? 0 : pct;
  pct = pct === 1 ? 0.999999 : pct;
  let phi = (Math.PI / 2) * 3 - pct * Math.PI * 2;
  return `M ${cx},${cy + radius} A ${radius} ${radius} 0 ${pct <= 0.5 ? 0 : 1},1 ${cx + radius * Math.cos(phi)} ${cy - radius * Math.sin(phi)}`;
}
const alpha_sort = (a, b) => {
  a = a && a.trim ? a.trim() : a;
  b = b && b.trim ? b.trim() : b;
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}
const contact_sort = (a,b) => alpha_sort(a?.name, b?.name);

const numeric_sort = (a, b) => Number(a) - Number(b);
const date_sort = (a, b) => new Date(a) - new Date(b);

const sorts = {
  'row_number': numeric_sort,
  'match': numeric_sort,
  'employer': alpha_sort,
  'subunit': numeric_sort, // works for bools
  'master': alpha_sort,
  'local': numeric_sort,
  'council': numeric_sort,
  'agreement_effective': date_sort,
  'agreement_expires': date_sort,
  'members': numeric_sort,
  'pct': numeric_sort,
  'cents_hourly': numeric_sort,
  'hourly_base': numeric_sort,
  'annual': numeric_sort,
  'annual_base': numeric_sort,
  'effective': date_sort,
  'negotiations': numeric_sort,
  'comments': alpha_sort,
  'contact': contact_sort
}
 const formatErrors = errs => {
                const first = errs?.[0];
                const remainder = errs?.slice(1);
                return first ? html`
                <div class='errors' title=${errs?.map?.(e => `[${e}]`)?.join?.('\n') || 'no errors'}>
                  <span class="err">${first}</span>${remainder && remainder.length > 0 ? html`<span class="err_more">&hellip;+${remainder.length}</span>` : nothing}
                </div>
                ` : nothing;
              }


const cell_match = (row, header) => row.match_fields && row.match_fields[header.short] && row.match_fields[header.short].match_score;
const cell_match_title_info = (row, header) => row.match_fields && row.match_fields[header.short] ? `${header.long}: ${row[header.short] || 'none'} ⇨ ${row.match_fields[header.short].comparing[1] || 'none'} [${Math.round(row.match_fields[header.short].val * 100)}%]` : null;
const match_info_text = (row) => {
  const match = row?.unit;
  //const {name=null, state=null, council=null, local=null, subunit=null} = row?.unit;
  const abb = {
  'name': '', 'state': '', 'council': 'C', 'local': 'L', 'subunit': 'subunit '
  }
  const suff = {
    'name': '\n', 'state': ' ', 'council': ' ', 'local': '\n', 'subunit': ''

  }

  return match ? ['name', 'state', 'council', 'local', 'subunit'].map(n => match[n] && `${abb[n]}${match[n]}${suff[n]}`).filter(n => n).join('') : '';
}


const import_export_page_styles = css`
       
      mwc-button {
        --mdc-theme-on-primary: white;
        --mdc-theme-primary: var(--paper-pink-a200);
        --mdc-theme-on-secondary: white;
        --mdc-theme-secondary: var(--paper-pink-a200);
      }

      .column {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column; 
        width: 100%;
        padding: 0;
        padding-top: 0;
        height: 100%;
      }
      .column > * {
        margin-top: 24px;
      }
      .load_message {
          padding-bottom: 18px;

        }

      .drag_placeholder {
          text-align: center;
          opacity: 0.4;
          box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        width: 100%;
        height: 100%;

        }

        .scroller {
          overflow: overlay;
          height: calc(100vh - 64px);
          width: 100%;
        }

        .content-area {
          flex: 1;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          align-items: flex-start;

          height: calc(100vh - 64px);
        }

        mwc-fab {
          position: static;
        }
        #fab-holder {
          width: 100%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: flex-end;
          flex-direction: row;
        }

        .primary {
          font-weight: 500;
          font-size: 20px;
          }
        .secondary {
          font-weight: 200;
          font-size: 16px;
          margin-top: 8px;
          }


        mwc-top-app-bar {
          --mdc-theme-primary: var(--paper-green-700);
          background-color: var(--paper-green-700);
        }

        .card {
          box-sizing: border-box;
          background-color: white;
          border: 1px solid var(--paper-grey-400);
          border-radius: 8px;
          transition: var(--shadow-transition);
          height: 100%;
          width: 100%;
          font-size: 12px;
          overflow: hidden;

          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column; 
          margin-bottom: 60px;

        }
        .card > h2 {
          margin: 12px;
          font-size: 90%;
        }

        .card:hover {
          box-shadow: var(--shadow-elevation-8dp_-_box-shadow);
        }

        .card[height_computed] {
          height: fit-content;
        }

        .table-scroller[height_computed] {
          height: fit-content;
        }
              
        .top-app-bar-adjust {
          margin-top: 64px;
          }


        /* TABLE STYLES */
    .table-container { 
        flex: 1 1; 
        box-sizing: border-box; 
        width: 100%; 
        height: 100%;
        position: relative; /*FIXME: this isn't optimal... */ 
        overflow-y: auto; 
        overflow-x: auto;
        max-height: 100%;

        overflow-y: overlay;
        height: calc(100vh - 64px);
        margin: 0;
        }
      .table-scroller { 
        height: intrinsic;           /* Safari/WebKit uses a non-standard name */
        height: -moz-max-content;    /* Firefox/Gecko */
        height: -webkit-max-content; /* Chrome */
        height: max-content;
        width: intrinsic;           /* Safari/WebKit uses a non-standard name */
        width: -moz-max-content;    /* Firefox/Gecko */
        width: -webkit-max-content; /* Chrome */
        width: max-content;
        min-width: 100%;
        padding-bottom: 120px;
        }

        table { box-sizing: border-box; border-collapse: collapse; width: 100%; font-size: 80%}
        td,th { border: none; padding: 6px 12px; whitespace: normal;}
        th {
          text-align: left;
          text-transform: uppercase;
          border: none;
          font-size: 80%;
          background-color: var(--paper-grey-200);
          color: var(--paper-grey-600);
          z-index: 2;
          position: sticky;
          top: 0;
        }
        tr#file_header {

        }

        th[type="number"], th[type="amt"], th[type="pct"] {
          text-align: right;
        }
        th[type="match"], td.match {
          max-width: 130px;
        }

        td.check_status {
          text-align: center;
        }
        td.check_status > mwc-checkbox {
          position: relative;
          right: 12px;
        }
        th > div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row; 
          position: relative;
        }
        th.control {
          padding: 0;
        }

        th.control > div {
          justify-content: center;
        }
        
        th[type="number"] > div, th[type="amt"] > div {
          justify-content: flex-end;
        }
        th[type="number"] span, th[type="amt"] span {
          order: 99;
        }


        th mwc-icon {
          visibility: hidden;
          font-size: 12px;
        }
        th mwc-icon[sort]{
          visibility: visible;
        }
        th mwc-icon[reverse] {
          transform: scaleY(-1);
        }

        th mwc-icon[filtered]{
          visibility: visible;
        }
        th:hover mwc-icon:not([sort]) {
          visibility: visible;
          opacity: 0.45;
        }
        th mwc-menu {
          visibility: hidden;
          position: fixed;
          visibility: visible;
        }
        th:hover mwc-menu {
          visibility: visible;
        }

        th .filter_menu_container {
          position: absolute;
          bottom: 0px;
        }

        .filter_item {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;

        }

        th span {
        }

        th:hover {
          cursor: pointer;
        }

        th.control {
          opacity: 1;
        }

        td[errors] {
        }
        td[errors] .errors {
          font-weight: 900;
          font-size: 95%;
        }
        td[errors] .errors > .err {
          display: inline-block;
          background-color: var(--paper-red-800);
          color: white;
          border-radius: 4px;
          padding: 3px 6px;
          margin: 4px -3px;
          margin-right: 3px;
        }

        td[errors] .errors > .err_more {
          color: var(--paper-red-800);
          font-size: 80%;
          font-weight: 900;
        }

        td[errors] .errors > .err[last-child] {
          margin-right: 0;
        }


        mwc-select[duplicate] {
          --mdc-select-fill-color: var(--paper-yellow-700);
        }
        tr[errors] > td {
          background-color: var(--paper-red-100);
          --mdc-select-fill-color: (var(--paper-red-200));
          
        }
        span.error_count {
          font-weight: 900;
          color: var(--paper-red-800);
        }


 


        /*
        tr { border: none}
        tr:nth-child(even) { background: #CCC;}
        */

        tr {
          border: none;
          border-bottom: 1px solid var(--paper-grey-400); 
          content-visibility: auto;
          /*contain-intrinsic-size: 72px;*/
        }
        tbody > tr:hover {background-color: var(--paper-yellow-50); cursor: pointer;}
        tr[selected] {background-color: var(--paper-teal-50); cursor: pointer;}
        tr:not([validated]) > td { opacity: 0.5 }
        tr[inprogress] {
          opacity: 0.5
        }
        tr[inprogress] > td {
          /*background-color: var(--paper-purple-100);*/
        }
        tr[unitdialogopen] > td div.match_info {
          color: var(--paper-purple-200);
        }

        td {
        }
        td.textcell, td.contactcell {
          text-align: left;
          max-width: 100px;
        }


        td.textcell > div  {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
        td.textcell span.overflow_ellipsis {
          text-overflow: ellipsis;
          white-space: break-spaces;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }
        td.contactcell > div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        td.textcell[column='employer'] > div {
          white-space: normal;
        }


        td.amtcell, td.datecell, td.textcell, td.numbercell > *
        {
          cursor: auto;
        }

        td.remove { padding: 10px 0px;}
        td.type { 
          text-transform: uppercase;
          font-size: 75%;
          font-weight: bold;
        }

        td.amtcell, td.numbercell {
          text-align: right;
        }
        td.boolcell { 
          text-align: center;
        }
        
        td[match="good"] {
          color: var(--paper-green-900);
          --match-color: var(--paper-green-900);
        }
        td[match="poor"] {
          color: var(--paper-yellow-900);
          font-weight: 500;
          --match-color: var(--paper-yellow-900);
        }
        td[match="bad"] {
          color: var(--paper-red-900);
          font-weight: 800;
          --match-color: var(--paper-red-900);
        }

        td[match="sending"] {
          color: var(--paper-purple-700);
          --match-color: var(--paper-purple-700);
        }

        td[match="sending"] mwc-icon {
        }

        td.match {
          font-weight: 900;
        }



        span.currency {
          float: left;
        }
        contrib-datecell[changed], contrib-amtcell[changed] {
        font-style: italic;
        color: var(--paper-green-600); 
        }

        .table_controls {
          background-color: var(--paper-green-300);
          padding: 8px 16px;
          color: white;
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          --mdc-theme-primary: var(--paper-teal-700);
          color: var(--paper-teal-900);
        }
        .table_controls[selection] {
        }

        .table_controls > * {
        }

        .table_controls > .info {
          margin-left: 64px;
        }
        .table_controls > h2 {
          flex: 1 1;
          text-align: right;
          margin-right: 64px;
          font-weight: 100;
          font-size: 90%;
        }

        .table_controls .buttons {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
        }
        .buttons > * {
          margin-right: 12px;
          margin-left: 12px;
        }

        div.non_checkable {
          position: relative;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          font-size: 8px;
          color: var(--label-color);
          opacity: 0;
          text-align: center;
          width: fit-content;
          max-width: 24px;
        }

        div.non_checkable[errors] {
          --label-color: var(--paper-red-900);
          opacity: 1;
        }
        div.non_checkable[imported] {
          --label-color: var(--paper-green-800);
          opacity: 1;
        }

        div.non_checkable  .errors, div.non_checkable  .imported {
          display: block;
          text-transform: uppercase;
        }
        div.non_checkable  mwc-icon {
          display: block;
          font-size: 25px;

        }

        div.match_info {
          display: flex;
          flex-direction: column;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;
          overflow: visible !important;
          position: relative;
          
        }
        div.match_picker {
          margin-left: 12px;
          flex: 1;
        }
        .match_picker > mwc-select {
          width: 100%;
        }
        span.searching {
          color: var(--paper-purple-600);
          font-weight: 100;
          font-size: 90%;
        }
        span.new_unit {
          color: var(--paper-pink-500);
          font-weight: 900;
          font-size: 90%;
          text-transform: uppercase;
        }
        span.new_unit::before {
          /*content: '\\e148'; new circle*/
          content: '\\e255'; /* publish */
          font-family: "Material Icons";
          padding-right: 5px;
          font-size: 16px;
          position: relative;
          top: 4px;
        }



        div.gauge {
          position: relative;
          width: 24px;
          height: 24px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          justify-content: center;
          align-items: center;
          font-size: 10%;
          font-weight: bold;
          z-index: 1;
        }
        .match[match="new"] .gauge {
          color: var(--paper-purple-700);
        }

        div.gauge > span {
          white-space: nowrap;
          text-transform: uppercase;
        }
       
        #navigation {
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          padding: 12px;
        }

        #navigation-info {
          margin-right: 12px;
        }
        #navigation:last-child { margin-right : 12px }

        mwc-checkbox[disabled] {
          opacity: 0.5;
        }
        @keyframes spin {
          from {
              transform:rotate(360deg);
          }
          to {
              transform:rotate(0deg);
          }
      }
 
        svg { overflow: visible; }
        circle { opacity: 0.25 }
        path { fill: none; stroke-width: 10px;}
        circle { fill: none; stroke-width: 5px; opacity: 0.3}
        svg[match="good"] { stroke: var(--paper-green-900)}
        svg[match="poor"] { stroke: var(--paper-yellow-900)}
        svg[match="bad"] { stroke: var(--paper-red-900)}
        svg[match="sending"] { 
          stroke: var(--paper-purple-800);
          animation-name: spin;
          animation-duration: 4000ms;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        div.contactcell {
          font-size: 90%;
        }
        div.contactcell div {
          display: inline-block;
          margin-bottom: 10px;
        }

        .contactcell .contactname {
          font-weight: 900;
        }
        .contactcell .contactemail {
          font-weight: 100;
        }
        .contactcell .contactphone {
          font-weight: 100;
        }


        .progress_centered {
          z-index: 50;
          position: fixed;
          top: 50%;
          left: 50%;
          --progress-color: var(--paper-grey-300);
          --progress-bg: var(--paper-green-600);
          --progress-size: 128;
        }

        span.preview {
          display: inline-block;
          color: var(--paper-grey-300);
          background-color: var(--paper-grey-300);
          opacity: 0.5;
        }

       
       
        span.preview.checkbox {
          width: 18px;
          height: 18px;
          margin: 11px;
        }
     
        
        svg.match_circ {
          position: absolute;
          top: 0px;
          left: 0px;
          opacity: 0.8;
        }
        div.preview_holder{
          display: flex;
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          min-width: fit-content;
          overflow: hidden;
        }
        span.preview.circle {
          flex: 0 0 auto;
          width: 48px;
          height: 48px;
          min-width: 48px;
          min-height: 48px;
          border-radius: 100%;
        }


        span.preview.large {
          box-sizing: border-box;
          flex: 1 0 auto;
          font-size: 1rem;
          margin-left: 12px;
          padding: 16px 52px 16px 16px; 
          max-height: 56px;
          min-height: 56px;
          white-space: nowrap;
        } 
        div.upload_error_count {
          color: var(--paper-red-800);
          font-size: 10px;
          font-weight: 100;
        }


        div.progress_overlay {
          position: fixed;
          height: calc(100vh - 250px);
          width: 100%;
          display: flex;
          flex-direction: column;
          align-content: center;
          align-items: center;
          justify-content: center;
        }


        div.progress_overlay > div.progress_block {
          width: 50%;
          margin: 20px;
        }

        div.progress_block > span {
          margin-bottom: 12px;
          color: var(--paper-grey-600);
        }

        span.progress_title{
          text-transform: uppercase;

        }
        span.progress_numbers {
          float: right;
          }
          /*
        span.progress_numbers:before { content: '(' }
        span.progress_numbers:after { content: ')' }
        */


        span.editicon {
          opacity: 0;
          font-family: "Material Icons";
          position: absolute;
          top: 4px;
          left: -20px;
        }

        div.match_info:hover span.editicon {
          opacity: 1;
        }

        mwc-dialog.unit-picker-dialog {
          --mdc-dialog-min-width: min(95vw, 1100px);
          --mdc-dialog-max-width: max(95vw, 1200px);
        }
        mwc-dialog.unit-picker-dialog mwc-select {
          width: 100%;
        }
        mwc-dialog.unit-picker-dialog th {
          background: none;
          color: var(--paper-grey-800);
          max-width: 250px;
        }
        mwc-dialog.unit-picker-dialog th:first-child {
          width: 100%;
        }
        mwc-dialog.unit-picker-dialog tr {
          border: none;
        }
        mwc-dialog.unit-picker-dialog tr[selection] {
        }
        mwc-dialog.unit-picker-dialog tr:hover {
          background-color: var(--paper-cyan-100);
        }
        mwc-dialog.unit-picker-dialog td {
          opacity: 1;
          color: var(--paper-grey-900);
          font-weight: 800;
          max-width: 250px;
        }
        mwc-dialog.unit-picker-dialog td.unit {
          /* min-width: 300px;
          display: inline-block;
          */
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
        }
        mwc-dialog.unit-picker-dialog td.employer, mwc-dialog.unit-picker-dialog th.employer {
          min-width: 150px;
        }
        span.match_name {
          white-space: break-spaces;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;

        }

        span.match_rating {
          font-weight: 100;
          font-size: 75%;
          background-color: var(--match-color);
          color: white;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 2px 4px;
          display: inline-flex;
          align-items: center;
        }
        span.nomatch {
          text-transform: uppercase;
          background-color: var(--match-color);
          color: white;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 2px 4px;
          display: inline-block;
        }
        span.has_conflict {
          font-size: 75%;
          text-transform: uppercase;
          background-color: var(--paper-yellow-600);
          color: white;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 2px 4px;
          display: inline-block;
        }
        span.conflict_icon {
          font-family: "Material Icons";
          display: inline-block;
        }

        mwc-icon.conflict_warning {
          font-size: 14px;
          color: var(--paper-yellow-600);
        }



`;




class ImportPage extends MDuesPage {
  static styles = [super.styles, import_export_page_styles]
  static icon = "cloud_upload"
  static default_title = "Import Data"

  get form_name() { return "Import" }

  constructor() {
    super();
    this.title = "Import";
    this.build_cache = debounce(this.build_cache_debounced.bind(this), 250);
    this.requestValidate = debounce(this.requestValidateDebounced.bind(this), 250);
    this.files = [];
    this.data = null;
    this.selected = new Set();
    this.select_all = false;
    this.filter = { ignored: true, imported: true, good_match: false, poor_match: false };
    this.match_threshold = 0.7;
    this.threshold_good = 0.85;
    this.threshold_poor = 0.5;
    this._display_limit = null;
    this.render_count = 1;
    this.XLSXworker = Comlink.wrap(new Worker(new URL('/src/components/import-worker.js', import.meta.url), { type: 'module' }));
    this.ValidateWorker = Comlink.wrap(new Worker(new URL('/src/components/validation-worker.js', import.meta.url), { type: 'module' }));
    this.import_count = 0;
    this.pending_searches = [];
    this.search_received = new Set();
    this._sort = [{ col: 'row_number', dir: 1 }];
    this.queue = [];
    this.pqueue = [];
    this.processing_queue = null;
  }

  handleRowClick(e, row) {
    if (!this.rowSelectable(row)) {
      e.stopPropagation();
    } else {
      this.toggleSelected(row.row_id, row);
      if (e.shiftKey && this.last_selected) {
        let last = this.data.filtered.findIndex(f => f.row_id === this.last_selected);
        let current = this.data.filtered.findIndex(f => f.row_id === row.row_id);
        if (last >= 0 && current >= 0) {
          let start = last > current ? current : last;
          let end = last > current ? last : current;
          this.data.filtered.slice(start,end).filter(f => f.row_id !== this.last_selected).forEach(f => this.toggleSelected(f.row_id, f));
        }
      }
      this.last_selected = row.row_id;
    }
  }

  /*
  renderSimpleCell(celltype, value) {
      switch (celltype) {
                case "text":
                  return html`<td simple class="textcell"><span class="preview">${value}</span></td>`
                  break;
                case "contact":
                  return html`<td simple class="contactcell"><span class="preview">${['name', 'email', 'phone'].map(m => value ? value[m] : '').join('\n')}</span></td>`
                  break;
                case "date":
                  return html`<td simple class="datecell"><span class="preview">${value !== null ? (new Date(value))?.toLocaleDateString() : ''} </span></td>`;
                  break;
                case "number":
                  return html`<td simple class="numbercell"><span class="preview">${value !== null ? value?.toLocaleString() : 'no number'}</span></td>`
                  break;
                case "local":
                  return html`<td simple class="numbercell"><span class="preview">${value}</span></td>`
                  break;
                case "pct":
                  return html`<td simple class="numbercell"><span class="preview">${value !== null ? (value/100)?.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:3}) : ''}</span></td>`
                  break;
                case "amt":
                  return html`<td simple class="amtcell"><span class="preview">${value !== null ? (value/100)?.toLocaleString(undefined,{style: 'currency', currency: 'USD'}) : ''}</span></td>`
                  break;
                case "bool":
                  return html`<td simple class="boolcell"><span class="preview"> ${value ? 'X' : ''}</span></td>`;
                  break;
                default:
                  return html`<td simple class="textcell"><span class="preview">${value}</span></td>`
                  break;
              }
  }
  */

  renderCell(celltype, value, errors, match, title, col){
    let text;
    switch (celltype) {
      case "text":
        return html`
                            <td class="textcell" column=${col} ?errors=${errors} match=${match} title=${title || value || '[none]'}> 
                              <div><span class="overflow_ellipsis">${value}</span></div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "local":
        return html`
                            <td class="numbercell" column=${col} ?errors=${errors} match=${match} title=${title || value || '[none]'}> 
                              <div>${value}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "contact":
        let {name, email, phone} = value;
        return html`
                            <td class="contactcell" column=${col} ?errors=${errors} match=${match} title=${`${name}\n${email}\n${phone}`} >
                              <div class="contactname">${name}</div>
                              <div class="contactemail">${email?.split('@').map((e,i) => html`${e}${i ===0? '@':''}&#8203;`)}</div>
                              <div class="contactphone">${phone}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "date":
        let d = value && dayjs(value);
        text = d?.format('MM-DD-YY') || '';
        title = d?.format('MM-DD-YYYY') || value || '[none]';
        return html`
                            <td class="datecell" column=${col} ?errors=${errors} match=${match} title=${title}>
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "number":
        value = value?.toLocaleString?.();
        text = value || '';
        title = value || '[none]';
        return html`
                            <td class="numbercell" column=${col} ?errors=${errors} match=${match} title=${title} > 
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "pct":
        value = typeof(value) === 'string' ? value : value !== null ?  (value/100)?.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:3}) : undefined;
        text = value || '';
        title = value || '[none]';
        return html`
                            <td class="numbercell" column=${col} ?errors=${errors} match=${match} title=${title} >
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "amt":
        value = value !== null ? value?.toLocaleString(undefined, { style: 'currency', currency: 'USD' }) : undefined;
        text = value || '';
        title = value || '[none]';
        return html`
                            <td class="amtcell" column=${col} ?errors=${errors} match=${match} title=${title || value || '[none]'} > 
                              <div>${text}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      case "bool":
        return html`
                            <td class="boolcell" column=${col} ?errors=${errors} match=${match} title=${title || value || '[none]'} >
                              <div>${value ? html`<mwc-icon>check</mwc-icon>` : ''}</div>
                              ${formatErrors(errors)}
                            </td>`;
        break;
      default:
        return html`<td>cannot format ${header.type}</td>`
        break;
    }
  }
  matchInfo(row, index) {
    //console.log("MATCHINFO", row, index);
    let {match_type, matched_master, unit} = row;
    let match_id = row?.unit?.id ?? row?.matched_master?.id;

    let selected_match = row?.match_options?.find(o => match_id === o?.id);
    let rating = row.match_map.get(selected_match?.id)?.match_score;
    let conflicts = this?.data?.unit_ids?.get?.(selected_match?.id)?.length > 1;
    if (row && conflicts) row.had_conflict = true;
    //console.log("SELECTED MATCH=", selected_match, rating);
    return html`
    ${selected_match ? html`
      ${selected_match?.all_agreements?.some(a => a?.agreement?.master) ? '[MASTER] ': ''}
      <span class="match_name">${selected_match?.name}</span>
      <span class="match_rating">${rating?.toLocaleString?.(undefined,{style: 'percent', minimumFractionDigits:0})}
      ${conflicts ? html`<mwc-icon class="conflict_warning" title="other rows also upload to this unit">warning</mwc-icon>` : nothing}
      </span>
      ${conflicts && false ? html`<span class="has_conflict">conflicts<span class="conflict_icon">warning</span></span>` : nothing}
      <span class="editicon">edit</span>`
      : row?.new_unit ? html`<span class="new_unit">new unit</span>` : 
        (row?.match_options && row?.match_options?.some(m => m)) ? html`<span class="searching">searching&hellip;</span>` :
           html`<span class="nomatch">no match</span>`
    }
    `
                     //(${row.match_map.get(selected_match?.id)?.match_score?.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})})
  }

  row_has_dups(row) {
    const dups = this?.data?.unit_ids.get(row?.unit?.id);
    return  dups?.length > 1;
  }


  set selector_dialog(row) {
    const dups = this?.data?.unit_ids.get(row?.unit?.id);
    const dup = dups?.length > 1;
    if (row) row.had_conflict = dup;

    //console.log("ALL DUPS", Array.from(this?.data?.unit_ids.values()).filter(v => v.length > 1));
    //console.log("unit_ids", this?.data?.unit_ids);
    //console.log("data", this?.data?.data);

    if (row) row.epoch += 1;
    if (this?._selector_dialog?.rows) this._selector_dialog.rows.forEach(r => r.epoch += 1);



    this._selector_dialog = row ? {
      rows: dups?.length > 1 ? dups : [row],
      selected_row: row,
      rowset: new Set((dups?.length > 1 ? dups : [row]).map(r => r.sheet_id)),
      dup
    } : null;

    console.log("SELECTOR DIALOG =>", this._selector_dialog);
    this.requestUpdate('selector_dialog');
    this.requestUpdate('data');
  }

  add_selector_rows(new_rows) {
    if (!this.selector_dialog) return;
    let {rows, rowset} = this._selector_dialog;
    if (new_rows.some(r => rowset.has(r.sheet_id))) {
      new_rows.forEach(r => {
        if (!rowset.has(r.sheet_id)) {
          rows = [...rows, r];
          rowset.add(r.sheet_id);
        }
      })
      this._selector_dialog.rows = rows;
      this._selector_dialog.rowset = rowset;
    }
    this.requestUpdate('selector_dialog');

    /*
    if (this.selector_dialog && (this.selector_dialog?.rows?.find(r => r?.unit?.id == old_id || r?.unit?.id == new_id)) ) {
      let prior_ids = new Set(this._selector_dialog.rows.filter(r => r.unit?.id).map(r => r.unit?.id));
      let extra_rows = [...new_conflicts, ...old_conflicts].filter(c => !prior_ids.has(c?.unit?.id));
      this._selector_dialog.rows = [ ...this._selector_dialog.rows, ...extra_rows];
      console.warn("NEW CONFLICTS", this.selector_dialog, extra_rows);
    }
    */

  }
  get selector_dialog() { return this._selector_dialog }

  renderMatchDialog() {
    if (!this.selector_dialog) {
      console.warn('nothing to show');
      return nothing;
    }
    const { selected_row, rows, options, dup, selected_option_value} = this.selector_dialog;

    return html`
      <mwc-dialog class="unit-picker-dialog" ?open=${this.selector_dialog} @closed=${e => this.selector_dialog = null}}>
      <table>
        <thead>
          <th class="unit">import to&hellip;</th>
          <th class="employer">employer</th>
          <th>state</th>
          <th>council</th>
          <th>local</th>
          <th>subunit</th>
        </thead>
        <tbody>
        ${rows.map(row => {
          //const { options, selected_option_value } = this.match_options_for_row(row);
          const selected_option_value = (row?.match_options?.length > 0 && row?.match_options?.some(m => m)) ? row?.unit?.id : null;
          if (row.all_show) {
            console.warn("all show row", row.sheet_id, row.epoch, selected_option_value);
          }
          const is_selected = (row, o) => {
            /*
            if (row.all_show) {
              console.warn("all show row selected?", row.sheet_id, row.epoch, row?.unit?.id, o?.id, row?.unit?.id === o?.id);
            }*/
            return row?.unit?.id ===o?.id
          }

          
          return html`
          <tr ?selection=${row.sheet_id === selected_row.sheet_id}>
            <td class="unit">
            <div class="unit_select_holder">
              <mwc-select id=${`select::${row.sheet_id}`} fixedMenuPosition naturalMenuWidth outlined label=${row.match_type?.toUpperCase?.()} @closed=${e => e.stopPropagation()} ?duplicate=${dup} style=${dup ? `--mdc-select-fill-color: ${this.data.unit_colors.get(row?.unit?.id)};` : ''} >
                ${ row?.match_options?.length > 0 && row?.match_options?.some(m => m) ?  html`${ repeat(row.match_options, o => o.id, o => 
                    html`
                      <mwc-list-item 
                      id=${`${row.sheet_id}::${o.id}${is_selected(row,o) ? '::selected' : ''}`}
                      value=${`${o?.id}`}
                      ?selected=${is_selected(row, o)}
                      @request-selected=${e => { if (row.match_type === 'master') {this.set_master_match(row, o);} else {this.set_unit_match(row, o);} }}>
                        ${o?.all_agreements?.some(a => a?.agreement?.master) ? '[IN MASTER] ' : ''}${o.name} ${o.subunit ? `[subunit ${o.subunit}] `: ''}(${row.match_map.get(o.id).match_score.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})})
                      </mwc-list-item>
                    `)}` 
                  : html`<mwc-list-item ?selected=${!row?.new_unit} disabled value=${null}>no match</mwc-list-item>`}
                ${ row.match_options.length < row.all_match_options?.length ? 
                  html`<mwc-list-item value=${'more'}
                    @request-selected=${async e => { 
                      if (row.all_show) return;
                      row.match_options = row.all_match_options.sort((a,b) => row.match_map.get(b.id).match_score - row.match_map.get(a.id).match_score);
                      row.epoch += 1;
                      row.all_show = true;
                      //console.log("SHOWING ALL FOR", row);
                      this.requestUpdate('data');
                      // FIXME: super hacky, but can't figure out why ?selected attrib. on list-item gets unset from this
                      if(row?.unit?.id) {
                        await this.updateComplete;
                        this.renderRoot.getElementById(`${row.sheet_id}::${row.unit.id}::selected`)?.setAttribute?.('selected', true);
                        await this.updateComplete;
                        this.renderRoot.getElementById(`select::${row.sheet_id}`).menuOpen = true;
                      }
                    }}><em>[ show all ${row.all_match_options.length} options&hellip; ]&nbsp;</em>
                </mwc-list-item>` : nothing }
                ${
                  row.match_type === 'unit' ?
                html`
                <mwc-list-item ?selected=${row?.new_unit} value=${'new'} @request-selected=${e => this.set_new_unit(row)}><em>[ create new matching unit ]&nbsp;</em></mwc-list-item>
                `
                  : nothing
                }

              </mwc-select>
            </div>
            </td>
            <td class="employer">${row.employer}</td>
            <td>${row.state}</td>
            <td>${row.council}</td>
            <td>${row.local}</td>
            <td>${row.subunit}</td>
          </tr>
        `;
      })}
        </tbody>
      </table>
      
              <mwc-button slot="primaryAction" dialogAction="discard" @click=${e => this.selector_dialog=null}>close</mwc-button>
      </mwc-dialog>
    `;

  }



  /*
  matchSelectorOld(row) {
    //if (row.employer === 'Niagara, County of') console.warn("RENDERING MATCHER", row.employer, row.epoch, row?.match_options?.length);
    let options;
    let selected_option_value;
    if (row?.match_options?.length > 0 && row?.match_options?.some(m => m)) {
      options = html`
          ${
          //repeat(row.match_options.sort((a,b) => b.match_score - a.match_score), o => `${row?.match_options?.length || 0}::${o.id}::${o.match_score}::${o.id === row?.unit?.id}`, o =>  
            row.match_options.map(o => 
                html`
                  <mwc-list-item 
                  value=${o?.id}
                  ?selected=${row?.unit?.id === o?.id}
                  @request-selected=${e => { this.set_unit_match(row, o); }}>
                     ${o?.all_agreements?.some(a => a?.agreement?.master) ? '[IN MASTER] ' : ''}${o.name} (${row.match_map.get(o.id).match_score.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})})
                  </mwc-list-item>
                `)
          }
      `
      selected_option_value = row?.unit?.id;
    } else {
      options = html`<mwc-list-item ?selected=${!row?.new_unit} disabled value=${null}>no match</mwc-list-item>`
      selected_option_value = null;
    }
    const dup = this?.data?.unit_ids.get(row?.unit?.id) > 1;
    return html`
              <mwc-select label="Unit" value=${selected_option_value} ?duplicate=${dup} style=${dup ? `--mdc-select-fill-color: ${this.data.unit_colors.get(row?.unit?.id)};` : ''} >
                ${options}
                ${ !this.rowHasErrors(row) && row.match_options.length < row.all_match_options?.length ?  html`<mwc-list-item value=${'more'} @request-selected=${e => { 
                  row.match_options = row.all_match_options.sort((a,b) => row.match_map.get(b.id).match_score - row.match_map.get(a.id).match_score);
                  row.epoch += 1;
                  this.requestUpdate('data');
                }}><em>[ show all ${row.all_match_options.length} options&hellip; ]&nbsp;</em></mwc-list-item>` : nothing }
                ${ !this.rowHasErrors(row) ?  html`<mwc-list-item ?selected=${row?.new_unit} value=${'new'} @request-selected=${e => this.set_new_unit(row)}><em>[ create new unit ]&nbsp;</em></mwc-list-item>` : nothing }
              </mwc-select>
        `
  }
*/


  renderRow(row, i) {
    let selectable = this.rowSelectable(row);
    let selected = this.selected.has(row.row_id);
    let imported = row.status === 'imported' || (row.matching_increases && row.matching_increases.length > 0);
    let has_errors = this.rowHasErrors(row);
    let in_progress = row.in_progress;
    let unit_select = this?.selector_dialog?.rowset?.has?.(row.sheet_id);

    return html`                            
      <tr 
      id=${row.row_id}
      rowindex=${i}
      sheetid=${row.sheet_id}
      ?imported=${imported}
      ?selected=${selected}
      @click=${e => { console.log("rowclick", e); this.handleRowClick(e, row) }}
      ?errors=${has_errors}
      ?validated=${row.validated}
      ?onscreen=${row.onscreen}
      ?inprogress=${row.in_progress}
      ?unitdialogopen=${unit_select}
    >
        <td>
        <div>${row.row_number}</div>
        ${row.upload_errors ? html`<div class="upload_error_count">upload failed: ${row.upload_errors.length}</div>` : ''}
        </td>
        <td class="check_status">
          ${selectable ?  html`<mwc-checkbox ?disabled=${!selectable || this.mdp?.locked} @click=${e => { e.stopPropagation(); this.handleRowClick(e, row); }} .checked=${selected}> </mwc-checkbox>`
            : html`<div class="non_checkable" ?imported=${imported} ?errors=${has_errors}>${
            imported ? html`<mwc-icon>check</mwc-icon><span class="imported">imported</span>`
            : has_errors ? html`<mwc-icon>error</mwc-icon><span class="errors">invalid</span>`
            : in_progress ? html`<mwc-icon>pending</mwc-icon><span class="errors">importing</span>`
            : nothing}</div>` }
        </td>
        <td  title=${match_info_text(row)} class="textcell match" match=${row?.in_progress ? 'sending' : row?.new_unit ? 'new' : row.match_score > this.threshold_good ? "good" : row.match_score > this.threshold_poor ? "poor" : "bad"}>
          ${row.match_pending ? html`` : html`
              <div class="match_info" @click=${e => {e.stopPropagation(); console.log("MATCH CLICK", row); this.selector_dialog = row;}}>
                ${this.matchInfo(row, i)}
              </div>
          `}
        </td>
        ${ this.header.filter(h => !h.ignore).map(header => {
              let value = row[header.short];
              let errors = row.errors[header.short];
              errors = errors && errors.length > 0 ? errors : null;
              let match = cell_match(row, header);
              let title = cell_match_title_info(row, header);
              return this.renderCell(header.type, value, errors, match, title, header.short);
              //this.renderSimpleCell(header.type, row[header.short])
          })}
        </tr>
  `
  }

  /*
            <div class="preview_holder"><span class="preview circle"></span><span class="preview large">${row?.unit?.name}</span></div>
  renderOldRow(row, i) {
    return html`                            
      <tr 
      id=${row.row_id}
      rowindex=${i}
      sheetid=${row.sheet_id}
      ?imported=${row.status === 'imported' || (row.matching_increases && row.matching_increases.length > 0)}
      ?selected=${this.selected.has(row.row_id)}
      @click=${e => { this.handleRowClick(e, row) }}
      ?errors=${this.rowHasErrors(row)}
      ?validated=${row.validated}
      ?onscreen=${row.onscreen}
      ?inprogress=${row.in_progress}
      .data=${row}
    >
        <td><div>${row.row_number}</div>${row.upload_errors ? html`<div class="upload_error_count">upload failed: ${row.upload_errors.length}</div>` : ''}</td>
        ${
          cache(
          row.onscreen ? html`
        <td>
            <mwc-checkbox ?disabled=${!this.rowSelectable(row)} @click=${e => { e.stopPropagation(); this.handleRowClick(e, row); }} .checked=${this.selected.has(row.row_id)}> </mwc-checkbox> 
        </td>
        <td class="match numbercell" match=${row?.in_progress ? 'sending' : row?.new_unit ? 'new' : row.match_score > this.threshold_good ? "good" : row.match_score > this.threshold_poor ? "poor" : "bad"}>
          ${row.match_pending ? html`
            <div class="preview_holder"><span class="preview circle"></span><span class="preview large">${row?.unit?.name}</span></div>
          ` : html`
            <div class="match_info" >
              <div class="gauge" >
                <span>${
                  row?.in_progress ? html`<mwc-icon>save</mwc-icon>` :
                  row?.new_unit ? 'NEW' :
                  row.matching_increases && row.matching_increases.length > 0 ? "IMPORTED" :
                  row.match_score ? Math.round(row.match_score * 100) + '%' :
                  row.match_failed ? 'no match' : 
                  row?.in_master ? 'MASTER' : '' }
                </span>
                <svg class="match_circ"
                  match=${ row?.in_progress ? 'sending' : row?.new_unit ? 'new' : row.match_score > this.threshold_good ? "good" : row.match_score > this.threshold_poor ? "poor" : "bad"}
                  width="48" height="48" viewBox="0 0 100 100"> 
                  <path d=${arcDraw(50, 50, 50, row?.in_progress ? (row.match_score < 1 && row.match_score > 0 ? row.match_score :  ((Math.random()/2.0)+0.5)) : row.match_score)} />
                  <circle cx="50" cy="50" r="50"/>
                </svg> 
              </div>
              <div class="match_picker" @click=${e => {e.stopPropagation(); this.renderRoot.querySelectorAll('mwc-select').forEach(s => s.menuOpen = false);}}>
                ${this.matchSelector(row)}
              </div>
            </div>
          `}
        </td>
        ` : html`<td><span class="preview checkbox">X</span></td>
                  <td><div class="preview_holder"><span class="preview circle"></span><span class="preview large">${row?.unit?.name}</span></div></td>
                ` 
                )
              }
        ${ 
          cache(
            row.onscreen ? 
            this.header.filter(h => !h.ignore).map(header => {
              let value = row[header.short];
              let errors = row.errors[header.short];
              errors = errors && errors.length > 0 ? errors : null;
              let match = cell_match(row, header);
              let title = cell_title(row, header);
              return this.renderCell(header.type, value, errors, match, title);
          })
            : this.header.filter(h => !h.ignore).map(header => this.renderSimpleCell(header.type, row[header.short]))
          )
          //!row.onscreen ? html`<td colspan=${this.header.filter(h => !h.ignore).length}>loading...</td>` :
          }
        </tr>
  `
  }
  */

  //<mwc-icon style="position:relative; bottom:4px; opacity: 0.5;" ?sort=${this.sort.find(s => s.col === 'selected')}>${this.sort.find(s => s.col === 'selected' && s.dir < 0) ? 'arrow_downward' : 'arrow_upward'}</mwc-icon>
  paginated = false;
  renderTable(top_item, last_item, bottom_item) {
    //console.log("render table", this?.data && this?.data?.filtered, this.data?.filtered?.length);
    const sort_icon = (fieldname) => html`<mwc-icon ?sort=${this.sort.find(s => s.col === fieldname)} ?reverse=${this.sort.find(s => s.col === fieldname && s.dir == 1)}>sort</mwc-icon>`;
    const filter_icon = (fieldname) => html`<mwc-icon ?filtered=${this.is_field_filtered(fieldname)}>filter_alt</mwc-icon>`;
    const filter_menu = (fieldname) => {
      //console.warn("FILTER MENU CHECK", this.filter_menu === fieldname, fieldname, this.filter_menu);
      return this.filter_menu === fieldname ? this.renderFilterMenu(fieldname) : nothing;
    }
    return html`
                          <table>
                            <thead>
                              <tr id="file_header" onscreen>
                                <th class="control" @click=${e => this.toggle_sort('row_number')}>
                                    ${sort_icon('row_number')}
                                    ${filter_icon('row_number')}
                                </th>
                                <th class="control" @click=${e => this.toggle_sort('selected')} @mouseenter=${e => this.set_filter_menu('selected')} @mouseleave=${e => this.set_filter_menu(null)} >
                                  <div style="position: relative; left: -6px;">
                                    <mwc-checkbox @click=${e => { e.stopPropagation(); this.toggleAll() }} .checked=${this.select_all}></mwc-checkbox>
                                    ${filter_icon('selected')}
                                  </div>
                                    ${this.renderFilterMenu('selected')}
                                </th>
                                <th id='match_th' type="match" @mouseenter=${e => this.set_filter_menu('match')} @mouseleave=${e => this.set_filter_menu(null)} @click=${e => this.toggle_sort('match')}>
                                  <div>
                                    <span>Target</span>
                                    ${sort_icon('match')}
                                    ${filter_icon('match')}
                                  </div>
                                    ${this.renderFilterMenu('match')}
                                </th>
                                ${this.header.filter(h => !h.ignore).map(h => html`
                                <th 
                                    type=${h.type}
                                    @click=${e => this.toggle_sort(h.short)} 
                                    @mouseenter=${e => this.set_filter_menu(h.short)}
                                    @mouseleave=${e => this.set_filter_menu(null)} 
                                    title=${h.long}
                                  >
                                  <div>
                                    <span>${h.long_func ? h.long_func(this.data) : h.disp || h.short.replace(/_/g, ' ')}</span>
                                    ${sort_icon(h.short)}
                                    ${filter_icon(h.short)}
                                  </div>
                                  ${this.renderFilterMenu(h.short)}
                                </th>
                                `)}
                              </tr>
                            </thead>
                            <tbody id="file_data">
                            ${ repeat((this.data && this.data.filtered ? this.data.filtered : []), row => row.row_number, (row, i) => 
                              guard([this.import_count, row.epoch],() => this.renderRow(row, i))
                            )}
                            </tbody>
                          </table>
            `;
  }


  filter_enabled_cols = new Set()
  filter = [ ]
  include_filter = []

  toggle_enable_filter(col) {
    console.log("TOGGLE ENABLE", col);
    if (this.filter_enabled_cols.has(col)) {
      this.filter_enabled_cols.delete(col);
      this.filter = this.filter.filter(f => f.col !== col);
    } else {
      this.filter_enabled_cols.add(col)
      this.filter = [...this.filter, ...this.available_filters[col].map(f => ({col, filter: f}))]
    }
    console.log("TOGGLE ENABLE", [...this.filter])
    this.requestUpdate('filter')
    this.build_cache();
  }

  is_row_filtered(row) {
    //console.log("ALWAYS", this.filter.filter(({filter}) => filter.always));
    return this.include_filter.every(({filter}) => filter.func(row)) && this.filter.every(({filter}) => !filter.func(row))// || this.filter.filter(({filter}) => filter.always).some(({filter}) => !filter.func(row));
  }

  is_field_filtered(field) {
    return this.filter.find(f => f.col === field) || this.include_filter.find(f => f.col === field);
  }
  is_filter_enabled(field, filter) {
    return this.filter.find(f => f.col === field && f.filter.name === filter.name);
  }
  is_include_filter_enabled(field, filter) {
    return this.include_filter.find(f => f.col === field && f.filter.name === filter.name);
  }

  toggleFilter(field, filter) {
    if (this.is_filter_enabled(field, filter)) {
      this.filter = this.filter.filter(f => !(f.col === field && f.filter.name === filter.name));
      if (!this.filter.some(f => f.col === field)) {
        this.filter_enabled_cols.delete(field);
      }
    } else {
      this.filter = [...this.filter, { col: field, filter}];
      this.filter_enabled_cols.add(field);
    }
    console.log(this.filter);
    this.build_cache();
  }

  toggleIncludeFilter(field, filter){
    console.log("TOGGLING", field, filter.name);
    if (this.include_filter.find(f => f.col === field && f.filter.name === filter.name)) {
      this.include_filter = this.include_filter.filter(f => !(f.col === field && f.filter.name === filter.name));
    } else {
      this.include_filter = [...this.include_filter, { col: field, filter}];
    }
    console.log("INCLUDE NOW", [...this.include_filter]);
    this.requestUpdate('include_filter')
    this.build_cache();
  }

  set_filter_menu(field_name){
    //this.renderRoot.querySelectorAll('mwc-menu.filter_menu').forEach(m => { if (m.id !== `filter_menu_${field_name}`) m.open = false });
    if (this.filter_menu !== field_name) {
      let old = this.filter_menu;
      this.filter_menu = field_name;
      this.requestUpdate("filter_menu", old);
    }
    //await this.updateComplete;
    //this.renderRoot.querySelectorAll('mwc-menu.filter_menu').forEach(m => { if (m.id !== `filter_menu_${field_name}`) m.open = false });
    //this.renderRoot.getElementById(`filter_menu_${field_name}`).open = true;
  }

  /*
  match_threshold = 0.7;
  threshold_good = 0.85;
  threshold_poor = 0.5;
  */
  available_exclude_filters = {
    match: [
      {name: "good", func: r => r.match_score >= this.threshold_good },
      {name: "poor", func: r => r.match_score < this.threshold_good && r.match_score >= this.threshold_poor},
      {name: "bad", func: r => r.match_score < this.threshold_poor },
      {name: "no match", func: r => (!r.all_match_options || r.all_match_options.length === 0)},
      {name: "new", func: r => r.new_unit },
    ],
    selected: [
      {name: 'checked', func: row => this.selected.has(row.row_id)},
      {name: 'uploaded', func: row => row.status === 'imported' || (row.matching_increases && row.matching_increases.length > 0) },
      {name: 'unchecked', func: row => this.rowSelectable(row) },
      {name: 'invalid', func: row => this.rowHasErrors(row) }
    ],

  }
  available_include_filters = {
    match: [
      {name: "conflicts only", func: r => r.had_conflict || this.row_has_dups(r) },
    ],
    comments: [
      {name: "has comment", func: r => r.comments && r.comments !== '' },
    ],
    negotiations: [
      {name: "in negotiation", func: r => r.negotiations },
    ]
  }

  renderFilterMenu(field_name) {
    // toggle_sort(field_name)
    let excludes = this.available_exclude_filters[field_name];
    let includes = this.available_include_filters[field_name];

    return this.filter_menu === field_name && (excludes || includes) ? html`
    <div class="filter_menu_container">
      <mwc-menu class="filter_menu" id=${`filter_menu_${field_name}`} ?open=${this.filter_menu === field_name} @closed=${e => this.set_filter_menu(null)}>
        ${excludes ? html`
        <mwc-list-item>
          <div class="filter_item filter_all">
            <mwc-checkbox @click=${e => { e.stopPropagation(); this.toggle_enable_filter(field_name) }} .checked=${!this.filter_enabled_cols.has(field_name)}></mwc-checkbox>
            all rows
          </div>
        </mwc-list-item>
        <li divider role="separator"></li>

        ${excludes.map(filter => html`
        <mwc-list-item>
          <div class="filter_item">
            <mwc-checkbox
            @click=${e => { e.stopPropagation(); this.toggleFilter(field_name, filter) }}
            .checked=${!this.filter_enabled_cols.has(field_name) || !this.is_filter_enabled(field_name, filter)}
            ></mwc-checkbox>
            ${filter.name}
          </div>
        </mwc-list-item>
        `)}
        ` : nothing}
        ${excludes && includes ? html`<li divider role="separator"></li>` : nothing}
        ${includes ? html`
        ${includes.map(filter => html`
        <mwc-list-item>
          <div class="filter_item">
            <mwc-checkbox
            @click=${e => { e.stopPropagation(); this.toggleIncludeFilter(field_name, filter) }}
            .checked=${this.is_include_filter_enabled(field_name, filter)}
            ></mwc-checkbox>
            ${filter.name}
          </div>
        </mwc-list-item>
        `)}
        ` : nothing}
      </mwc-menu>
    </div>` : nothing;

  }
  renderExtraItems() {
    return html`
                  <div class="buttons" slot="actionItems">
                      <mwc-button ?disabled=${this.mdues_period?.locked} slot="actionItems" id="upload_button" icon="file_upload" extended label=${this.data ? 'new file' : 'upload file'} @click=${e => { this.renderRoot.getElementById('file_chooser').click(); this.renderRoot.getElementById('upload_button').blur(); }}></mwc-button>
                      ${this.data ? html`<mwc-button raised ?disabled=${this.selected.size === 0} @click=${e => this.importSelected()}>${this.data ? `import${this.selected.size > 0 ? ` ${this.selected.size} row${this.selected.size > 1 ? 's' : ''}` : ''}` : nothing}</mwc-button>` : nothing}
                      ${this.import_rows_in_progress ? html`<span>${this.import_rows_in_progress} data points to upload&hellip;</span>` : ''}
                  </div>
    ${super.renderExtraItems()}
    `
  }

  renderControls() {
    
    return html`
                <div class="table_controls" ?selection=${this.selected.size > 0}>

                  ${this.paginated ? this.renderNav() : ''}



                  <h2>${this.data ? html`${this.data.source.name}` : nothing}</h2>
                </div>
                `
/*
                  (loaded ${this.data?.filtered?.filter(d => d.validated)?.length}/${this.data.data.length} rows${this.data.total_error_rows > 0 ? html`&mdash;<span class='error_count'>${this.data.total_error_rows} with errors</span>` : ''})` : 'unknown'}
    return html`
                <div class="table_controls" ?selection=${this.selected.size > 0}>
                ${this.selected.size > 0 ? html`<div class="selection_info">
                ${this.selected.size} item${this.selected.size === 1 ? '' : 's'} selected</div>` 
                : html`<h2>${this.data ? this.data.source.name : 'unknown'}</h2>`}
                  ${this.selected.size > 0 ? html` <div class="buttons">
                    <mwc-button raised @click=${e => this.importselected()}>import selected</mwc-button>
                </div>` : html``}
                </div>
                `
                */
  }

  renderNav() {
    let top_item = this.display_item + 1;
    let last_item = this.data && this.data.filtered ? this.data.filtered_length : 'unknown';
    let bottom_item = Math.min(this.display_item + this.display_limit, last_item);
    return html`
                <div id="navigation">
                  <div id="navigation-info">${top_item}-${bottom_item} of ${last_item}</div>
                  <mwc-icon-button ?disabled=${this.display_item <= 0} icon="chevron_left" @click=${e => this.display_item = this.display_item - this.display_limit}></mwc-icon-button>
                  <mwc-icon-button ?disabled=${this.display_item >= (this.data.filtered_length - (this.data.filtered_length % this.display_limit))} icon="chevron_right" @click=${e => this.display_item = this.display_item + this.display_limit}></mwc-icon-button>
                </div>`;
  }
  renderPage() {
    this.render_count += 1;

    let top_item = this.display_item + 1;
    let last_item = this.data && this.data.filtered ? this.data.filtered_length : 'unknown';
    let bottom_item = Math.min(this.display_item + this.display_limit, last_item);

    //${this.renderControls()}
    return html`
            <div class="column" ?dragging=${this.dragging} ?data=${this.data && !this.dragging} id="drop-target" @drop=${e => { e.preventDefault(); this.dragDrop(e) }} @dragenter=${e => this.dragEnter(e)} @dragover=${e => this.dragEnter(e)} @dragleave=${e => this.dragLeave(e)} >
            ${true && this.data && this.data.type === 'mdues' ? html`
                  ${this.data && this.data.data ? html`
                    <div class="table-container" id="tablecontainer">
                        ${!this?.progress?.complete ? this.renderProgress(this.progress) : nothing}
                        <div class="table-scroller" >
                          ${this?.progress?.complete ? this.renderTable(top_item, last_item, bottom_item) : nothing}
                           ${ 
                             nothing
                             //(this.data && this.data.filtered && this.data.filtered.some(d => !d.validated)) ? 
                            //html`<progress-circle class="progress_centered" .status=${'animate'} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>`
                            //this.renderProgress(this.progress)
                            }
                        </div>
                      </div>
                  ` : html`` /*FULLTIME
                        <ul> ${this?.data?.filtered?.map(d => html`<li>${d.employer}/${d?.unit?.name}</li>`)} </ul>
                               <td class="remove"><mwc-icon-button @click=${e => this.deleteContrib(c)} icon="delete"></mwc-icon-button></td>
                  */} 

            ` : html`

              <div class="drag_placeholder">
            ${(this.data && (this.data.type === 'loading' || (this.queue && this.queue.length > 0))) ? html`
              <progress-circle style="--progress-color: white; --progress-bg: var(--paper-grey-700); --progress-size: 128;" .status=${'animate'} .icon_incomplete=${""} .icon_complete=${""}></progress-circle>
              ` : html`
            ${ this.data && (this.data.type === 'unknown' || this.data.type === 'error') ? html`
            <div class="load_message">${this.data.message}</div>
            ` : ``}
            ${ this.mdues_period?.locked ? html`<div>PERIOD IS LOCKED <mwc-icon>lock</mwc-icon></div>` : html`<div>DROP FILES TO UPLOAD</div>`}
              
              `}
            </div>
            `} 
        </div>
        ${this.selector_dialog ? this.renderMatchDialog() : nothing}
    <input
        id="file_chooser"
        type="file"
        multiple="false"
        accept=".xlsx"
        @change=${e => { console.log("file chooser change", e); for (const f of e.target.files) { console.log("adding a file"); this.addFile(f); this.renderRoot.getElementById('file_chooser').value = null; } }}
        hidden>
        `;
  }
            //<mwc-fab class="pink" id="upload_button" icon="file_upload" extended label="Open File" @click=${e => { this.renderRoot.getElementById('file_chooser').click(); this.renderRoot.getElementById('upload_button').blur(); }}></mwc-fab>

  get progress() {
    return this._progress;
  }

  set progress(p) {
    if (this._progress !== p) {
      const old = this._progress;
      this._progress = {...p, complete: old?.complete};
      this.requestUpdate('progress');
      if (p?.complete !== old?.complete) {
        (async () => {
          //this._progress.awaiting_complete = true;
          await this.updateComplete;
          await wait(500);
          this._progress.complete = p.complete;
          //this._progress.awaiting_complete = false;
          this.requestUpdate('progress');
        })();
      }
    }
  }

  renderProgress(progress) {
    const {bytes_read, processed, validated, matched, awaiting_complete} = progress;
    const blocks = [
      {title: "reading file", unit: 'bytes', data: bytes_read},
      {title: "processing data", unit: 'rows', data: processed},
      {title: "validating", unit: 'rows', data: validated},
      {title: "matching", unit: 'rows', data: matched}
    ]
    return html`
      <div class="progress_scrim"></div>
      <div class="progress_overlay">
      ${blocks.map(({title, unit, data: {finished, total, partial} = {finished:0, total:0, partial: 0}}) => html`
        <div class="progress_block">
          <span class="progress_title">${title}</span>
          <span class="progress_numbers">${finished}/${total} ${unit}</span>
          <mwc-linear-progress progress=${finished/total} buffer=${1 - (partial/total)}></mwc-linear-progress>
        </div>
      `)}
      ${awaiting_complete ? html`<div><mwc-icon>check</mwc-icon></div>` : nothing}
      </div>`
  }

  async fetchMatches() {
    let merged_search = new Map();
    let visible = this.data?.data?.filter?.((r) => this.visible(r));
    this.data = {...this.data, total_rows: visible.length, validated_rows: new Set(), sent_rows: new Set(), received_rows: new Set()};
    visible.forEach(async r => {
      r.match_score = 0;
      r.match_pending = true;
      r.epoch += 1;

      console.log("COMPUTE KEY FOR", r);
      let key = `${r.state}::${r.council}::${r.local}::${r.master}`;
      console.log(key);
      if (!merged_search.has(key)) {
        merged_search.set(key, {
          params: { state: r.state, council: r.council, local: r.local, master: r.master },
          rows: [r]
        });
      } else {
        let e = merged_search.get(key);
        merged_search.set(key, { ...e, rows: [...e.rows, r]});
      }
    });
    console.log(`Merged ${visible.length} -> ${merged_search.size}`);
    merged_search.forEach((v, k) => this.queueWork(`do search (${v.rows.length} rows)`, null, () => this.doSearch(k, v.params, v.rows)));
  }

  
  set mdp(p) {
    super.mdp = p;
    if (this.data) {
      this.fetchMatches();
    }
  }
  get mdp() {
    return super.mdp;
  }


              //{_or: [{name: {_ilike: $filter}}, {aliases: {name: {_ilike: $filter}}}, {masters: {master: {name: {_ilike: $filter}}}}]},

              //{subunit: {_eq: $subunit}}

  async doSearch(key, params, rows) {
    console.log("SEARCH", key, rows.length);
    this.data.sent_rows = this?.data?.sent_rows || new Set();
    rows.forEach(row => {
      if (this?.data?.sent_rows?.has?.(row.sheet_id)) console.warn("row already sent", row.row_number, row.pending_requests + 1);
      this.data.sent_rows.add(row.sheet_id)
      row.pending_requests = row.pending_requests ? row.pending_requests + 1 : 1;
    });
    /*
    console.log({
      matched: {
        partial: this.data.sent_rows.size,
        finished: this.data.received_rows.size,
        total: this?.data?.total_rows
      }
    });*/
    this.progress = {
      ...this.progress,
      complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
      matched: {
        partial: this.data.sent_rows.size,
        finished: this.data.received_rows.size,
        total: this?.data?.total_rows
      }
    }
    params.resends = 0;
    let { state, council, local, master } = params;
    if (master) {
      await this.doMasterSearch(key, params, rows, state, council, local, master);
    } else {
      await this.doUnitSearch(key, params, rows, state, council, local);
    }
  }

  async doMasterSearch(key, params, rows, state, council, local, master) {
    const master_query = gql`
      query master_search($state: String, $period: Int){
        results:core_master(where: {_and: {period_id: {_eq: $period}, units: {unit: {state: {_eq: $state}}}}}) {
          name
          id
          report_individually
          period_id
          units {
            unit {
              id
              ...UnitFields
            }
          }
        }
      }
      ${unit_fields}
      `;

    const agreement_query = gql`
      query master_agreement_search($state: String, $period: Int){
        results:cached_agreement(where: {_and: {period_id: {_eq: $period}, master: {_eq: true}, units: {unit: {state: {_eq: $state}}}}}) {
          id
          name
          promoted
          master
          period_id
          increases {
            ...IncreaseFields
          }
          units {
            unit {
              ...UnitFields
            }
          }
        } 
      }
      ${unit_fields}
      ${increase_fields}
    `;

    client.query({
      fetchPolicy: 'network-only',
      query: agreement_query,
      variables: { state, period: get_current_period()?.id }
    })
      .then(data => {
        console.warn("GOT MASTER SEARCH DATA", data);
        if (data.data && data.data.results) {
          const d = data.data.results;
          rows.forEach(r => {
            r.match_type = 'master';
            console.log("QUEUING DATA FOR", r);
            console.log("DATA", d);
            this.queueWork(`new master data row #{r.row_number}: ${r.master}`, r, () => this.newMasterSearchData(key, r, d));
          });
        }
      })
      .catch(error => console.error(key, error));

    //this.pending_searches.push({row: row, args: args});
    await wait(2500);
    if (!this.search_received.has(key)) {
      params.resends += 1;
      if (params.resends < 2) {
        this.doSearch(key, params, rows);
      } else {
        console.warn("search timed out", params.resends, row_text);
        //this.validate_rows(rows);
        rows.forEach(r => this.validate(r));
      }
    }
  }

  async doUnitSearch(key, params, rows, state, council, local) {
    const unit_query = gql`
      query unit_search($state: String, $council: Int, $local: Int, $period: Int){
        results:core_unit(
          where: {
            _and: 
            [
              {state: {_ilike: $state}},
              {council: {_eq: $council}},
              {local: {_eq: $local}},
              {deprecated: {_eq: false}}
            ]
          }
          ){
            id
            ...UnitFields

            all_agreements(where: {agreement: {period_id: {_eq: $period}}}) {
              agreement {
                id
                name
                promoted
                master
                period_id
                increases {
                  ...IncreaseFields
                }
              }
            }
 
          }
        }
      ${unit_fields}
      ${increase_fields}
    `;

    client.query({
      fetchPolicy: 'network-only',
      query: unit_query,
      variables: { state, council, local, period: get_current_period()?.id }
    })
      .then(data => {
        if (data.data && data.data.results) {
          const d = data.data.results.map(r => ({
            ...r,
            match_unit: r.unit !== undefined && r.unit !== null && String(r.unit.trim()) !== '' ? r.unit.trim().toLowerCase() : null,
            current_agreement: r.all_agreements.filter(t => t?.agreement?.period_id === get_current_period()?.id)?.[0]
          }));
          rows.forEach(r => {
            r.match_type = 'unit';
            this.queueWork(`new unit data row #${r.row_number}: ${r.employer}`, r, () => this.newUnitSearchData(key, r, d));
          });
        }
      })
      .catch(error => console.error(key, error));

    //this.pending_searches.push({row: row, args: args});
    await wait(2500);
    if (!this.search_received.has(key)) {
      params.resends += 1;
      if (params.resends < 2) {
        this.doSearch(key, params, rows);
      } else {
        console.warn("search timed out", params.resends, row_text);
        //this.validate_rows(rows);
        rows.forEach(r => this.validate(r));
      }
    }
  }
  async newMasterSearchData(search_key, row, data) {
    this.search_received.add(search_key);
    if (data.length === 0) {
      console.warn("NO DATA FOUND FOR", search_key, row, data);
      row.no_local_council_data = true;
    }
    row.data_received = row.data_received ? row.data_received + 1 : 1;

    let match_text = row.master?.toLowerCase?.().trim?.();
    let match_map = new Map();
    let top_matches = [];
    let best_match, worst_match;

    data.forEach(d => {
      d.match_text = d.match_text ?? d.name?.toLowerCase?.()?.trim?.();
      let score = similarity(String(match_text), String(d.match_text));

      let m = {
        match_score: score,
        match_fields: {
          master: { val: score, comparing: [match_text, d.match_text] }
        }
      };
      match_map.set(d.id, m);

      if (!best_match || match_map.get(best_match.id).match_score < m.match_score) {
        best_match = d;
        top_matches.unshift(d);
        top_matches = top_matches.slice(0,5);// top_matches.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score);
      } else if (!worst_match || match_map.get(worst_match.id).match_score < m.match_score) {
        top_matches.push(d);
        top_matches = top_matches.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score).slice(0,5);
        worst_match = top_matches[top_matches.length - 1];
      } 
    });

    row.match_options = [best_match, ...top_matches.filter(m => m.id !== best_match.id)];
    row.all_match_options = data;//.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score);
    row.match_map = match_map;
    delete row.match_pending;
    row.epoch = row.epoch + 1;
    this.build_cache;
    // F this.queueWork(`set match ${row.row_number}: ${row.employer} => ${best_match?.name}`, row, () => this.set_unit_match(row, best_match));
    this.set_master_match(row, best_match);
  }

  async newUnitSearchData(search_key, row, data) {
    this.search_received.add(search_key);
    //console.log("NEW DATA", row.employer, row.sheet_id, "(total:)", this.search_received.size);
  
    if (data.length === 0) {
      console.warn("NO DATA FOUND FOR", search_key, row, data);
      row.no_local_council_data = true;
    }
    let best_match = null;
    let worst_match = null;
    //TODO: why was this using row.unit -- which AFAIK has never existed
    let match_unit = row?.subunit?.trim?.()?.toLowerCase?.();

    let match_map = new Map();
    let top_matches = [];

    row.data_received = row.data_received ? row.data_received + 1 : 1;
    data.forEach((d,i) => {
      
      let match_num = 0;
      let match_fields = {};
      let match_denom = 0;
      ['state', 'council', 'local'].forEach(l => {
        match_fields[l] = { val: row[l] === d[l], comparing: [row[l], d[l]] };
        if (match_fields[l].val !== 1) {
          row.epoch += 1;
          return;  // abort if any of these don't match
        }
      });

      // unit matching:
      let val = similarity(String(match_unit).trim().toLowerCase(), d.subunit?.trim?.()?.toLowerCase?.());
      match_fields.subunit = { val: val, comparing: [match_unit, d?.subunit?.toLowerCase?.()] };
      if (match_unit) {
        match_num += val;
        match_denom += 1;
      }

      // employer name matching:
      let similiarities = [d.name, ...d.aliases.map(a => a.name)].map(s => ({name: s, val: similarity(row.employer, s)})).sort((a,b) => b.val-a.val);
      let best = similiarities && similiarities.length > 0 ? similiarities[0] : null;
      match_fields.employer = { val: best ? best.val : 0, comparing: [row.employer, best ? best.name : null] };
      if (row.employer) {
        match_num += best ? best.val : 0;
        match_denom += 1;
      }
      //console.log(i, row.employer, "EMP MATCH", match_fields.employer, d);
        //console.log("SIMS", [d.name, ...d.aliases.map(a => a.name)].map(s => ({n1: row.employer, n2: s, similiar: similarity(row.employer, s)})).sort((a,b) => b.similar-a.similar));
        //&& (row.employer === d.name || (d.aliases && d.aliases.some(a => a.name === row.name)))) {
        //match_num += 1;
      let m = {
        match_score: match_num / match_denom,
        match_fields: match_fields
      };
      match_map.set(d.id, m);
      if (!best_match || match_map.get(best_match.id).match_score < m.match_score) {
        best_match = d;
        top_matches.unshift(d);
        top_matches = top_matches.slice(0,5);// top_matches.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score);
      } else if (!worst_match || match_map.get(worst_match.id).match_score < m.match_score) {
        top_matches.push(d);
        top_matches = top_matches.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score).slice(0,5);
        worst_match = top_matches[top_matches.length - 1];
      } 
    });
    
    row.match_options = [best_match, ...top_matches.filter(m => m.id !== best_match.id)];
    row.all_match_options = data;//.sort((a,b) => match_map.get(b.id).match_score - match_map.get(a.id).match_score);
    row.match_map = match_map;
    
    delete row.match_pending;
    row.epoch = row.epoch + 1;
    this.build_cache;
    // F this.queueWork(`set match ${row.row_number}: ${row.employer} => ${best_match?.name}`, row, () => this.set_unit_match(row, best_match));
    this.set_unit_match(row, best_match);
  }

  colidx = 0;
   
  update_unit_count(new_id, old_id) {
    const shades = [600, 400, 800, 700];
    const colors = ['yellow', 'orange', 'amber'];
    /*
    if (!this.data.unit_ids.has(id)) {
      this.data.unit_ids.set(id, inc && inc > 0 ? inc : 0);
    } else {
      this.data.unit_ids.set(id, this.data.unit_ids.get(id) + inc);
    }*/
    let old_prior = this.data?.unit_ids?.get?.(old_id);
    let new_prior = this.data?.unit_ids?.get?.(new_id);

    let old_conflicts = old_id ? this?.data?.data?.filter(r => r?.unit?.id === old_id) : [];
    let new_conflicts = new_id ? this?.data?.data?.filter(r => r?.unit?.id === new_id) : [];

    old_id && this.data.unit_ids.set(old_id, old_conflicts);
    new_id && this.data.unit_ids.set(new_id, new_conflicts);

    if (new_conflicts.length === 2 && !this.data.unit_colors.has(new_id)) {
      this.colidx += 1;
      let next_shade = shades[this.colidx % shades.length];
      let next_color = colors[this.colidx % colors.length];
      let color = `var(--paper-${next_color}-${next_shade})`
      //console.warn("COLOR", `${color}`);
      this.data.unit_colors.set(new_id, `${color}`);
    }

    new_conflicts.forEach(r => { r.epoch += 1; })
    old_conflicts.forEach(r => { r.epoch += 1; })

    this.add_selector_rows([...new_conflicts, ...old_conflicts]);
    this.requestUpdate('data');
  }

  get_unit_count(row) {
    return this.data.unit_ids.get(row?.unit?.id).length;
  }

  set_master_match(row, master_match) {
    if (row.match_type !== 'master') {
      console.error("Something went wrong: attempt to set master match on non-master row", row, match);
      return;
    }
    if (master_match) {
      let m = row.match_map.get(master_match.id);
      row.match_score = m.match_score;
      row.match_fields = m.match_fields;
      row.matched_master = master_match;
      this.set_agreement(row, master_match);
      row.row_id = `${row.sheet_id}::${master_match.id}`;
      this.matched_rows = [...this.matched_rows, row]
    } else {
      row.match_score = 0;
      row.match_fields = null;
      row.unit = null;
      row.row_id = `${row.sheet_id}::null`;
      this.unmatched_rows = [...this.unmatched_rows, row]
    }
    //this.update_unit_count(match?.id, row?.unit?.id);
    delete row.new_unit;
    row.epoch += 1;
    //if (this.data.received_rows.has(row.sheet_id)) console.warn("row already received", row.row_number, row);
    this.data.received_rows.add(row.sheet_id);
    this.build_cache();
  }
  set_unit_match(row, match) {
    if (row.match_type !== 'unit') {
      console.error("Something went wrong: attempt to set unit match on non-unit row", row, match);
      return;
    }

    let old_unit = row.unit;

    if (match) {
      let m = row.match_map.get(match.id);
      row.match = m.match_score;
      row.match_score = m.match_score;
      row.match_fields = m.match_fields;
      row.unit = match;
      this.set_agreement(row, match);
      row.row_id = `${row.sheet_id}::${match.id}`;
      this.matched_rows = [...this.matched_rows, row]
    } else {
      row.match_score = 0;
      row.match_fields = null;
      row.unit = null;
      row.row_id = `${row.sheet_id}::null`;
      this.unmatched_rows = [...this.unmatched_rows, row]
    }
    this.update_unit_count(row?.unit?.id, old_unit?.id);
    delete row.new_unit;
    row.epoch += 1;
    //if (this.data.received_rows.has(row.sheet_id)) console.warn("row already received", row.row_number, row);
    this.data.received_rows.add(row.sheet_id);
    this.build_cache();
  }
  matched_rows = []
  unmatched_rows = []

  async set_new_unit(row) {
    //console.log("setting to new unit", row);
    row.match_score = 2;
    row.match_fields = {};
    row.new_unit = true; //{new_unit: true};
    row.unit = null;
    row.row_id = `${row.sheet_id}::NEW`;
    row.epoch = row.epoch + 1;

    if (this.rowSelectable(row)) {
      this.selected.add(row.row_id);
      this.last_selected = row.row_id;
    }

    this.build_cache();
  }

  set_agreement(row, best_match) {
    //title = ${ row.match_fields && row.match_fields[header.short] ? `"${row.match_fields[header.short].comparing[1]}": ${Math.round(row.match_fields[header.short].val * 100)}%` : `${header.short}: no match` }

    const { members, annual, annual_base, cents_hourly, hourly_base, pct, agreement_effective, agreement_expires, effective, negotiations: in_negotiations=false } = row;
    const [type, value, base ] = pct !== null && pct !== undefined ? ['pct', pct, null] : (cents_hourly ? ['hourly', cents_hourly, hourly_base] : (annual ? ['annual', annual, annual_base] : ['pct', null, null]));

    //console.log("agreements:", best_match.all_agreements);
    const agreements = row.match_type === 'master' ? [{agreement: best_match}] : best_match.all_agreements.filter(t => t?.agreement?.period_id === get_current_period()?.id);
    if (agreements.length > 1){ //|| best_match.all_agreements.length > 1) {
      console.error("UNEXPECTED?: MORE THAN 1 MATCHING TARGET");
      console.log("agreements", agreements);
      console.log("all_agreements", best_match.all_agreements);
    }
    const agreement = agreements?.[0]?.agreement;
    if (!agreement) {
      row.increase_record = null;
      row.agreement_info_record = null;
      row.matching_increases = [];
      return;
    }
    if (agreement?.master) {
      if (row.match_type === 'unit') {
        row.match = false;
      }
      row.is_master_agreement = true;
      row.in_master = true;
    }

    row.increase_record = { 
      agreement_id: agreement?.id,

      //source: this.data.source,
      //agreement_effective,
      //agreement_expires,
      effective_date: effective, 
      members,
      //in_negotiations: in_negotiations ? true : false,
      increase_type: type,
      increase_value: value !== null && value!== undefined ? value : null,
      increase_base_value: base !== null && base !== undefined ? base  : null,
      comment: row.comments,
      contact: row.contact,
      row_info: row.sheet_id,
      /*
      agreement: {
        data: {
          agreement_id: agreement.id,
          effective_date: agreement_effective,
          expires_date: agreement_expires,
          in_negotiation: in_negotiations ? true : false
        },
        on_conflict: `{
            constraint: core_agreement_pkey,
            update_columns: [effective_date, expires_date, in_negotiation]
          }`
      }*/
      //status_code: 'staff'
    };
    row.agreement_info_record = {
      agreement_id: agreement?.id,
      effective_date: agreement_effective,
      expires_date: agreement_expires,
      in_negotiation: in_negotiations ? true : false
    };
    if (row.employer && row.match_type === 'unit') {
      let mismatches = [row.unit.name, ...row.unit.aliases.map(a => a.name)].filter(a => a.toLowerCase() !== row.employer.toLowerCase()); //.map(a => ({
      if (mismatches.length > 0) {
      row.import_aliases = [{
        unit_id: row.unit.id,
        name: row.employer
      }];
      //console.log("cehcking aliases", row.import_aliases);
    }
    }
   
    if (this.match_debugging) {
      agreement.increases.forEach(i => {
        console.log("INCREASE", i, row);
        //console.log(`members, i.members, members === i.members, row neg: ${in_negotiations}, inc neg: ${i.in_negotiations ? true : false}`, in_negotiations === (i.in_negotiations ? true : false), type === i.type, value === (i.value ? i.value : null), base === (i.base ? i.base : null))
        console.log(`members: ${members}===${i.members} (${members === (i.members !== null && i.members !== undefined ? i.members : null)}) && type: ${type} === ${i.increase_type} (${type === (i.increase_type !== null && i.increase_type !== undefined ? i.increase_type : null)}) && value: ${value} === ${(i.increase_value)} (${value === (i.increase_value !== null && i.increase_value !== undefined ? i.increase_value : null)}) && base: ${base} === ${(i.increase_base)} (${base === (i.increase_base !== null && i.increase_base !== undefined ? i.increase_base : null)})`);
        console.log("matches", members === (i.members !== null && i.members !== undefined ? i.members : null) && type === (i.increase_type !== null && i.increase_type !== undefined ? i.increase_type : null) && value === (i.increase_value !== null && i.increase_value !== undefined ? i.increase_value : null) && base === (i.increase_base !== null && i.increase_base !== undefined ? i.increase_base : null));
      });
    }
    //row.matching_increases = agreement.increases.filter(i => members === i.members && type === i.increase_type && value === (i.increase_value !== null && i.increase !== undefined ? i.increase : null) && base === (i.increase_base));
    row.matching_increases = agreement.increases.filter(i => 
      members === (i.members !== null && i.members !== undefined ? i.members : null) 
      && type === (i.increase_type !== null && i.increase_type !== undefined ? i.increase_type : null) 
      && (value ? Math.round(value*10000) : 0) === (i.increase_value !== null && i.increase_value !== undefined ? Math.round(i.increase_value*10000) : 0) 
      && (base !== undefined && base !== null ? Math.round(base*10000) : null) === (i.increase_base_value !== null && i.increase_base_value !== undefined ? Math.round(i.increase_base_value*10000) : null)
      );
     
    //console.log("ROW MATCH", row.match, row.match_fields, row.unit);
    row.row_id = JSON.stringify(row.increase_record);

    this.validate(row);
  }

  queueWork(n, d, f) {

    //FIXME:
    //f();
    //return;

    //console.log("queued", n);
    if (d && d.onscreen) {
      this.pqueue.push({f: f, d: d, n: n});
    } else {
      this.queue.push({f: f, d: d, n: n});
    }
    if (!this.processing_queue) {
      console.log("STARTING WORK QUEUE");
      this.processing_queue = window.requestIdleCallback(this.processQueue.bind(this))
    }

    this.progress = {
          ...this.progress,
          complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
          matched: {
            partial: this.data?.sent_rows?.size,
            finished: this.data?.received_rows?.size,
            total: this?.data?.total_rows
          }
        }
  }
  async processQueue(deadline) {
    while (deadline.timeRemaining() > 5 && (this.pqueue.length > 0 || this.queue.length > 0)){
      while (this.pqueue.length > 0) {
        let { f, n } = this.pqueue.shift();
        //console.log(`${this.queue.length}: running work`, n);
        f();
      }
     if(this.queue.length > 0 && deadline.timeRemaining() > 5) {
        let { f, n } = this.queue.shift();
        //console.log(`${this.queue.length}: running work`, n);
        f();
      }
      
    }
    this.progress = {
          ...this.progress,
          complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
          matched: {
            partial: this.data?.sent_rows?.size,
            finished: this.data?.received_rows?.size,
            total: this?.data?.total_rows
          }
        }
    this.build_cache();
    //await this.updateComplete;
    if (this.queue.length > 0) {
      //console.log("WORK QUEUE: ", this.queue.length);
      this.processing_queue = window.requestIdleCallback(this.processQueue.bind(this))
    } else {
      //console.log("WORK QUEUE EMPTY!");
      this.processing_queue = null;

      if (this?.data?.data?.some(d => !d.validated)) {
        let unvalid = this?.data?.data?.filter(d => !d.validated);
        //console.warn("unvalidated: ", unvalid);
        unvalid.forEach(d => this.validate(d));
        //unvalid.forEach(d => this.queueWork('cleanup validation', d, () => this.validate(d)));

      }

    }

  }

  validating = new Set();

  validate_queue = [];
  validate(row) {
    if (!this.validating.has(row.sheet_id)) {
      this.validating.add(row.sheet_id);
      this.validate_queue.push(row);
    }
    this.requestValidate();
  }

  requestValidateDebounced() {
    const rows = this.validate_queue;
    this.validate_queue = [];
    console.warn("validating", rows.length);
    this.validate_rows(rows);
  }

  validate_rows(rows) {
    rows.forEach(r => {
      this.validating.add(r.sheet_id);
    });
    this.progress = {...this.progress, 
      //complete: this?.data?.total_rows && this?.data?.validated_rows.size == this?.data?.total_rows, 
      validated: {partial: this.validating.size, finished: this?.data?.validated_rows.size, total: this?.data?.total_rows}
    };
    new this.ValidateWorker(rows, get_current_period()?.year, 
      Comlink.proxy((r, error_delta, error_row_delta) => {
        //row.errors = {...r.errors};
        this.data.total_errors += error_delta;
        this.data.total_error_rows += error_row_delta;
        //this.queueValidatedRow({...r});
        //this.queueWork(`validation finish ${r.row_number}: ${r.employer}`, row, () => this.loadValidatedRow({...r}));
        //console.log("got data back from validator", r);
        //this.queueWork(`validation finish ${r.row_number}: ${r.employer}`, this.data.data.find(d => d.sheet_id === r.sheet_id), () => this.loadValidatedRow({...r}));
        this.loadValidatedRow(r);
    }));
  }
  loadValidatedRow(u) {
    //console.log("loading validation data", u);
    let d = this.data.data.find(d => d.sheet_id === u.sheet_id);
    if (d) {
      d.errors = u.errors;
      d.validated = true;
      d.epoch = d.epoch + 1;
      if (this.data.validated_rows.has(u.sheet_id)) {
        console.warn("already validated", u);
      }
      this.data.validated_rows.add(u.sheet_id);
      //console.log("validated a row", {requested: this?.validating.size, finished: this?.data?.validated_rows.size, total: this?.data?.total_rows});
      this.progress = {...this.progress, 
        //complete: this?.data?.total_rows && this?.data?.validated_rows.size == this?.data?.total_rows, 
        validated: {partial: this.validating.size, finished: this?.data?.validated_rows.size, total: this?.data?.total_rows}
      };
    }
  }




  get filter() {
    return this._filter;
  }
  set filter(filter) {
    this._filter = filter;
    this.build_cache();
  }

  items_per_page = 50;
  get display_limit() {
    return this.paginated ? this.items_per_page : (this.data && this.data.filtered ? this.data.filtered.length : 0);
    ///return this.paginated ? this._display_limit : (this.data && this.data.filtered ? this.data.filtered.length : 0);
  }

  set display_limit(limit) {
    if (limit != this._display_limit) {
      this._display_limit = limit;
      this.build_cache();
    }
  }

  get display_item() {
    return this._display_item;
  }

  set display_item(item) {
    if (this.paginated) {
      let max_item = this.data.filtered_length - (this.data.filtered_length % this.display_limit);
      let change_item = item < 0 ? 0 : (item > max_item ? max_item : item);
      if (change_item != this._display_item) {
        this._display_item = change_item;
        this.build_cache();
      }
    }
  }


  get sort() {
    return this._sort;
  }

  set sort(sort) {
    this._sort = sort;
    if (this.data) this.data.is_sorted = false;
    this.build_cache();
  }

  toggle_sort(column) {
    let previous = this.sort.find(s => s.col === column);
    let prev_dir = previous ? previous.dir : 0;
    let next_dir = ((prev_dir + 2) % 3) - 1;
    this.sort = [{ col: column, dir: next_dir }, ...this.sort.filter(s => s.col !== column)].filter(s => s.dir !== 0);
  }
  async build_cache_debounced() {
    //console.log("rebuld cache", this?.data?.is_sorted);

    this.progress = {
          ...this.progress,
          complete: this.data?.total_rows && this.data?.total_rows <= this.data?.received_rows?.size,
          matched: {
            partial: this.data?.sent_rows?.size,
            finished: this.data?.received_rows?.size,
            total: this?.data?.total_rows
          }
        }
    if (this.data) {
      if (false && this.data && this.data.data) {
        let first_vis = this.data.data.findIndex(d => d.onscreen);
        let last_vis = this.data.data.reduce((acc, cur, i) => cur.onscreen ? i : acc, -1);
        //console.log("cleanup", this.data.data, first_vis, last_vis)
        if (first_vis > -1 && last_vis > -1) {
          this.data.data.slice(first_vis, last_vis).forEach(d => {
            if (!d.onscreen){
              d.onscreen = true;
              d.epoch += 1;
            }
          })
        }
        
      }
      if (!this?.data?.is_sorted) {
        this?.data?.data?.sort((a, b) => {
          //console.log("SORTING", this.sort);
          for (let sort of this.sort) {
            //console.log("SORT:", sort);
            let res;
            if (sort.col === 'selected') {
              res = sort.dir > 0 ? sorts[sort.col](this.selected.has(b.row_id), this.selected.has(a.row_id)) : sorts[sort.col](this.selected.has(a.row_id), this.selected.has(b.row_id));
            } else {
              res = sort.dir > 0 ? sorts[sort.col](a[sort.col], b[sort.col]) : sorts[sort.col](b[sort.col], a[sort.col]);
            }
            if (res !== 0) { return res };
          }
          return 0;
        });
        this.data.is_sorted = true;
      }
      this.data.filtered = this?.data?.data?.filter?.((r) => this.visible(r) && this.is_row_filtered(r) /*&& r.rendered */);
      console.warn("Filtered with filters", [...this.filter], 'include filters', [...this.include_filter], 'result:', [...this.data.filtered]);
      //this.data.filtered = this.data.filtered?.filter?.(r => this.is_row_filtered(r));

      this.data.filtered_length = this?.data?.filtered?.length;
      if (this.paginated) this.data.filtered = this.data.filtered.slice(this.display_item, this.display_item + this.display_limit);
    }
    this.requestUpdate("data");
    /*
    if (!this.intersect) {
      await this.updateComplete;
      this.makeIntersectObserver();
    }*/
    //console.log("rebuild finished.");
    /*
    if (this.paginated) {
      await this.updateComplete;
      this.computeDisplayLimit();
    }*/
  }

  visible(row) {
    return !row.ignore && row.status !== 'ignore';
  }

  rowHasErrors(row) {
    let e = row?.errors;
    return e && Object.keys(e)?.some(k => e[k] && e[k].length > 0);
  }
  rowSelectable(row) {
     //FIXME: filter based on prior contribs

    /*
    console.warn("SELECTABLE", row);
    console.log('\tvalidated', row.validated);
    console.log('\t!in_prog', !row.in_progress);
    console.log(`\t!unit master`, !(row.match_type === 'unit' && row.in_master));
    console.log('\t!has errors', !this.rowHasErrors(row));
    console.log('\tnew unit ', ( row?.new_unit ));
    console.log(`\t unit || master`, (row.unit || row.matched_master));
    console.log(`\t matching inc`, row.matching_increases);
    console.log(`\t inc length == 0`, row.matching_increases.length === 0);
    console.log(`\t match score`, row.match_score);
    */


    return row.validated 
    && !row.in_progress 
    && !(row.match_type === 'unit' && row.in_master) 
    && !this.rowHasErrors(row) 
    && (
        row?.new_unit 
         || (
            (row.unit || row.matched_master) 
            && row.matching_increases 
            && row.matching_increases.length === 0 
            && row.match_score
           )
       ); //&& row.match_data.e && row.match_data.e.employer.code === this.data.employer.consensus;
  }

  toggleSelected(id, row) {
    console.log("toggling", id, row, this.rowSelectable(row));
    if (!this.rowSelectable(row)) {
      return;
    }
    if (this.selected.has(id)) {
      this.selected.delete(id);
    } else {
      this.selected.add(id);
    }
    row.epoch = row.epoch + 1;
    this.requestUpdate('selected');
  }
  toggleAll() {
    this.select_all = !this.select_all;
    if (this.select_all) {
      this.selected = new Set([...this.selected.keys(), ...this.data.data.filter((r) => this.visible(r) && this.rowSelectable(r)).map((r) => r.row_id)]);
    } else {
      this.selected = new Set();
    }
    this?.data?.data?.forEach(r => {
      if (this.visible(r) && this.rowSelectable(r)) r.epoch = r.epoch + 1;
    })
    this.requestUpdate('selected');
  }

  /*

  doCreateUnit(row) {
    console.log("creating new unit", row);

    let unit_data = {
      name: row.employer,
      state: row.state, 
      council: row.council,
      local: row.local,
      subunit: row.subunit
    }

    let unit_importer = new EditUnit(
        p => console.log("saved unit", p),  // data update function
        { changeMap: null },  //initial variables
        p => { // finalizing function
          console.log("finalized unit", p);
          row.unit = p;
          row.match_score = 1;
          row.new_unit = null;
          row.row_id = `${row.sheet_id}::${p.id}`;
          this.set_agreement(row, p);
          this.doImportRow(row);
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: msgs };
          console.error(this.error);
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        });
      unit_importer.save(unit_data, unit_data);
  }

  doImportRow(import_row) {
      console.log("importing", import_row);
      let import_data = import_row.increase_record;
     
      console.log("trying to import", import_data);
      let importer = new EditIncrease(
        p => console.log("saved data", p),  // data update function
        { changeMap: null },  //initial variables
        p => { // finalizing function
          console.log("finalized data", p);
          import_row.matching_increases = [p];
          this.selected.delete(import_row.row_id);
          this.build_cache();
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: msgs };
          console.error(this.error);
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        });
      importer.save(import_data, import_data);

      let agreement_importer = new EditAgreement(
        p => console.log("saved agreement data", p),  // data update function
        { changeMap: null },  //initial variables
        p => { // finalizing function
          console.log("finalized agreement", p);
        },
        (e, msgs) => { // error handler
          this.error = { data: this.data_property, error: e, msgs: msgs };
          console.error(this.error);
          this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
        });
      agreement_importer.save(import_row.agreement_info_record, import_row.agreement_info_record);

      if (import_row.import_aliases && import_row.import_aliases.length > 0) {
        console.log("attempting to import aliases", import_row.import_aliases);
        let alias_importer = new EditUnitAlias(
          p => console.log("saved alias", p),  // data update function
          { changeMap: null },  //initial variables
          p => { // finalizing function
            console.log("finalized alias", p);
          },
          (e, msgs) => { // error handler
            this.error = { data: this.data_property, error: e, msgs: msgs };
            console.error(this.error);
            this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
          });
          import_row.import_aliases.forEach(a => {
            alias_importer.save(a, a);
          })
      }
  }
*/

  pending_increases = [];
  pending_aliases = [];
  pending_units = [];
  pending_rows = new Set();

  importSelected() {
    this.import_complete = false;
    this.pending_rows = new Set();
    
    let selected = this.data.data.filter((r) => this.selected.has(r.row_id));
    this.updates_in_progress = this.selected;
    this.selected = new Set();
    console.log(`importing ${selected.length} rows`);
    let new_units = [];
    let existing_units = [];
    selected.forEach(d => {
      if (d.new_unit){
        new_units.push(d);
      } else {
        existing_units.push(d);
      }
      d.in_progress = true;
      d.epoch = d.epoch+1;
    });

    this.pending_increases = [...this.pending_increases, ...existing_units];
    //this.pending_aliases = [...this.pending_aliases, ...new_aliases];
    this.pending_units = [...this.pending_units, ...new_units];

    this.pending_increases.forEach(i => this.pending_rows.add(`${i.sheet_id}::increase`));
    //this.pending_increases.forEach(i => this.pending_rows.add(`${i.sheet_id}::agreement`));
    this.pending_increases.filter(i => i?.agreement_info_record?.in_negotiation && !i?.agreement_saved).forEach(i => this.pending_rows.add(`${i.sheet_id}::agreement`));
    this.pending_units.forEach(i => this.pending_rows.add(`${i.sheet_id}::unit`));

    this.requestUpdate('import_rows_in_progress');
    this.queueWork('import queued data', null, () => this.importData());
  }

  get pending_data() {
    return this.pending_increases.length + this.pending_aliases.length + this.pending_units.length > 0;
  }
  get import_rows_in_progress() {
    return this.pending_rows.size;
  }

  importData() {
    const max_unit_chunk = 50;
    const max_increase_chunk = 50;
    let increase_rows = [];
    let alias_rows = [];
    let new_unit_rows = [];
    let agreement_priority = [];

    if (!this.pending_data){
      console.log("no work");
      return;
    }

    if (this.pending_units.length > 0) {
      // if there are units to create, do only that on this run
      while (this.pending_units.length > 0 && new_unit_rows.length < max_unit_chunk) {
        new_unit_rows.push(this.pending_units.shift());
      }
      console.warn(`CREATING ${new_unit_rows.length} UNITS`)
    } else {
      // process the increases
      while (this.pending_increases.length > 0 && increase_rows.length + agreement_priority.length < max_increase_chunk) {
        let next = this.pending_increases.shift();
        if (next?.agreement_info_record?.in_negotiation && !next.agreement_saved) {
          agreement_priority.push(next);
        } else {
          increase_rows.push(next);
        }
      }
      console.warn(`SENDING ${agreement_priority.length}+${increase_rows.length} INCREASES`)
    }

    alias_rows = increase_rows.filter(d => d?.import_aliases?.length > 0);

    //console.log(`importing ${increase_rows?.length} increases into existing units (${this.pending_increases.length} pending)`);
    //console.log(`importing ${alias_rows?.length} aliases (${this.pending_aliases.length} pending)`);
    //console.log(`importing ${new_unit_rows?.length} new units (${this.pending_units.length} pending)`);
    //console.log("pending keys", this.pending_rows);

    let increases = increase_rows.map(d => d.increase_record).filter(i => i);
    console.log("priority, increase", agreement_priority, increase_rows);
    let agreements = [...agreement_priority, ...increase_rows].map(d => d.agreement_info_record).filter(a => a);
    let deduped_agreements = [];
    let aids = new Map();
    console.log("importing ", agreements);
    agreements.forEach(a => {
      if (!aids.has(a.agreement_id)){
        deduped_agreements.push(a);
        aids.set(a.agreement_id, [a]);
      } else {
        aids.set(a.agreement_id, [...aids.get(a.agreement_id), a]);
        console.warn("ignoring duplicated agreements", aids.get(a.agreement_id));
      }
    });
    agreements = deduped_agreements;
    
    let aliases = [];
    alias_rows.forEach(r => r.import_aliases?.forEach(a => aliases.push(a)));
    let new_units = new_unit_rows.map(r => ({
      name: r.employer,
      state: r.state, 
      council: r.council,
      local: r.local,
      subunit: r.subunit
    }));

    console.log("ARGS:", {inc: increases, agr: agreements, ali: aliases, uni: new_units});
    if (increases.length + agreements.length + aliases.length + new_units.length == 0) {
      console.warn("nothing to import!");
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `nothing to upload` } })); // TODO: undo
      return;
    }
    
    const mut = gql`
        mutation upsert_increase($inc: [core_increase_insert_input!]!, $agr: [core_agreement_info_insert_input!]!, $ali: [core_unit_alias_insert_input!]!, $uni: [core_unit_insert_input!]!) {
          agreement:insert_core_agreement_info (
            objects: $agr,
            on_conflict: {
              constraint: core_agreement_info_pkey,
              update_columns: [agreement_id, effective_date, expires_date, in_negotiation]
            }
          ) {
            returning {
              agreement_id
              effective_date
              expires_date
              in_negotiation
            }
          }
          increase:insert_core_increase (
            objects: $inc,
            on_conflict: {
              constraint: core_increase_pkey,
              update_columns: [agreement_id, effective_date, members, increase_type, increase_value, increase_base_value, comment, contact]
            }
          ) {
            returning {
              agreement_id
              id
              row_info
              ...IncreaseFields
            }
          }
          alias:insert_core_unit_alias (
            objects: $ali,
            on_conflict: {
              constraint: core_unit_alias_pkey,
              update_columns: []
            }
          ) {
            returning {
              id
              unit_id
              name
            }
          }
          unit:insert_core_unit (
            objects: $uni,
            on_conflict: {
              constraint: core_unit_pkey,
              update_columns: [name, state, council, local, subunit]
            }
          ) {
            returning {
              ...UnitFields
              all_agreements {
                agreement {
                  id
                  name
                  promoted
                  period_id
                  increases {
                    ...IncreaseFields
                  }
                }
              }
            }
          }
        }
        ${increase_fields}
        ${unit_fields}
    `;
    client.mutate({
      mutation: mut,
      variables: {inc: increases, agr: agreements, ali: aliases, uni: new_units},
      refetchQueries: refetch_searches
     }).then(data => {
      window.router.invalidate();
      console.log("mutation results:", data);
      let {increase, agreement, alias, unit} = data?.data;
      this.handleUploadedIncreases(increase?.returning, alias?.returning);
      this.handleUploadedAgreements(agreement?.returning, agreement_priority);
      this.handleUploadedAliases(alias?.returning);
      this.handleUploadedUnits(unit?.returning, new_unit_rows);
      this.build_cache();

      if (new_unit_rows.length === 0 && this.pending_data) {
        console.log("queing remaining work");
        this.queueWork('import remaining rows', null, () => this.importData());
      }
     }).catch(error => {
       this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `upload failed (${increases.length}/${agreements.length}/${aliases.length}/${new_units.length})` } })); // TODO: undo
       console.error("ERROR WHILE SAVING INCREASES");
       console.warn("inc:", increases); console.warn("agr:", agreements); console.warn('alias:', aliases); console.warn('new:', new_units);
       formatQueryError(error);
       this.handleError(error, [...increase_rows, ...alias_rows, ...new_unit_rows, ...agreement_priority]);
       //todo: clear out some of the row status info
     });
  }

  errors = [];
  handleError(error, rows) {
    let uniq = new Set();
    rows.forEach(r => {
      if (!uniq.has(r.sheet_id)) {
        uniq.add(r.sheet_id);
        this.errors.push(r);
        let actual = this?.data?.data?.find(d => d.sheet_id === r.sheet_id);
        if (actual) {
          actual.upload_errors = actual.upload_errors ? [...actual.upload_errors, error] : [error];
          actual.in_progress = false;
          actual.epoch = actual.epoch + 1;
        }
      }
    });
    this.build_cache();
  }
  checkFinished() {
    console.log("checking for doneness", this.pending_rows, this.import_rows_in_progress);
    this.requestUpdate('import_rows_in_progress');
    if (!this.pending_data && this.import_rows_in_progress === 0 && !this.import_complete) {
      this.import_complete = true;
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'complete', text: `import complete` } })); // TODO: undo
    }
  }

  handleUploadedIncreases(increases, aliases) {
    if (!increases || increases.length === 0) {
      console.warn("no increase results returned");
      return;
    }
    this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `imported ${increases?.length} rows + ${aliases.length} aliases (${this.pending_increases.length} remaining)` } })); // TODO: undo
    increases.forEach(r => {
      let source_row = this.data.data.find(i => i.sheet_id === r.row_info);
      if (source_row) {
        console.log("UPSERTED EXISTING", source_row);
        source_row.epoch += 1;
        delete source_row.in_progress;
        this.selected.delete(source_row.row_id);
        if (this.selected.size === 0) {
          this.select_all = false;
        }
        source_row.matching_increases = [r];
        this.pending_rows.delete(`${source_row.sheet_id}::increase`);
      } else {
        console.error("UNABLED TO FIND EXISTING UPSERT SOURCE", r, increases);
      }
    });
    this.checkFinished();
  }
  handleUploadedAgreements(agreements, priority_rows) {
    console.log("inserted agreements:", agreements);
    let ids = new Set(agreements.map(a => a.agreement_id));
    priority_rows.forEach(r => {
      let real = this.data.data.find(d => d.sheet_id === r.sheet_id);
      if (real && ids.has(real?.agreement_info_record?.agreement_id)) {
        real.agreement_saved = agreements.find(a => a?.agreement_id === real?.agreement_info_record?.agreement_id);
        this.pending_increases = [real, ...this.pending_increases];
        this.pending_rows.add(`${real.sheet_id}::increase`);
        this.pending_rows.delete(`${real.sheet_id}::agreement`);
        console.log("saved priority agreement", real.agreement_saved);
      } else {
        console.warn("failed to save/find", priority_rows);
        this.pending_rows.delete(`${r.sheet_id}::increase`);
        this.pending_rows.delete(`${r.sheet_id}::agreement`);
      }
    });
    this.checkFinished();
  }
  handleUploadedAliases(aliases) {
    console.log("inserted aliases:", aliases);
    //if (aliases && aliases.length > 0) this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `added ${aliases?.length} aliases` } })); // TODO: undo
    this.checkFinished();
  }
  handleUploadedUnits(units, uploaded) {
    console.log("inserted units:", units);
    if (units && units.length > 0) {
      if (units.some(u => u.all_agreements.filter(t => t?.agreement?.period_id === get_current_period()?.id).length === 0)) {
        // FIXME: we didn't get back the agreement info in the mutation transaction...
        console.warn("DID NOT GET AGREEMENT INTO IN TRANS", units);
        const q = gql`
          query inserted_units($ids: [Int!]!) {
            units:core_unit(where: {id: {_in: $ids}}) {
              ...UnitFields
              all_agreements {
                agreement {
                  id
                  name
                  promoted
                  period_id
                  increases {
                    ...IncreaseFields
                  }
                }
              }
            }
          }
        ${increase_fields}
        ${unit_fields}
        `;

        client.query({
          query: q,
          variables: {ids: units.map(u => u.id)}
        }).then(d => {
          let final_units = d.data.units;
          console.log("got units", final_units);
          this.handleUploadedUnits(final_units, uploaded);
        }).catch(error => {
          this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { kind: 'error', text: `new unit upload failed (${units.length})` } })); // TODO: undo
          console.error("ERROR WHILE SAVING NEW UNITS");
          console.warn('new:', units);
          formatQueryError(error);
          this.handleError(error, [...uploaded]);
          //todo: clear out some of the row status info
        });
        return;
      }
      this.dispatchEvent(new CustomEvent('snackbar', { bubbles: true, composed: true, detail: { text: `created ${units.length} new units (${this.pending_units.length} remaining)` } })); // TODO: undo
      let increases = [];
      units.forEach(u => {
        let original_row = uploaded.find(r => r.employer === u.name && ['state', 'council', 'local', 'subunit'].every(f => u[f] === r[f]));
        let row = original_row ? this.data.data.find(d => d.sheet_id === original_row.sheet_id) : null;
        if (row) {
          row.unit = u;
          row.match_score = 1;
          row.new_unit = null;
          row.row_id = `${row.sheet_id}::${u.id}`;
          row.epoch += 1;
          this.set_agreement(row, u);
          increases.push(row);
          this.pending_rows.add(`${row.sheet_id}::increase`);
          this.pending_rows.delete(`${row.sheet_id}::unit`);
        } else {
          console.error("UNABLE TO FIND MATCHING UNIT ROW", u, uploaded);
        }
      });

      this.pending_increases = [...increases, ...this.pending_increases];
      console.log("queing remaining work after new units");
      this.queueWork('import remaining rows', null, () => this.importData());
    }
    this.checkFinished();
  }

  firstUpdated() {
    console.log("version 8");
    super.firstUpdated();
    this.resizer = new ResizeObserver(async entries => {
      for (let entry of entries) {
        if (this.rect.height != entry.contentRect.height || this.rect.width != entry.contentRect.width) {
          this.rect = entry.contentRect;
          //this.computeDisplayLimit();
        }
      }
    });

  }

  setRender(elem, visible) {
    if (elem && elem.data) {
      elem.data.onscreen = visible;
      elem.data.epoch += 1;
    }
  }

  /*
  makeIntersectObserver() {
    if (!this.intersect) {
      let scroller = this.renderRoot.querySelector('.table-scroller');
      if (scroller) {
        console.log("MAKING INTERSECTOR", scroller);
        this.intersect = new IntersectionObserver((async (entries, observer) => {
          entries.forEach(async (entry) => {
            let ratio = entry.intersectionRatio;
            let target = entry.target;
            if (ratio === 0) {
              this.setRender(target, false);

            }
            if (ratio > 0) {
              this.setRender(target, true);
              //let i = target.getAttribute('rowindex');
              //let vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
              //console.log("INTERSECT", i, ratio); //this.data.filtered[i]);
              this.data.filtered.slice(i, Math.round(2*i+(vh/72))).forEach(r => {
                r.onscreen = true;
                r.epoch = r.epoch + 1;
              });
              this.build_cache();
              this.updateComplete.then( () => this.updateObservedRow());
            }
            this.build_cache();
          });
        }), {rootMargin: "400px 0px 400px 0px"});
        this.updateObservedRow();
        let rows = this.renderRoot.querySelectorAll('.table-scroller tr');
        rows.forEach(r => {
          this.intersect.observe(r)
        });

      }
    }
  }
  */
 /*
  updateObservedRow() {
    if (this.observed_row) this.intersect.unobserve(this.observed_row);
    let row = this.renderRoot.querySelector('.table-scroller tr:not([onscreen])');
    if (row && row !== this.observed_row) {
      console.log('observed', this.observed_row, "=>", row);
      this.intersect.observe(row);
      this.observed_row = row;
    }
  }*/

  dragEnter(e) {
    e.preventDefault();
    this.dragging = true;
  }
  dragLeave(e) {
    e.preventDefault();
    this.dragging = false;
  }

  dragDrop(e) {
    e.preventDefault();
    this.dragging = false;

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          var file = e.dataTransfer.items[i].getAsFile();

          window.requestIdleCallback(() => this.addFile(file));

        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        window.requestIdleCallback(() => this.addFile(e.dataTransfer.files[i]));
      }
    }
    // Pass event to removeDragData for cleanup
    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      e.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      e.dataTransfer.clearData();
    }
  }


  handleRouteEvent(name, evt) {
    switch(name) {
      case 'open-upload':
        //console.log("opening an upload", evt.detail);
        const arg = {
          data: evt.detail.data.data,
          hash: evt.detail.hash,
          header: evt.detail.data.header,
          source: { ...evt.detail.data.source, type: 'upload', id: evt.detail.id }
        }
        //console.log("calling handler with", arg);
        this.handleMDuesData(arg);
        this.progress = { ...this.progress, bytes_read: {finished: arg.data.length, total: arg.data.length }};
        this.progress = { ...this.progress, processed: { finished: arg.data.length, total: arg.data.length }};
        break;
      case 'new-upload':
        //console.log("opening file handler");
        this.renderRoot.getElementById('file_chooser').click();
        this.renderRoot.getElementById('upload_button').blur();
        break;
      default:
        console.warn("no such event", name);
    }
  }


  async handleMDuesData({data, hash, header, source}){
    this.data = {data, hash, render_queue: [], header, source, type: 'mdues', name: "Wage Survey", total_errors: 0, total_error_rows: 0, unit_ids: new Map(), unit_colors: new Map()};
    //console.log("GOT DATA FROM WORKER", header, data);
    //this.filter = { ignored: true, imported: true, good_match: false, poor_match: false };
    this.display_limit = 8;///this.data.data.length;
    this.display_item = 0;
    this.header = header;
    //const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    //const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    /*
    this.data.data.slice(0, Math.round(2*vh/72)).forEach(r => {
      //r.onscreen = true;
      r.rendered = true;
    }); */
    this.build_cache();
    //await this.updateComplete;
    this.queueWork('fetch matches', null, () => this.fetchMatches());
    if (source?.type === 'file') this.queueWork('upload data', null, () => this.uploadSheet(this.data));
    //this.resizer.disconnect();
    //this.resizer.observe(this.renderRoot.getElementById('tablecontainer'));
  }

  async uploadSheet(d) {
    let { data, hash, header, source, type, name } = d;
    console.log("uploadSheet", d, {data, header, source, type, name});
    let upload = { period_id: get_current_period()?.id, filename: source?.name, hash, format: type, status: {rows: data?.length, imported: 0}, data: {data, header, source, type, name}};

  
    let uploader = new EditFileUpload(
      p => console.log("saved sheet data", p),  // data update function
      { changeMap: null },  //initial variables
      p => { // finalizing function
        console.log("finalized sheet", p);
      },
      (e, msgs) => { // error handler
        this.error = { data: this.data_property, error: e, msgs: msgs };
        console.error(this.error);
        this.dispatchEvent(new CustomEvent('save-error', { detail: this.error }));
      });
    console.log("uploading", upload);
    uploader.save(upload, {});
  }

  async bgOpenXLSX(f) {
    this.data = { type: 'loading' };
    const data_handlers = {
      'mdues': data => this.handleMDuesData(data),
      'error': error => this.data = { 'type': 'error', error, message: `Error. Failed to load data: "${error}". Try again.` },
      'unknown': () => this.data = { 'type': 'unknown', message: `Unable to recognize data format. Try again.` }
    }
    const worker = await new this.XLSXworker(f,
      Comlink.proxy(async (t, p) => {
        this.progress = {...this.progress, ...p }
      }),
      Comlink.proxy(async (type, data) => {
        console.log("received data from XLSXworker", type, data);
        if (data_handlers[type]) {
          data_handlers[type](data);
        } else {
          this.data = { type: 'unknown', message: "Unable to load file. Try Again." };
        }
      }));
    console.log("BG WORKER LAUNCHED...", worker);
  }

  resetState() {
    this.files = [];
    this.data = null;
    this.selected = new Set();
    this.select_all = false;
    this.pending_searches = [];
    this.search_received = new Set();
    this.queue = [];
    this.pqueue = [];
    this.processing_queue = null;
    this.intersect = null;
    this.validating = new Set();

    this.pending_increases = [];
    this.pending_aliases = [];
    this.pending_units = [];
    this.pending_rows = new Set();

    this.import_count = 0;
    this.pending_searches = [];
    this.search_received = new Set();
    this.queue = [];
    this.pqueue = [];
    this.processing_queue = null;
    this.matched_rows = [];
    this.unmatched_rows = [];

    this.validate_queue = [];
  }

  addFile(f) {
    console.log("ADDING A NEW FILE", f);
    this.resetState();
    this.search_received = new Set();
    this.data = { type: 'loading' };
    this.progress = {};
    this.files = [f];
    this.files.forEach(f => {
      switch (f.type) {
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
          this.bgOpenXLSX(f);
          this.import_count = this.import_count+1;
          break;
        case "text/tab-separated-values":
        case "text/csv":
          console.log("csv error", f);
          this.data = { 'type': 'error', message: `Only .xlsx files are currently recognized` };
          //this.openCSV(f);
          break;
        default:
          console.error("invalid file type:", f.type);
          this.data = { 'type': 'error', message: `Found [${f.type}] file, but only .xlsx files are currently recognized.` };
      }
    });
  }

  /*
  computeDisplayLimit() {
    if (this.paginated) {
      let scroller = this.renderRoot.querySelector('.table-scroller');
      let header = this.renderRoot.querySelector('#file_header');
      let first = this.renderRoot.querySelector('#file_data > tr');
      let container_height = scroller && header ? scroller.clientHeight - header.offsetHeight : null;
      let row_height = first ? first.offsetHeight : null;
      this.display_limit = container_height && row_height ? Math.floor(container_height / row_height) : 8;
    }
  }*/


  static get properties() {
    return {
      ...(super.properties),
      search_results: { type: Array },
      files: { type: Array },
      selected: { type: Object },
      select_all: { type: Boolean },
      filter: { type: Object },
      sort: { type: Object },
      data: { type: Object },
      dragging: { type: Boolean },
      active_user: { type: String },
      pending_searches: { type: Array },
      filter_menu: {type: String}
    };
  }
}

window.customElements.define('import-page', ImportPage);
export { ImportPage }
